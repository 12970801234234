import React, { Component } from "react";
import Paper from '@material-ui/core/Paper';

import Table from "@material-ui/core/Table";
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import ExamService from "../../services/exam.service";
// import AuthService from "../../services/auth.service";

export default class examresultperapplicant extends Component {
  constructor(props) {
    super(props);
    this.state = {
        examRes: {
			verbal_right_answer: 0,
			verbal_wrong_anser: 0,
			verbal_answered: 0,
			verbal_item_count: 0,
			math_right_answer: 0,
			math_wrong_anser: 0,
			math_answered: 0,
			math_item_count: 0,
			anal_right_answer: 0,
			anal_wrong_anser: 0,
			anal_answered: 0,
			anal_item_count: 0,
			speedaccu_right_answer: 0,
			speedaccu_wrong_anser: 0,
			speedaccu_answered: 0,
			speedaccu_item_count: 0,
			total_exam_item:0,
			total_exam_score:0,
			speed_time:'0:0:00',
			icat_time:'0:0:00',
			ver_classif: '',
			color: '',
			verbal_classification: '',
		},
        submitted: false,
        loading: false,
        note: false,
		checked: true,
		disableDetails: false,
		examClassif: [],
		applicantFullName: '',
		// examDate:''
    }
  }
  
  componentDidMount() {
		// Get Verbal Exam
		
		this.setState({applicantFullName: localStorage.getItem('applicantFullName')});
		ExamService.getExamResult(localStorage.getItem('encode_name')).then(
			response => {
				// console.log(response[0])
				const scoring = {
					speedClassif: response.speed_classification,
					speedResult: response.speed_result,
					verbalClassif: response.verbal_classification,
					verbalResult: response.verbal_result,
					mathClassif: response.math_classification,
					mathResult: response.math_result,
					analClassif: response.anal_classification,
					analResult: response.anal_result,
					finalRating: response.final_rating,
					finalClassification: response.final_classification
				}
				// console.log(scoring);
				if (response.final_rating === 'Failed') {
					this.setState( {note: true});
				}
				this.setState({ 
					examRes:response[0], 
					examClassif: scoring 
				});
				// console.log(examRes);
			},
			error => {
				console.log(error);
			}
		);
  }

  closeDiv = () => {
	this.props.history.goBack();
  }

  render() {
    const { examRes, examClassif } = this.state;
	const totalItem = Number(examRes.anal_item_count) + Number(examRes.math_item_count) + Number(examRes.verbal_item_count) + Number(examRes.speedaccu_item_count);
	const totalScore = Number(examRes.anal_right_answer) + Number(examRes.math_right_answer) + Number(examRes.verbal_right_answer) + Number(examRes.speedaccu_right_answer);
	// inline
	const cssCount = {fontWeight:'bold', fontSize: '15px'};
	const cssTime = {fontWeight:'bold', fontSize: '15px', opacity: .5};
    return (
		<div className="container" id="jobPortalContainer" style={{paddingTop:'65px',paddingBottom: '10px'}}>
				<div>
						<Paper style={{width:'100%', marginRight:'-5px', marginLeft:'-2px', marginBottom: '15px'}} elevation={3}>
						<Table sx={{ minWidth: 650, maxWidth: '100%' }} size="small">
							<TableHead>
								<TableRow>
									<TableCell><h5><span style={{opacity: '.5'}}>Final exam result of: </span><b>{this.state.applicantFullName}</b>,<span style={{opacity: '.5'}}>Exam Date: </span> <b>{this.state.examRes.created}</b></h5></TableCell>
									<TableCell align="right"><i className="fa fa-times" style={{fontSize: '25px', color: 'red'}} onClick={this.closeDiv}></i></TableCell>
								</TableRow>
								<TableRow>
									<TableCell><h5  style={{color: 'rgb(8, 66, 152)' }}>Final Exam Result</h5></TableCell>
									<TableCell align="right"><h5  style={{fontWeight: 'bold'}}>Result</h5></TableCell>
								</TableRow>
								<TableRow>
									<TableCell><h6>Final Rating</h6></TableCell>
									<TableCell align="right" style={cssCount}><span style={{color: examClassif.finalRating === 'Passed'? 'blue': 'red'}}>{examClassif.finalRating}</span>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell><h6 style={{fontWeight: 'bold'}}>Classification</h6></TableCell>
									<TableCell align="right" style={cssCount}><span style={{color: examClassif.finalRating === 'Passed'? 'blue': 'red'}}>{examClassif.finalClassification}</span></TableCell>
								</TableRow>
								<TableRow>
									<TableCell><h6 style={{fontWeight: 'bold'}}>Total Correct Answer</h6></TableCell>
									<TableCell align="right" style={cssCount}>{totalScore}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell><h6>Total Item</h6></TableCell>
									<TableCell align="right" style={cssTime}>{totalItem}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell><h6><span style={{
													    display: 'block',
														fontSize: '12px',
														fontWeight: 'bold',
														color: 'darkgray'
												}}>Test I</span>Speed and Accuracy time</h6></TableCell>
									<TableCell align="right" style={cssTime}>{examRes.speed_time}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell><h6><span style={{
													    display: 'block',
														fontSize: '12px',
														fontWeight: 'bold',
														color: 'darkgray'
												}}>Test II</span>ICAT time</h6></TableCell>
									<TableCell align="right" style={cssTime}>{examRes.icat_time}</TableCell>
								</TableRow>
							</TableHead>
						</Table>
						</Paper>
						<Grid item xs={12}>
							<Grid container spacing={2}>
							{/* style={{background: examClassif.verbalColor}} */}
							<Paper style={{width:'100%', marginRight:'10px', marginLeft:'5px'}} elevation={3}>
								<Grid item xs={12}>
									<Table sx={{ minWidth: 200, maxWidth: '50%' }} size="small">
										<TableHead>
											<TableRow>
												<TableCell><h5 style={{color: '#084298'}}><span style={{
													    display: 'block',
														fontSize: '15px',
														fontWeight: 'bold',
														color: 'darkgray'
												}}>Test I</span>Speed in Accuracy</h5></TableCell>
												<TableCell align="right"><h5  style={{color: '#084298'}}>Result</h5></TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6 style={{fontWeight: 'bold'}}>Item Correct</h6></TableCell>
												<TableCell align="right" style={{fontWeight:'bold', fontSize: '15px'}}>{examRes.speedaccu_right_answer}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6 style={{fontWeight: 'bold'}}>Result</h6></TableCell>
												<TableCell align="right" style={{fontWeight:'bold', fontSize: '15px'}}><span style={{color: examClassif.speedResult === 'Passed'? 'blue': 'red'}}>{examClassif.speedResult}</span></TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6 style={{fontWeight: 'bold'}}>Classification</h6></TableCell>
												<TableCell align="right" style={{fontWeight:'bold', fontSize: '15px'}}><span style={{color: examClassif.speedResult === 'Passed'? 'blue': 'red'}}>{examClassif.speedClassif}</span></TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6>Item Count</h6></TableCell>
												<TableCell align="right" style={{opacity:'.5'}}>{examRes.speedaccu_item_count}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6>Item Answered</h6></TableCell>
												<TableCell align="right"  style={{opacity:'.5'}}>{examRes.speedaccu_answered}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6>Item Wrong</h6></TableCell>
												<TableCell align="right"  style={{opacity:'.5'}}>{examRes.speedaccu_wrong_anser}</TableCell>
											</TableRow>
										</TableHead>
									</Table>
								</Grid>
								</Paper>
								{/* style={{background: examClassif.verbalColor}} */}
							</Grid>
						</Grid>
						<Paper style={{width:'100%', marginRight:'5px', marginTop: '15px', marginBottom:'10px', marginLeft: '-3px'}} elevation={3}>
						<Grid item xs={12} >
							<Grid container spacing={2}>
							{/* <Paper style={{width:'100%', marginRight:'5px', marginLeft:'5px', marginTop: '15px', marginBottom:'10px'}} elevation={3}> */}
								<Grid item xs={6}>
									<Table sx={{ minWidth: 200, maxWidth: '50%' }} size="small" aria-label="a dense table">
										<TableHead>
											<TableRow>
												<TableCell><h5 style={{color: '#084298',textShadow: '#f9fafb 0 1px 0 rgba(255, 255, 255, 0.4)'}}><span style={{
													    display: 'block',
														fontSize: '15px',
														fontWeight: 'bold',
														color: 'darkgray'
												}}>Test II</span>Verbal Intelligence </h5></TableCell>
												<TableCell align="right"><h5 style={{color: '#084298',paddingTop:'15px'}}>Result</h5></TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6 style={{fontWeight: 'bold'}}>Item Correct</h6></TableCell>
												<TableCell align="right" style={{fontWeight:'bold', fontSize: '15px'}}>{examRes.verbal_right_answer}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6 style={{fontWeight: 'bold'}}>Result</h6></TableCell>
												<TableCell align="right" style={{fontWeight:'bold', fontSize: '15px'}}><span style={{color: examClassif.verbalResult === 'Passed'? 'blue': 'red'}}>{examClassif.verbalResult}</span></TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6 style={{fontWeight: 'bold'}}>Classification</h6></TableCell>
												<TableCell align="right" style={{fontWeight:'bold', fontSize: '15px'}}><span style={{color: examClassif.verbalResult === 'Passed'? 'blue': 'red'}}>{examClassif.verbalClassif}</span></TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6>Item Count</h6></TableCell>
												<TableCell align="right" style={{opacity:'.5'}}>{examRes.verbal_item_count}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6>Item Answered</h6></TableCell>
												<TableCell align="right" style={{opacity:'.5'}}>{examRes.verbal_answered}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6>Item Wrong</h6></TableCell>
												<TableCell align="right" style={{opacity:'.5'}}>{examRes.verbal_wrong_anser}</TableCell>
											</TableRow>											
										</TableHead>
									</Table>
								</Grid>
							{/* style={{background: examClassif.mathColor}} */}
								<Grid item xs={6}>
									<Table style={{ marginTop: '17px'}} size="small" >
										<TableHead>
											<TableRow>
												<TableCell><h5 style={{color: '#084298'}}>Mathematical Intellegence</h5></TableCell>
												<TableCell align="right"><h5 style={{color: '#084298'}}>Result</h5></TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6 style={{fontWeight: 'bold'}}>Item Correct</h6></TableCell>
												<TableCell align="right" style={{fontWeight:'bold', fontSize: '15px'}}>{examRes.math_right_answer}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6 style={{fontWeight: 'bold'}}>Result</h6></TableCell>
												<TableCell align="right" style={{fontWeight:'bold', fontSize: '15px'}}><span style={{color: examClassif.mathResult === 'Passed'? 'blue': 'red'}}>{examClassif.mathResult}</span></TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6 style={{fontWeight: 'bold'}}>Classification</h6></TableCell>
												<TableCell align="right" style={{fontWeight:'bold', fontSize: '15px'}}><span style={{color: examClassif.mathResult === 'Passed'? 'blue': 'red'}}>{examClassif.mathClassif}</span></TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6>Item Count</h6></TableCell>
												<TableCell align="right"  style={{opacity:'.5'}}>{examRes.math_item_count}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6>Item Answered</h6></TableCell>
												<TableCell align="right" style={{opacity:'.5'}}>{examRes.math_answered}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6>Item Wrong</h6></TableCell>
												<TableCell align="right" style={{opacity:'.5'}}>{examRes.math_wrong_anser}</TableCell>
											</TableRow>
										</TableHead>
									</Table>
								</Grid>
								{/* style={{background: examClassif.analColor}} */}
								<Grid item xs={12}>
									<Table sx={{ minWidth: 200, maxWidth: '50%' }} size="small" aria-label="a dense table">
										<TableHead>
											<TableRow>
												<TableCell><h5 style={{color: '#084298'}}>Analytical Intelligence</h5></TableCell>
												<TableCell align="right"><h5 style={{color: '#084298'}}>Result</h5></TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6 style={{fontWeight: 'bold'}}>Item Correct</h6></TableCell>
												<TableCell align="right" style={{fontWeight:'bold', fontSize: '15px'}}>{examRes.anal_right_answer}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6 style={{fontWeight: 'bold'}}>Result</h6></TableCell>
												<TableCell align="right" style={{fontWeight:'bold', fontSize: '15px'}}><span style={{color: examClassif.analResult === 'Passed'? 'blue': 'red'}}>{examClassif.analResult}</span></TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6 style={{fontWeight: 'bold'}}>Classification</h6></TableCell>
												<TableCell align="right" style={{fontWeight:'bold', fontSize: '15px'}}><span style={{color: examClassif.analResult === 'Passed'? 'blue': 'red'}}>{examClassif.analClassif}</span></TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6>Item Count</h6></TableCell>
												<TableCell align="right" style={{opacity:'.5'}}>{examRes.anal_item_count}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6>Item Answered</h6></TableCell>
												<TableCell align="right" style={{opacity:'.5'}}>{examRes.anal_answered}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><h6>Item Wrong</h6></TableCell>
												<TableCell align="right" style={{opacity:'.5'}}>{examRes.anal_wrong_anser}</TableCell>
											</TableRow>
										</TableHead>
									</Table>
								</Grid>
								{/* </Paper> */}
							</Grid>
						</Grid>
						</Paper>
					{/* </Paper> */}
					</div>
			{/* </Paper> */}
		</div>
		);
  }
}
