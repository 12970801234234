import React, { Component } from "react";
import Typography from '@material-ui/core/Typography';
// import Badge from '@material-ui/core/Badge';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// import Button from '@material-ui/core/Button';
// import { userGrid } from '@mui/x-data-grid-generator';

// import JobService from "../../services/job.service";
import AuthService from "../../services/auth.service";
import '../Component.css';

export default class Quickapplicant extends Component {
  constructor(props) {
    super(props);
    this.state = {
        submitted: false,
        loading: true,
        message: "",
		checked: true,
		rows: [],
		jobApplied:[]
    }
  }
  
  componentDidMount() {
		if(localStorage.getItem('openNav') === '0') {
			document.getElementById("createApplicContainer").style.paddingLeft = "0px";
		} else {
			document.getElementById("createApplicContainer").style.paddingLeft = "216px";
		}
		//Get current user
		const user = AuthService.getCurrentUser();
		if (user === null) {
			this.setState({ redirect: "/dashboard" });
		} else {
			AuthService.getAllQuickApplicant().then(
				response => {
					console.log(response);
					try {
						this.setState({ rows:response, loading: false });
					}catch (error) {
						console.log(error);
					}
				},
				error => {
					console.log(error);
				}
			);
	  	}
  }	

  render() {
    const { rows, loading } = this.state;
	const columns = [
		{
			field: 'quick_fullname',
			headerName: 'Full Name',
			width: 250,
			editable: true,
			cellClassName: 'rowName'
		},
		{
			field: 'email',
			headerName: 'Email',
			width: 200,
			editable: true,
		},
		{
			field: 'phone',
			headerName: 'Mobile Number',
			width: 200,
			editable: true
		},
		{
			field: 'def_educ_attain',
			headerName: 'Educational Attainment',
			width: 200,
			editable: true,
			cellClassName: 'rowName'
		},
		{
			field: 'quick_present_address',
			headerName: 'Present Address',
			width: 200,
		},
		{
			field: 'id',
			headerName: 'Action',
			width: 250,
			renderCell: (params) => {
				let maxVal = 0xFFFFFF;
				let randomNumber = Math.random() * maxVal; 
				randomNumber = Math.floor(randomNumber);
				const randColor = randomNumber.toString(16);
				return (<div><span style={{background: `#${randColor}`,color: 'white', padding: '5px', borderRadius:'5px', cursor: 'pointer'}}><i className="fas fa-clock" style={{color:'white'}}></i>&nbsp;{'Schedule'}</span></div>);
			}
		}
	  ];
	  const applicContainter = {
		paddingTop:'45px',paddingBottom: '10px', position: 'absolute',left: '40px', right: '30px'
	  };
	  return (
		<div id="createApplicContainer" style={applicContainter}>
			<Typography component="h4" variant="h6" style={{textAlign:'left', paddingBottom: '10px', marginTop: '10px'}}>
				Quick Apply
			</Typography>
			<div style={{ width: '100%' }}>
				<div style={{ display: 'flex', height: '100%' }}>
					<DataGrid
						rows={rows}
						columns={columns}
						pageSize={10}
						autoHeight= {true}
						rowsPerPageOptions={[10, 20, 50]}
						components={{
							Toolbar: GridToolbar,
						}}
						onCellDoubleClick={(params, event) => {
							if (!event.ctrlKey) {
								event.defaultMuiPrevented = true;
								console.log(params.row);
								// this.props.history.push("/applicantprofile/"+params.row.encode_name);
							}
						}}
						checkboxSelection
						disableSelectionOnClick
						loading={loading}
						// density="compact"
					/>
				</div>
			</div>
		</div>
		);
  }
}
