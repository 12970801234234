import React, { Component } from "react";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { Editor } from "react-draft-wysiwyg";
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

import AuthService from "../../services/auth.service";
import JobsService from "../../services/job.service";

export default class JobsInfo extends Component {
  constructor(props) {
    super(props);
	this.state = {editorState: EditorState.createEmpty()};
    this.state = {
        formData: {
            job_position: '',
			company: '',
            vacancy: '',
            job_details: '',
			employment_type: '',
            street: '',
            city: '',
			No: false,
			Year: false,
			High: false,
			More: false,
			job_required: "",
			qualifications: [],
			jobRequired:[],
			Category: '',
			job_category:[],
			status: 0,
			created: '',
			created_date:'',
			availability:'',
			assgnmnt_area: '',
			incharge_email: ''
        },
		qualifications:[],
        submitted: false,
        loading: false,
        message: "",
		checked: true,
		disableDetails: false,
		disabled: true,
		qualifOptions: [
			"No Experience",
			"1 Year or less",
			"High school graduate are welcome",
			"College Level are welcome",
			"College Graduate",
			"2 to 4 years",
			"5+ Years",
			"Manegerial/Supervisory"
		],
		CategoryOptions: []
    }
  }
  
	componentDidMount() {
		const currentUserData = AuthService.getCurrentUser();
		if (currentUserData === null) {
			this.setState({ redirect: "/dashboard" });
		} else {
			const { id }  = this.props.match.params;
			this.setState({ loading: false, jobID: id });
			// Get Job by ID
			JobsService.getjobByID(id).then(
				response => {
					if (response.status) {
						const jobDetails = response.data;
						const created_date = jobDetails[0].created;
						if (jobDetails[0].job_details) {
							this.setState({ editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(jobDetails[0].job_details))) });
							this.saveContent(this.state.editorState.getCurrentContent());
						} else {
							this.setState({ editorState: EditorState.createEmpty() });
						}
						this.setState({ jobRequired: JSON.parse(response.data[0].job_required) });
						this.setState({ formData: { ...response.data[0], qualifications:JSON.parse(response.data[0].job_required), Category: (response.data[0].job_category === 0)? '':response.data[0].job_category ,created_date: created_date.split(' ')[0] }, disabled: false });
						console.log(this.state.formData);
					}
				},
				error => {
					console.log(error);
				}
			);
		}
		// Get Category
		JobsService.getJobCategory().then(
			response => {
				console.log(response);
				if (response.status) {
					const jobCategory = response.data;
					console.log(jobCategory);
					this.setState({CategoryOptions: jobCategory, loading: false});
				}
			},
			error => {
				this.setState({jobsList: false});
			}
		);
	}

	handleChange = (event) => {
		const { formData } = this.state;
		formData[event.target.name] = event.target.value;
		if (formData.qualifications.length < 1) {
			this.setState({ disabled: true });
		} else {
			this.setState({ disabled: false });
		}
		console.log(formData);
		this.setState({ formData });
	}

	handleChangeChecked = (event) => {
		const { formData } = this.state;
		formData[event.target.name] = event.target.checked;
		console.log(formData);
		if(formData.High || formData.More || formData.No || formData.Year) {
			this.setState({checkedData: true});
		} else {
			this.setState({checkedData: false});
		}
	}

	onChange = (editorState) => {
		const contentState = editorState.getCurrentContent();
		console.log('content state', convertToRaw(contentState));
		if(contentState.hasText()) {
			this.setState({disableDetails: true, disabled: false});
		} else {
			this.setState({disableDetails: false, disabled: true});
		}
		this.saveContent(contentState);
		this.setState({
			editorState,
		});
	}

	saveContent = (content) => {
		this.setState({job_details: JSON.stringify(convertToRaw(content)), disabled: false})
	}

	closeDiv = () => {
		this.props.history.push("/jobs");
	}

	handleSubmit = () => {
		this.setState({
			resMessage: "",
			loading: true
		});
		this.setState({ submitted: true }, () => {
			JobsService.addNewJob(
				this.state.jobID,
				this.state.formData.job_position,
				this.state.formData.vacancy,
				this.state.formData.street,
				this.state.formData.city,
				this.state.job_details,
				JSON.stringify(this.state.formData.qualifications),
				this.state.formData.employment_type,
				this.state.formData.company,
				this.state.formData.availability,
				this.state.formData.status,
				this.state.formData.created_date,
				this.state.formData.assgnmnt_area,
				this.state.formData.Category,
				this.state.formData.incharge_email
			).then(
			response => {
					console.log(response);
					if (response.status) {
						setTimeout(() => 
							this.setState({ 
								message: response.message,
								successful: true,
								loading: false
							}), 1000);
							setTimeout(() => 
							this.setState({ 
								successful: false
							}), 8000);
							setTimeout(() => 
							this.props.history.push('/jobs'), 1000);
					}
				}, error => {
					const resMessage = error.response.data;
					console.log(resMessage);
				}
			);
			setTimeout(() => 
			this.setState({ submitted: false }), 2000);
		});
		}

  render() {
    const { formData, submitted, disabled } = this.state; 
    return (
		<div id="createApplicContainer" style={{paddingTop:'45px',paddingBottom: '10px'}}>
			<Paper style={{maxWidth: '500px !important' }}>
			<span onClick={this.closeDiv } style={{color: '#0a0d0a',float: 'right',paddingRight: '10px',fontSize: '21px', cursor: 'pointer'}}><i className="fas fa-times"></i></span>
				<ValidatorForm
					ref="form"
					onSubmit={this.handleSubmit}
					style={{padding: '10px'}}
				>
					<Typography component="h1" variant="h5" style={{textAlign:'center'}}>
						Update Job
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12}> 
							<TextValidator
								label="Company*"
								onChange={this.handleChange}
								name="company"
								value={formData.company}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['required']}
								errorMessages={['Company is required']}
								autoFocus
								fullWidth
								style={{marginTop:'-20px'}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}> 
							<TextValidator
								label="Position*"
								onChange={this.handleChange}
								name="job_position"
								value={formData.job_position}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['required']}
								errorMessages={['Position is required']}
								fullWidth
								style={{marginTop:'-15px'}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}> 
							<InputLabel style={{fontSize: '13px'}}>
								Job Category*
							</InputLabel>
							<Select
								label="Category"
								labelId="Category_select"
								id="Category"
								// multiple
								value={formData.Category}
								name="Category"
								onChange={this.handleChange}
								input={<Input />}
								renderValue={selected => selected}
								fullWidth
							>
								{this.state.CategoryOptions.map(name => (
									<MenuItem key={name.category_id} value={name.category_name}>{name.category_name}</MenuItem>
								))}
							</Select>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={6}> 
							<TextValidator
									name="availability"
									label="Availability"
									select
									value={formData.availability}
									InputLabelProps={{ shrink: true }}
									onChange={this.handleChange}
									validatorListener={this.validatorListener}
									fullWidth
									style={{marginTop: '-4px'}}
							>
								<MenuItem value={''} selected>Not Urgent</MenuItem>
								<MenuItem value={'Urgent'} selected>Urgent</MenuItem>
							</TextValidator>
						</Grid>
						<Grid item xs={6}>
							<TextValidator
								label="Vacancy*"
								onChange={this.handleChange}
								name="vacancy"
								value={formData.vacancy}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
								validators={['required']}
								errorMessages={['Vacancy is required']}
								fullWidth
								type="number"
								style={{marginTop:'-15px'}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={6}> 
							<label style={{fontSize: '13px'}}>Qualifications</label>
							<Select
								label="Qualification*"
								labelId="qualification_select"
								id="qualification"
								multiple
								value={formData.qualifications}
								name="qualifications"
								onChange={this.handleChange}
								input={<Input />}
								renderValue={selected => selected.join(", ")}
								fullWidth
							>
								{this.state.qualifOptions.map(name => (
									<MenuItem key={name} value={name}>
									<Checkbox checked={formData.qualifications.indexOf(name) > -1} />
									<ListItemText primary={name} />
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={6}>
							<TextValidator
									name="employment_type"
									label="Employment Type*"
									select
									value={formData.employment_type}
									InputLabelProps={{ shrink: true }}
									validators={['required']}
									errorMessages={['Employment Type is required.']}
									onChange={this.handleChange}
									validatorListener={this.validatorListener}
									fullWidth
									style={{marginTop: '14px'}}
							>
								<MenuItem value={'Fulltime'} selected>Full Time</MenuItem>
								<MenuItem value={'ProjectBased'}>Project Base</MenuItem>
								<MenuItem value={'Reliever'}>Reliever</MenuItem>
							</TextValidator>
						</Grid>	
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={6}> 
							<TextValidator
								label="Street, Building Address*"
								onChange={this.handleChange}
								name="street"
								value={formData.street}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['required']}
								errorMessages={['Street or Building is required']}
								fullWidth
								style={{marginTop: '-15px'}}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextValidator
								label="City, Province Address*"
								onChange={this.handleChange}
								name="city"
								value={formData.city}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
								validators={['required']}
								errorMessages={['City Address is required']}
								fullWidth
								style={{marginTop: '-15px'}}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={6}> 
							<TextValidator
									name="job_status"
									label="Job Status*"
									select
									value={formData.status}
									InputLabelProps={{ shrink: true }}
									validators={['required']}
									errorMessages={['Job Status is required.']}
									onChange={this.handleChange}
									validatorListener={this.validatorListener}
									fullWidth
									style={{marginTop: '7px'}}
							>
								<MenuItem value={'0'} selected>Active</MenuItem>
								<MenuItem value={'1'}>Inactive</MenuItem>
							</TextValidator>
						</Grid>
						<Grid item xs={6}>
							<TextValidator
								label="Created Date*"
								onChange={this.handleChange}
								name="created_date"
								type="date"	
								value={formData.created_date}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
								validators={['required']}
								errorMessages={['Created is required']}
								fullWidth
								style={{marginTop: '-4px'}}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={12}> 
							<TextValidator
								label="Area of Assignment*"
								onChange={this.handleChange}
								name="assgnmnt_area"
								value={formData.assgnmnt_area}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['required']}
								errorMessages={['Provide area of assignment']}
								fullWidth
								style={{marginTop: '-15px'}}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={12}> 
							<TextValidator
								label="Incharge Email*"
								onChange={this.handleChange}
								name="incharge_email"
								value={formData.incharge_email}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['required']}
								errorMessages={['Provide area of Incharge Email']}
								fullWidth
								style={{marginTop: '-15px'}}
							/>
						</Grid>
					</Grid>


					<Grid container spacing={2}>
						<Grid item xs={12}> 
							<h6 style={{opacity:'.5'}}>Job Description*</h6>
							<Paper style={{ padding: '10px', minHeight: '300px' }}>
								<Editor
									name="employment_type"
									editorState={this.state.editorState}
									value={formData.job_details}
									onEditorStateChange={this.onChange}
									placeholder="Enter Job Details"
									fullWidth
									style={{padding:'20px'}}
								/>
							</Paper>
						</Grid>
					</Grid>

					{this.state.successful && (
						<div>
							<br/>
							<div className="form-group">
								<div
									className={
										this.state.successful
										? "alert alert-success"
										: "alert alert-danger"
									}
									role="alert"
								>
								{this.state.message}
								</div>
							</div>
						</div>
					)}
					<div className="form-group" style={{marginTop: '-30px'}}>

						<Button
							color="primary"
							variant="contained"
							type="submit"
							disabled={disabled || submitted}
							style={{ 'width': '100%', marginTop: '30px'}}
						>
							{this.state.loading && (
								<div><span className="spinner-border spinner-border-sm"></span>&nbsp;</div>
							)}

							{
								(submitted && ' Updating Job..')
								|| (!submitted && 'Update Job ')
							}
						</Button>
					</div>
					</ValidatorForm>
			</Paper>
		</div>
		);
  }
}

