import React, { Component } from "react";
import AuthService from "../services/auth.service";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import "../App.css";

import JobsService from "../services/job.service";

export default class dashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			redirect: null,
			userReady: false,
			currentUser: null,
			jobs: [],
			search_job: '',
			allJobs: 0,
			activeJobs: 0
		};
	}

    handleChange = (event) => {
        const search_job = event.target.value;
        this.setState({ search_job });
    }

    handleSubmit = () => {
        // your submit logic
    }

	componentDidMount() {
		const currentUserData = AuthService.getCurrentUser();
		console.log(currentUserData);
		if (currentUserData.data) {
			// console.log(currentUserData.data);
			// if (currentUserData.data.role) {
			// 	if (currentUserData.data.role == 1) {
			// 		this.props.history.push("/applicantjobs");
			// 	}
			// }
			JobsService.getallJobs().then(
				response => {
					if (response.status) {
						const jobs = response.data;
						let ctr = 0;
						for(let i=0; i < response.data.length; i++) {
							const result = this.diffInMonths(new Date(response.data[i].created), new Date());
							if (result <= 1) {
								ctr++;
							}
						}
						this.setState({jobs, loading: false, allJobs: response.data.length, activeJobs: ctr});
					}
				},
				error => {
					console.log(error);
				}
			);
		}

	}

	diffInMonths = (end, start) => {
		var timeDiff = Math.abs(end.getTime() - start.getTime());
		return Math.round(timeDiff / (2e3 * 3600 * 365.25));
	}			 

	render() {
		const resCount = {
			position: 'absolute',
    		marginTop: '11px'
		};
		return (
			<div className="container" id="createApplicContainer">
					<div style={{paddingTop:'70px'}}>
						<Typography component="h1" variant="h5" style={{textAlign:'center'}}>
							Dashboard
						</Typography>
						<br />

						<Grid container spacing={2}>
							{/* Dashboard */}
							<Grid item xs={6}>
								<Paper elevation={3}>
									<div>
										<i className="fas fa-user-tie" style={{color:'skyblue',fontSize: '100px',padding: '15px'}}></i>
										<span style={resCount}>
											<Typography component="h1" variant="h5">Applicant List</Typography>
											<span>All: 0</span><br/>
											<span>Pass: 0</span><br/>
											<span>Failed: 0</span><br/>
										</span>
									</div>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper elevation={3}>
									<div>
										<i className="fas fa-briefcase" style={{color:'orange',fontSize: '100px',padding: '15px'}}></i>
										<span style={resCount}>
											<Typography component="h1" variant="h5">Job List</Typography>
											<span>All:<span style={{fontWeight:'bold'}}> {this.state.allJobs}</span></span><br/>
											<span>Active: <span style={{fontWeight:'bold'}}> {this.state.activeJobs}</span></span><br/>
											{/* <span>Occupied: 0</span><br/> */}
										</span>
									</div>
								</Paper>
							</Grid>
						</Grid>
					</div>
			</div>
		);
	}
}
