import React, { Component } from "react";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Logo from "../image/cgsilogo.png";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Bg from "../image/about-us.jpg";

import './Component.css';

import AuthService from "../services/auth.service";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
        formData: {
            email: '',
            password: '',
        },
        container: {
            backgroundImage: `url(${Bg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            position: 'absolute',
            width: '100vw',
            height: '100%',
            left: '0px',
            top: '0px'
        },
        submitted: false,
        loading: false,
        message: ""
    }

  }

  componentDidMount() {
    localStorage.clear();
  }

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleSubmit = () => {
      this.setState({ submitted: true }, () => {
        this.setState({
          resMessage: "",
          loading: true
        });

        AuthService.login(this.state.formData.email, this.state.formData.password).then(
          response => {
            if (response.data.role === '1') {
              this.props.history.push("/applicantjobs");
            } else {
              this.props.history.push("/dashboard");
            }
            window.location.reload();
          },
          error => {
            console.log(error);
            const resMessage = error.response.data;
            this.setState({
              loading: false,
              message: resMessage
            });
          }
        );
          setTimeout(() => 
          this.setState({ submitted: false, message:'' }), 5000);
      });
  }

  render() {
    const { formData, submitted } = this.state;
    return (
      <div className="col-md-12" style={this.state.container} >
        <div className="card card-container" style={{borderRadius:'0px'}}>
          <img src={Logo} alt="website logo" style={{"width": "80px",marginLeft: '40%'}}/>
            <ValidatorForm
                  ref="form"
                  onSubmit={this.handleSubmit}
            >
                <Typography component="h1" variant="h5" style={{textAlign:'center'}}>
                  Login
                </Typography>

                <div className="form-group">
                  <TextValidator
                      label="Email"
                      onChange={this.handleChange}
                      name="email"
                      value={formData.email}
                      inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
                      validators={['required', 'isEmail']}
                      errorMessages={['Email is required', 'Email is not valid']}
                      autoFocus
                      fullWidth
                  />
                </div><br />
                <div className="form-group" style={{marginTop: '-10px'}}>
                  <TextValidator
                      label="Password"
                      onChange={this.handleChange}
                      name="password"
                      type="password"
                      value={formData.password}
                      inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
                      validators={['required']}
                      errorMessages={['Password is required']}
                      fullWidth
                  />
                </div>
                <div className="form-group" style={{marginTop: '-10px'}}>
                  <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={submitted}
                      className="registerAccountBtn"
                      style={{ 'width': '100%', marginTop: '25px'}}>

                      {this.state.loading && (
                        <div><span className="spinner-border spinner-border-sm"></span>&nbsp;</div>
                      )}

                      {
                          (submitted && 'Logging In..')
                          || (!submitted && 'Login')
                      }
                  </Button>
                  <br />
                  {this.state.message && (
                    <div className="form-group" style={{marginTop: '10px'}}>
                      <div className="alert alert-danger" role="alert">
                        {this.state.message}
                      </div>
                    </div>
                  )}
                  <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      {"Don't have an account? "}
                      <Link href="/register" variant="body2">
                        {" Sign Up"}
                      </Link>
                    </Grid>
                  </Grid>
                </div>
            </ValidatorForm>
        </div>
      </div>
    );
  }
}
