import React, { Component } from "react";
// import Grid from '@material-ui/core/Grid';

import UserService from "../services/user.service";
import Bg from "../image/about-us.jpg";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      container: {
          backgroundImage: `url(${Bg})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          position: 'absolute',
          width: '100vw',
          height: '100%',
          left: '0px',
          top: '0px'
      },
    };
  }

  componentDidMount() {
    window.location.href = '/cgsi-jobs';
    UserService.getPublicContent().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  render() {
    return (
      <div className="container mt-5">
        <span>WELCOME TO CGSI JOB PORTAL </span>
        <header className="jumbotron">
          {/* <h3>{this.state.content}</h3> */}
          Content
        </header>
      </div>
    );
  }
}
