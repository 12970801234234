import axios from 'axios';
// import authHeader from './auth-header';
// const baseURL = "http://localhost:70/"
const baseURL = "https://f25426ad5f6c6cf7.ngrok.app/";
const API_URL = baseURL+"applicantapi/api/exam/";
const API_URL_Math = baseURL+"applicantapi/api/mathexam/";
const API_URL_Speed = baseURL+"applicantapi/api/speedaccuexam/";
const API_URL_Analytical = baseURL+"applicantapi/api/analyticexam/";
const ExamResult = baseURL+"applicantapi/api/examresult/";
const API_URL_SpeedProd = baseURL+"applicantapi/api/Spdaccuprod/";

class ExamService {

	// Get Verbal Exam
	getVerbalExam() {
		return axios({
			method: "get",
			url: API_URL,
			headers: { "Content-Type": "multipart/form-data" },
		})
		.then(response => {
			return response.data.data;
		})
	}

	// Get Math Exam
	getMathExam() {
		return axios({
			method: "get",
			url: API_URL_Math,
			headers: { "Content-Type": "multipart/form-data" },
		})
		.then(response => {
			return response.data.data;
		})
	}

	// Get Anal Exam
	getAnalExam() {
		return axios({
			method: "get",
			url: API_URL_Analytical,
			headers: { "Content-Type": "multipart/form-data" },
		})
		.then(response => {
			return response.data.data;
		})
	}

	// Get Speed Exam
	getSpeedExam() {
		return axios({
			method: "get",
			url: API_URL_Speed,
			headers: { "Content-Type": "multipart/form-data" },
		})
		.then(response => {
			return response.data.data;
		})
	}

	// Get Speed Exam
	getSpeedExamProd() {
		return axios({
			method: "get",
			url: API_URL_SpeedProd,
			headers: { "Content-Type": "multipart/form-data" },
		})
		.then(response => {
			return response.data.data;
		})
	}

	calculateExamResult(answer, userid, speed_accu_time, icat_time) {
		console.log(answer);
		var bodyFormData = new FormData();
		bodyFormData.append('exam_answer', answer);
		bodyFormData.append('user_id', userid);
		bodyFormData.append('speed_accu_time', speed_accu_time);
		bodyFormData.append('icat_time', icat_time);
		return axios({
			method: "post",
			url: API_URL,
			data: bodyFormData,
			headers: { "Content-type": "application/json; charset=UTF-8" },
		})
		.then(response => {
			return response.data
		})
	}

	getExamResult(userid) {
		return axios({
			method: "get",
			url: ExamResult+userid,
			headers: { "Content-Type": "multipart/form-data" },
		})
		.then(response => {
			return response.data.data;
		})
	}
}

export default new ExamService();
