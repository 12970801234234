// import { ExitToApp } from "@material-ui/icons";
import axios from "axios";

const baseUrl = "https://f25426ad5f6c6cf7.ngrok.app";
// const baseUrl = "http://180.232.152.179:70";
const API_URL = baseUrl+"/applicantapi/api/authentication/";
const API_PER_INFO = baseUrl+"/applicantapi/api/personalinfo/";
const API_EDUC_INFO =baseUrl+"/applicantapi/api/educinfo/";
const API_EMPLOYMENT_INFO = baseUrl+"/applicantapi/api/employmentinfo/";
const API_CHARACTER_INFO = baseUrl+"/applicantapi/api/characterinfo/";
const API_COMPLETE_PROFILE = baseUrl+"/applicantapi/api/completeprofile/";
const API_USER = baseUrl+"/applicantapi/api/users/";
const API_ADMIN = baseUrl+"/applicantapi/api/admin/";
const API_GET_INFO = baseUrl+"/applicantapi/api/usersinfo/";
const API_QUICK_APPLY = baseUrl+"/applicantapi/api/quickapply/";
const ADDRESS_API = "https://raw.githubusercontent.com/flores-jacob/philippine-regions-provinces-cities-municipalities-barangays/master/philippine_provinces_cities_municipalities_and_barangays_2019v2.json";
const API_QUICKY = baseUrl+"/applicantapi/api/quickapplicant/";

class AuthService {

login(email, password) {

	var bodyFormData = new FormData();
	bodyFormData.append('email', email);
	bodyFormData.append('password', password);

	return axios({
		method: "post",
		url: API_URL + "index_post",
		data: bodyFormData,
		headers: { "Content-Type": "multipart/form-data" },
	})
	.then(response => {
		localStorage.setItem("user", JSON.stringify(response.data));
		return response.data
	})

}

	//get address
	getAllAddress() {
		return axios.get(ADDRESS_API)
		.then(response => {
			return response.data;
		});
	}

	// get user default
	getUserDefualt(id) {
		return axios.get(API_GET_INFO+id)
		.then(response => {
			return response.data.data[0];
		});
	}

	// get all applicant
	getAllApplicant() {
		return axios.get(API_USER)
		.then(response => {
			return response.data.data;
		});
	}

	// get all quick applicant
	getAllQuickApplicant() {
		return axios.get(API_QUICKY)
		.then(response => {
			return response.data.data;
		});
	}

	// get all admin
	getAllAdmin() {
		return axios.get(API_ADMIN)
		.then(response => {
			return response.data.data;
		});
	}

	// get personal information of user
	personalInfo(id) {
		return axios.get(API_PER_INFO,{
			params: {
				userid: id
			}
		})
		.then(response => {
			return response.data.data[0];
		});
	}

	// get educ information of user
	educInfo(id) {
		return axios.get(API_EDUC_INFO+'educinfo?userid='+id)
		.then(response => {
			return response.data.data[0];
		});
	}

	// get employment information of user
	employmentInfo(id) {
		return axios.get(API_EMPLOYMENT_INFO+'employmentinfo?userid='+id)
		.then(response => {
			return response.data.data[0];
		});
	}

	// get character information of user
	characterInfo(id) {
		return axios.get(API_CHARACTER_INFO+'characterinfo?userid='+id)
		.then(response => {
			return response.data.data[0];
		});
	}

	logout() {
		localStorage.removeItem("user");
	}
	// Register 
	register(username, email, firstname, lastname, password, dickname, middlename, birthday, mobile, qualifications, educ_attainment, job_level, present_address) {

		var bodyFormData = new FormData();
		bodyFormData.append('username', username);
		bodyFormData.append('email', email);
		bodyFormData.append('first_name', firstname);
		bodyFormData.append('last_name', lastname);
		bodyFormData.append('password', password);
		bodyFormData.append('phone', mobile);
		bodyFormData.append('encode_name', dickname);
		bodyFormData.append('middlename', middlename);
		bodyFormData.append('birthday', birthday);
		bodyFormData.append('mobile', mobile);
		bodyFormData.append('qualifications', qualifications);
		bodyFormData.append('educ_attainment', educ_attainment);
		bodyFormData.append('job_level', job_level);
		bodyFormData.append('present_address', present_address);

		return axios({
			method: "post",
			url: API_URL + "registration",
			data: bodyFormData,
			headers: { "Content-type": "application/json; charset=UTF-8" },
		})
		.then(response => {
			localStorage.setItem("user", JSON.stringify(response.data));
			return response.data
		})
	}

	quickApply(jobid, fullname, gender, email, pressent_address, birthday, mobile_number, educ_attain, jobexperience) {

		var bodyFormData = new FormData();
		bodyFormData.append('jobid', jobid);
		bodyFormData.append('fullname', fullname);
		bodyFormData.append('gender', gender);
		bodyFormData.append('email', email);
		bodyFormData.append('presentaddress', pressent_address);
		bodyFormData.append('birthday', birthday);
		bodyFormData.append('mobile_number', mobile_number);
		bodyFormData.append('educ_attain', educ_attain);
		bodyFormData.append('jobexperience', jobexperience);

		return axios({
			method: "post",
			url: API_QUICK_APPLY + "registration",
			data: bodyFormData,
			headers: { "Content-type": "application/json; charset=UTF-8" },
		})
		.then(response => {
			localStorage.setItem("user", JSON.stringify(response.data));
			return response.data
		})
	}

	completeProfile(
		//PERSONAL INFORMATION
		sessionID,
		presentaddress,
		prescelno,
		provincialaddress,
		provcelno,
		// birthdate,
		birthplace,
		civilstatus,
		religion,
		sex,
		height,
		weight,
		// age,
		nameofspouse,
		numofchild,
		fathersname,
		fatoccupation,
		mothersname,
		motoccupation,
		emergencycontact,
		addressemercontact,
		cellnoemercontact,
		//EDUCATIONAL BACKGROUND
		elementary,
		elemyeargrad,
		highschool,
		hsyeargrad,
		college,
		colyeargrad,
		course,
		courseyrgrad,
		vocational,
		vocyrgrad,
		skills,
		//EMPLOYMENT HISTORY
		from1,
		from2,
		from3,
		to1,
		to2,
		to3,
		compaddress1,
		compaddress2,
		compaddress3,
		posi1,
		posi2,
		posi3,
		sal1,
		sal2,
		sal3,
		//CHARACTER REFERENCE
		refname1,
		refname2,
		refname3,
		refoccuaddress1,
		refoccuaddress2,
		refoccuaddress3,
		refcelno1,
		refcelno2,
		refcelno3,
		sssno,
		philhealthno,
		tinno,
		pagibigno,
		profilepic
	) {
		var bodyFormData = new FormData();
		//PERSONAL INFORMATION
		bodyFormData.append('session_id', sessionID);
		bodyFormData.append('presentaddress', presentaddress);
		bodyFormData.append('prescelno', prescelno); 
		bodyFormData.append('provincialaddress', provincialaddress);
		bodyFormData.append('provcelno', provcelno);
		// bodyFormData.append('birthdate', birthdate);
		bodyFormData.append('birthplace', birthplace);
		bodyFormData.append('civilstatus', civilstatus);
		bodyFormData.append('religion', religion);
		bodyFormData.append('sex', sex);
		bodyFormData.append('height', height);
		bodyFormData.append('weight', weight);
		// bodyFormData.append('age', age);
		bodyFormData.append('nameofspouse', nameofspouse);
		bodyFormData.append('numofchild', numofchild);
		bodyFormData.append('fathersname', fathersname);
		bodyFormData.append('fatoccupation', fatoccupation);
		bodyFormData.append('mothersname', mothersname);
		bodyFormData.append('motoccupation', motoccupation);
		bodyFormData.append('emergencycontact', emergencycontact);
		bodyFormData.append('addressemercontact', addressemercontact);
		bodyFormData.append('cellnoemercontact', cellnoemercontact);
		//EDUCATIONAL BACKGROUND
		bodyFormData.append('elementary', elementary);
		bodyFormData.append('elemyeargrad', elemyeargrad);
		bodyFormData.append('highschool', highschool);
		bodyFormData.append('hsyeargrad', hsyeargrad);
		bodyFormData.append('college', college);
		bodyFormData.append('colyeargrad', colyeargrad);
		bodyFormData.append('course', course);
		bodyFormData.append('courseyrgrad', courseyrgrad);
		bodyFormData.append('vocational', vocational);
		bodyFormData.append('vocyrgrad', vocyrgrad);
		bodyFormData.append('skills', skills);
		//EMPLOYMENT HISTORY
		bodyFormData.append('from1', from1);
		bodyFormData.append('from2', from2);
		bodyFormData.append('from3', from3);
		bodyFormData.append('to1', to1);
		bodyFormData.append('to2', to2);
		bodyFormData.append('to3', to3);
		bodyFormData.append('compaddress1', compaddress1);
		bodyFormData.append('compaddress2', compaddress2);
		bodyFormData.append('compaddress3', compaddress3);
		bodyFormData.append('posi1', posi1);
		bodyFormData.append('posi2', posi2);
		bodyFormData.append('posi3', posi3);
		bodyFormData.append('sal1', sal1);
		bodyFormData.append('sal2', sal2);
		bodyFormData.append('sal3', sal3);
		//CHARACTER REFERENCE
		bodyFormData.append('refname1', refname1);
		bodyFormData.append('refname2', refname2);
		bodyFormData.append('refname3', refname3);
		bodyFormData.append('refoccuaddress1', refoccuaddress1);
		bodyFormData.append('refoccuaddress2', refoccuaddress2);
		bodyFormData.append('refoccuaddress3', refoccuaddress3);
		bodyFormData.append('refcelno1', refcelno1);
		bodyFormData.append('refcelno2', refcelno2);
		bodyFormData.append('refcelno3', refcelno3);
		bodyFormData.append('sssno', sssno);
		bodyFormData.append('philhealthno', philhealthno);
		bodyFormData.append('tinno', tinno);
		bodyFormData.append('pagibigno', pagibigno);
		bodyFormData.append('profilepic', profilepic);

		return axios({
			method: "post",
			url: API_COMPLETE_PROFILE,
			data: bodyFormData,
			headers: { "Content-type": "application/json; charset=UTF-8" },
		})
		.then(response => {
			localStorage.setItem("user", JSON.stringify(response.data));
		return response.data
	})

	}
  
	getCurrentUser() {
		return JSON.parse(localStorage.getItem('user'));
	}

	getPersonalInfoData(id) {
		//PERSON INFO
		axios.get(API_PER_INFO+'personalinfo?userid='+id)
		.then(response => {
		// console.log(response.data.data);
			localStorage.setItem('personal_info', JSON.stringify(response.data.data));
		});
		return JSON.parse(localStorage.getItem('personal_info'));
	}

	getEducInfoData(id) {
		//EDUC INFO
		axios.get(API_EDUC_INFO+'educinfo?userid='+id)
		.then(response => {
			localStorage.setItem('educ_info', JSON.stringify(response.data.data));
		});
		return JSON.parse(localStorage.getItem('educ_info'));
	}

	getEmploymentInfoData(id) {
		//EMPLOYMENT INFO
		axios.get(API_EMPLOYMENT_INFO+'employmentinfo?userid='+id)
		.then(response => {
			localStorage.setItem('employment_info', JSON.stringify(response.data.data));
		});
		return JSON.parse(localStorage.getItem('employment_info'));
	}

	getCharacterInfoData(id) {
		//CHARACTER INFO
		axios.get(API_CHARACTER_INFO+'characterinfo?userid='+id)
		.then(response => {
			localStorage.setItem('character_info', JSON.stringify(response.data.data));
		});
		return JSON.parse(localStorage.getItem('character_info'));
	}

}

export default new AuthService();
