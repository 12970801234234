import React, { Component } from "react";
import AuthService from "../../services/auth.service";
import JobsService from "../../services/job.service";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { List } from 'react-content-loader';
import ReactPaginate from 'react-paginate';

import "../Component.css";

import * as moment from 'moment';
import { Link } from "react-router-dom";

export default class Applicantjobs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			redirect: null,
			userReady: false,
			currentUser: null,
			jobs: [],
			filter: "",
			box:'',
			offset: 0,
			perPage: 10,
			currentPage: 0,
			pageCount: 0, 
			search: false
		};
	}

    handleChange = (event) => {

		this.setState({ filter: event.target.value , search: event.target.value? true:false});
    }

	componentDidMount() {
		const currentUserData = AuthService.getCurrentUser();
		if (currentUserData === null) {
			this.setState({ redirect: "/dashboard" });
		} else {
			this.setState({loading: true});
			const id = currentUserData.data.encode_name;
			JobsService.getJobsApplied(id,2).then(
				response => {
					if (response.status) {
						const jobs = response.data;
						// console.log(jobs);
						this.setState({jobs, loading: false, search: false});
					}
				},
				error => {
					console.log(error);
				}
			);
			this.setState({ currentUser: currentUserData.data, userReady: true })
			this.receivedData();
		}
	}

	receivedData = () => {
		const currentUserData = AuthService.getCurrentUser();
		const id = currentUserData.data.encode_name;
		JobsService.getJobsApplied(id,2).then(
			response => {
				if (response.status) {
					const jobs = response.data;
					const slice = jobs.slice(this.state.offset, this.state.offset + this.state.perPage);
					const postData = slice.map(job => <React.Fragment key={job.id}>
						<Link to={`/jobview/${job.id}`} style={{textDecoration: 'none'}}>
							<Paper className="jobList" style={{width: "100%", marginTop: '15px'}} elevation={3}>
								<Grid item xs={12} style={{padding: "10px"}} className={(job.applied)? 'box': 'job'}>
									{
										(job.applied)?
										<div className="ribbon ribbon-top-right"><span>Applied</span></div>:''
									}
									<Grid container spacing={2} key={job.id}>
										<Grid item xs={3} key={job.id}>
											<span style={{
												fontSize: '20px',
												fontWeight: '600',
												color:'dodgerblue'
											}}>{job.job_position}</span>
											<br />
											{this.required(job.job_required)}
										</Grid>
										<Grid item xs={3}>
											<i className="fas fa-check-circle" style={{color:'green'}}></i>&nbsp;
											<span style={{fontWeight: 'bold',fontSize: '17px'}}>{job.company}</span>
											<span style={{fontSize: '12px', opacity: '.5', display: 'block'}}>Assignment: Mandaue, {job.employment_type}</span>
											{/* <span style={{fontSize: '12px', opacity: '.5'}}></span> */}
										</Grid>
										<Grid item xs={3}>
											<i className='fas fa-map-marker-alt'></i> {job.city}<br/>{job.street}
										</Grid>
										<Grid item xs={3}>
											<i className="fas fa-clock" style={{color:'royalblue'}}></i> 
											&nbsp;{moment(job.created).format('MMM DD YYYY')}
											<span style={{ display: 'block',fontSize: '0.66em', opacity: '.5'}}>{moment(job.created).startOf('hour').fromNow()}</span>
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						</Link>
					</React.Fragment>)
					this.setState({pageCount: Math.ceil(jobs.length / this.state.perPage), postData});
				}
			},
			error => {
				console.log(error);
			}
		);
	}

	// filter the required options
	required(req) {
		const qualif = JSON.parse(req);
		var reqdesc = [];
		qualif.forEach(element => {
			var withEx = 'Year';
			if(element.match(new RegExp(`${withEx}`, 'gi'))){
				var sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(224, 57, 151)'
					}}></i>
			}

			if(element.match(new RegExp(`No`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(242, 113, 28)'
					}}></i>
			}

			if(element.match(new RegExp(`High`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(251, 189, 8)'
					}}></i>
			}

			if(element.match(new RegExp(`years`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(33, 186, 69)'
					}}></i>
			}

			if(element.match(new RegExp(`5+`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(33, 133, 208)'
					}}></i>
			}

			if(element.match(new RegExp(`Manegerial/Supervisory`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(33, 133, 208)'
					}}></i>
			}


			reqdesc.push(<div key={element}><span style={{fontSize: '13px'}}>
							{sprite} &nbsp;
							{element}
						</span><br /></div>)
		});
		return reqdesc;
	}

	handlePageClick = (e) => {
		console.log(1);
		const selectedPage = e.selected;
		const offset = selectedPage * this.state.perPage;
  
		this.setState({
			currentPage: selectedPage,
			offset: offset
		}, () => {
			this.receivedData()
		});
  
	};

	render() {
		const { filter, jobs } = this.state;
		const lowercasedFilter = filter.toString().toLowerCase();
		const filteredData = jobs.filter(item => {
			return Object.keys(item).some(key =>
				item[key].toString().toLowerCase().includes(lowercasedFilter)
			);
		});
		return (
			<div className="container" id="createApplicContainer" style={{marginBottom: '30px'}}>
			{
				(this.state.userReady) ?
					<div style={{paddingTop:'50px'}}>
							<Typography component="h1" variant="h5">
								Jobs
							</Typography>
							<br />
							<TextField 
								label="Search job"
								onChange={this.handleChange}
								name="search_job"
								value={filter}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								fullWidth
								style={{marginTop:'-35px'}}
							/>
							{
								this.state.loading? 
								<div><br/><List /><br/></div>
							:<div>
							{
								this.state.search &&
							<div>
							{filteredData.map((job,key) => {
								return(
									<Link to={`/jobview/${job.id}`} key={key} style={{textDecoration: 'none'}}>
										<Paper className="jobList" key={job.id} style={{width: "100%", marginTop: '15px'}} elevation={3}>
											<Grid item xs={12} style={{padding: "10px"}} key={job.id} className={(job.applied)? 'box': 'job'}>
												{
													(job.applied)?
													<div className="ribbon ribbon-top-right"><span>Applied</span></div>:''
												}
												<Grid container spacing={2} >
													<Grid item xs={3}>
														<span style={{
															fontSize: '20px',
															fontWeight: '600',
															color:'dodgerblue'
														}}>{job.job_position}</span>
														<br />
														{this.required(job.job_required)}
													</Grid>
													<Grid item xs={3}>
														<i className="fas fa-check-circle" style={{color:'green'}}></i>&nbsp;
														<span style={{fontWeight: 'bold',fontSize: '17px'}}>{job.company}</span>
														<span style={{display:'block',fontSize: '13px',color: 'dodgerblue'}}>{job.employment_type}</span>
													</Grid>
													<Grid item xs={3}>
													<i className='fas fa-map-marker-alt'></i> {job.city}<br/>{job.street}
													</Grid>
													<Grid item xs={3}>
														<i className="fas fa-clock" style={{color:'royalblue'}}></i> 
														&nbsp;{moment(job.created).format('MMM DD YYYY')}
														<span style={{ display: 'block',fontSize: '0.66em'}}>{moment(job.created).startOf('hour').fromNow()}</span>
													</Grid>
												</Grid>
											</Grid>
										</Paper>
									</Link>
								)
							})}
							</div>
							}
							{
								!this.state.search &&
								<div>
									{this.state.postData}
									<ReactPaginate
										previousLabel={"prev"}
										nextLabel={"next"}
										breakLabel={"..."}
										breakClassName={"break-me"}
										pageCount={this.state.pageCount}
										marginPagesDisplayed={2}
										pageRangeDisplayed={5}
										onPageChange={this.handlePageClick}
										containerClassName={"pagination"}
										subContainerClassName={"pages pagination"}
										activeClassName={"active"}
									/>
								</div>
							}
							</div>
							}
					</div>
				: null
			}
			</div>
		);
	}
}
