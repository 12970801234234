import React, { Component } from "react";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import InputLabel from '@material-ui/core/InputLabel';
import '../Component.css';

import JobsService from "../../services/job.service";

export default class Createjobs extends Component {
  constructor(props) {
    super(props);
	this.state = {editorState: EditorState.createEmpty()};
	var date = (new Date()).toISOString().split('T')[0];
    this.state = {
        formData: {
            job_position: '',
			company: 'Cebu General Services (CGSI)',
            vacancy: '',
            job_details: '',
			employment_type: '',
            street: 'RKD Bldg., A.S. Fortuna St.',
            city: 'Mandue City, Cebu',
			assgnmnt_area:'',
			No: false,
			Year: false,
			High: false,
			More: false,
			qualifications: [],
			Category: '',
			availability:'',
			incharge_email: '',
			status: 0,
			created: date
        },
        submitted: false,
        loading: false,
        message: "",
		checked: true,
		disableDetails: false,
		disabled: true,
		qualifOptions: [
			"No Experience",
			"1 Year or less",
			"High school graduate are welcome",
			"College Level are welcome",
			"College Graduate",
			"2 to 4 years",
			"5+ Years",
			"Manegerial/Supervisory"
		],
		Category: []
    }
  }
  
	componentDidMount() {
		JobsService.getJobCategory().then(
			response => {
				console.log(response);
				if (response.status) {
					const jobCategory = response.data;
					console.log(jobCategory);
					this.setState({Category: jobCategory, loading: false});
				}
			},
			error => {
				this.setState({jobsList: false});
			}
		);
	}
	handleChange = (event) => {
		const { formData } = this.state;
		formData[event.target.name] = event.target.value;
		if (formData.qualifications.length < 1) {
			this.setState({ disabled: true });
		} else {
			this.setState({ disabled: false });
		}
		this.setState({ formData });	
	}
	
	handleChangeChecked = (event) => {
		const { formData } = this.state;
		formData[event.target.name] = event.target.checked;
		if(formData.High || formData.More || formData.No || formData.Year) {
			this.setState({checkedData: true});
		} else {
			this.setState({checkedData: false});
		}
	}

	onChange = (editorState) => {
		const contentState = editorState.getCurrentContent();
		if(contentState.hasText()) {
			this.setState({disableDetails: true, disabled: false});
		} else {
			this.setState({disableDetails: false, disabled: true});
		}
		this.saveContent(contentState);
		this.setState({
			editorState
		});
	}

	saveContent = (content) => {
		this.setState({job_details: JSON.stringify(convertToRaw(content))})
	}

	closeDiv = () => {
		this.props.history.push("/jobs");
	}

	handleSubmit = () => {
		this.setState({
			loading: true
		});	
		this.setState({ submitted: true }, () => {
			let jobid = '';
			JobsService.addNewJob(
				jobid,
				this.state.formData.job_position,
				this.state.formData.vacancy,
				this.state.formData.street,
				this.state.formData.city,
				this.state.job_details,
				JSON.stringify(this.state.formData.qualifications),
				this.state.formData.employment_type,
				this.state.formData.company,
				this.state.formData.availability,
				this.state.formData.status,
				this.state.formData.created,
				this.state.formData.assgnmnt_area,
				this.state.formData.Category,
				this.state.formData.incharge_email
			).then(
			response => {
					if (response.status) {
						setTimeout(() => 
							this.setState({ 
								message: response.message,
								successful: true,
								loading: false
							}), 3000);
							setTimeout(() => 
							this.setState({ 
								successful: false
							}), 4000);
							setTimeout(() => 
							this.props.history.push("/jobs"), 4500);
					}
				}, error => {
					// const resMessage = error.response.data;
				}
			);
			setTimeout(() => 
			this.setState({ submitted: false }), 5000);
		});
		}

  render() {
    const { formData, submitted, disabled } = this.state;
    return (
		<div id="createApplicContainer" style={{paddingTop:'45px',paddingBottom: '10px'}}>
			<Paper>
				<span onClick={this.closeDiv } style={{color: '#0a0d0a',float: 'right',paddingRight: '10px',fontSize: '21px', cursor: 'pointer'}}><i className="fas fa-times"></i></span>
				<ValidatorForm
					ref="form"
					onSubmit={this.handleSubmit}
					style={{padding: '10px',marginTop: '10px'}}
				>
					<Typography component="h1" variant="h5" style={{textAlign:'center'}}>
						Add New Job
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12}> 
							<TextValidator
								label="Company*"
								onChange={this.handleChange}
								name="company"
								value={formData.company}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['required']}
								errorMessages={['Company is required']}
								fullWidth
								style={{marginTop:'-20px'}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={6}> 
							<TextValidator
								label="Position*"
								onChange={this.handleChange}
								name="job_position"
								value={formData.job_position}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['required']}
								errorMessages={['Position is required']}
								fullWidth
								style={{marginTop:'-15px'}}
							/>
						</Grid>
						<Grid item xs={6}> 
							<InputLabel style={{fontSize: '13px'}}>
								Job Category*
							</InputLabel>
							<Select
								label="Category"
								labelId="Category_select"
								id="Category"
								// multiple
								value={formData.Category}
								name="Category"
								onChange={this.handleChange}
								input={<Input />}
								renderValue={selected => selected}
								fullWidth
							>
								{this.state.Category.map(name => (
									<MenuItem key={name.category_id} value={name.category_name}>{name.category_name}
									</MenuItem>
								))}
							</Select>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={6}> 
							<TextValidator
									name="availability"
									label="Availability"
									select
									value={formData.availability}
									InputLabelProps={{ shrink: true }}
									onChange={this.handleChange}
									validatorListener={this.validatorListener}
									fullWidth
									style={{marginTop: '-4px'}}
							>
								<MenuItem value={''} selected>Not Urgent</MenuItem>
								<MenuItem value={'Urgent'} selected>Urgent</MenuItem>
							</TextValidator>
						</Grid>
						<Grid item xs={6}>
							<TextValidator
								label="Vacancy*"
								onChange={this.handleChange}
								name="vacancy"
								value={formData.vacancy}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
								validators={['required']}
								errorMessages={['Vacancy is required']}
								fullWidth
								type="number"
								style={{marginTop:'-15px'}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={6}> 
							<InputLabel style={{fontSize: '13px'}}>
								Qualifications*
							</InputLabel>
							<Select
								label="Qualification"
								labelId="qualification_select"
								id="qualification"
								multiple
								value={formData.qualifications}
								name="qualifications"
								onChange={this.handleChange}
								input={<Input />}
								renderValue={selected => selected.join(", ")}
								fullWidth
							>
								{this.state.qualifOptions.map(name => (
									<MenuItem key={name} value={name}>
									<Checkbox checked={formData.qualifications.indexOf(name) > -1} />
									<ListItemText primary={name} />
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={6}>
							<TextValidator
									name="employment_type"
									label="Employment Type*"
									select
									value={formData.employment_type}
									InputLabelProps={{ shrink: true }}
									validators={['required']}
									errorMessages={['Employment Type is required.']}
									onChange={this.handleChange}
									validatorListener={this.validatorListener}
									fullWidth
									style={{marginTop: '7px'}}
							>
								<MenuItem value={'Fulltime'} selected>Full Time</MenuItem>
								<MenuItem value={'ProjectBased'}>Project Base</MenuItem>
								<MenuItem value={'Reliever'}>Reliever</MenuItem>
							</TextValidator>
						</Grid>	
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={6}> 
							<TextValidator
								label="Street, Building Address*"
								onChange={this.handleChange}
								name="street"
								value={formData.street}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['required']}
								errorMessages={['Street or Building is required']}
								fullWidth
								style={{marginTop: '-15px'}}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextValidator
								label="City, Province Address*"
								onChange={this.handleChange}
								name="city"
								value={formData.city}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
								validators={['required']}
								errorMessages={['City Address is required']}
								fullWidth
								style={{marginTop: '-15px'}}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={6}> 
							<TextValidator
								label="Area of Assignment*"
								onChange={this.handleChange}
								name="assgnmnt_area"
								value={formData.assgnmnt_area}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['required']}
								errorMessages={['Provide area of assignment']}
								fullWidth
								style={{marginTop: '-15px'}}
							/>
						</Grid>
						<Grid item xs={6}> 
							<TextValidator
								label="Recruitment Incharge Email*"
								onChange={this.handleChange}
								name="incharge_email"
								value={formData.incharge_email}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['required', 'isEmail']}
								errorMessages={['Email is required', 'Email is not valid']}
								fullWidth
								style={{marginTop: '-15px'}}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={12}> 
							<label style={{opacity:'.5'}}>Job Description*</label>
							<Paper style={{ padding: '10px', minHeight: '300px' }}>
								<Editor
									name="employment_type"
									editorState={this.state.editorState}
									value={formData.job_details}
									onEditorStateChange={this.onChange}
									placeholder="Enter Job Details"
									fullWidth
									style={{padding:'20px'}}
								/>
							</Paper>
						</Grid>
					</Grid>
					{this.state.successful && (
						<div>
							<br/>
							<div className="form-group">
								<div
									className={
										this.state.successful
										? "alert alert-success"
										: "alert alert-danger"
									}
									role="alert"
								>
								{this.state.message}
								</div>
							</div>
						</div>
					)}
					<div className="form-group" style={{marginTop: '-30px'}}>
						<div>
						<Button
							color="primary"
							variant="contained"
							type="submit"
							disabled={disabled || submitted}
							style={{ 'width': '100%', marginTop: '30px', height: '45px'}}
						>
							{this.state.loading && (
								<div><span className="spinner-border spinner-border-sm"></span>&nbsp;</div>
							)}

							{
								(submitted && ' Saving new job..')
								|| (!submitted && 'Save New Job ')
							}
						</Button>
						</div>
					</div>
					</ValidatorForm>
			</Paper>
		</div>
		);
  }
}
