import React, { Component } from "react";
// import axios from "axios";
import AuthService from "../services/auth.service";
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import './Component.css';	

import ImageUploader from 'react-images-upload';

import profpic from "../image/default.png";

export default class Profile extends Component {
  constructor(props) {
    super(props);
	this.state = {
	redirect: null,
	userReady: false,
	currentUser: null,
	currentUserData: null,
	profileData: null,
	sessionID: null,
	reload: false,
	message: '',
	successful: false,
	step: 1,
	pictures: [],
	currentUserAfterSubmit:[],
	personInfo: {
		//PERSONAL INFORMATION
		addressemercontact: '',
		userid:'',
		presentaddress: '',
		prescelno: '',
		provincialaddress: '',
		provcelno: '',
		// birthdate: '',
		birthplace: '',
		civilstatus: '',
		religion: '',
		sex: '',
		height: '',
		weight: '',
		// age: '',
		nameofspouse: '',
		numofchild: '',
		fathersname: '',
		fatoccupation: '',
		mothersname: '',
		motoccupation: '',
		emergencycontact: '',
		cellnoemercontact: '',
	},
	educInfo: {
		//EDUCATIONAL BACKGROUND
		elementary: '',
		elemyeargrad: '',
		highschool: '',
		hsyeargrad: '',
		college: '',
		colyeargrad: '',
		course: '',
		courseyrgrad: '',
		vocational: '',
		vocyrgrad: '',
		skills: '',
	},
	employmentInfo: {
		//EMPLOYMENT HISTORY
		from1: '',
		from2: '',
		from3: '',
		to1: '',
		to2: '',
		to3: '',
		compaddress1: '',
		compaddress2: '',
		compaddress3: '',
		posi1: '',
		posi2: '',
		posi3: '',
		sal1: '',
		sal2: '',
		sal3: '',
	},
	characterInfo: {
		//CHARACTER REFERENCE
		refname1: '',
		refname2: '',
		refname3: '',
		refoccuaddress1: '',
		refoccuaddress2: '',
		refoccuaddress3: '',
		refcelno1: '',
		refcelno2: '',
		refcelno3: '',
		sssno: '',
		philhealthno: '',
		tinno: '',
		pagibigno: ''
	},
	data: {
	},
	disabled: false,
	submitted: false,
	loading: false,
	photo: null,
	logUser: []
	};
  }

  componentDidMount() {
	let id = this.props.location.pathname.split('/')[2];
	// console.log(id);

	AuthService.getUserDefualt(id).then(
		response => {
			// console.log(response);
			localStorage.removeItem('defaultUser');
			localStorage.setItem('defaultUser', JSON.stringify(response));
			// this.setState({ defaultUser:response });
		},
		error => {
			console.log(error);
		}
	);

	this.setState({ logUser: JSON.parse(localStorage.getItem('defaultUser')) });

	const cUser = AuthService.getCurrentUser();
    if (cUser === null) {
      this.setState({ redirect: "/home" });
    } else {
		// Get user information data
		
		// console.log(search);
		// let id = new URLSearchParams(search).get('id');
		let getUser = JSON.parse(localStorage.getItem('user'));
		console.log(getUser);
		
		if (id === 'undefined') {
			id = getUser.data.data[0].encode_name;
			// id = cUser.data.encode_name;
		}

		// Get User defualt info
		AuthService.getUserDefualt(id).then(
			response => {
				// console.log(response);
				localStorage.removeItem('defaultUser');
				localStorage.setItem('defaultUser', JSON.stringify(response));
				// this.setState({ defaultUser:response });
			},
			error => {
				console.log(error);
			}
		);

		// Get Personal Info
		AuthService.personalInfo(id).then(
			response => {
				// console.log(response);
				localStorage.removeItem('personalInfo');
				localStorage.setItem('personalInfo', JSON.stringify(response));
				// this.setState({ personInfo: response });
			},
			error => {
				console.log(error);
			}
		);

		// Get Education Info
		AuthService.educInfo(id).then(
			response => {
				// console.log(response);
				localStorage.removeItem('educInfo');
				localStorage.setItem('educInfo', JSON.stringify(response));
				// this.setState({ educInfo:response });
			},
			error => {
				console.log(error);
			}
		);

		// Get Employment Info
		AuthService.employmentInfo(id).then(
			response => {
				localStorage.removeItem('employmentInfo');
				localStorage.setItem('employmentInfo', JSON.stringify(response));
				// this.setState({ employmentInfo:response });
			},
			error => {
				console.log(error);
			}
		);

		// Get Char ref Info
		AuthService.characterInfo(id).then(
			response => {
				localStorage.removeItem('characterInfo');
				localStorage.setItem('characterInfo', JSON.stringify(response));
				// this.setState({ characterInfo:response });
			},
			error => {
				console.log(error);
			}
		);
		
		setTimeout(() => 
		this.setState({ 
			currentUser: cUser, 
			// currentUserDetails: this.state.defaultUser,
			userReady: true,
			data: {...JSON.parse(localStorage.getItem('defaultUser')),...JSON.parse(localStorage.getItem('personalInfo')),...JSON.parse(localStorage.getItem('educInfo')),...JSON.parse(localStorage.getItem('employmentInfo')),...JSON.parse(localStorage.getItem('characterInfo'))},
			// data: { ...this.state.defaultUser,...this.state.personInfo, ...this.state.educInfo, ...this.state.employmentInfo, ...this.state.characterInfo },
			loading: true,
			photo: cUser.data.photo
		}), 300);
		// console.log("all data with val");
		// console.log(this.state.data);
		// console.log("with val");
    }
  }	

	onChange = (event) => {
		const { data } = this.state;
		data[event.target.name] = event.target.value;
		// console.log(data);
		var sessUser = this.state.currentUser;
		this.setState({sessionID: sessUser.id});
		this.setState({ data: data });
	}

	submit = () => {
		this.form.submit();
	}

	handleSubmit = (e) => {
		// console.log(e);
		this.setState({ submitted: true }, () => {
			//update all information
			// localStorage.setItem('photo',document.getElementById('uploadPhoto').src);
			const profilePic = localStorage.getItem('photo');
			// console.log(profilePic);
			AuthService.completeProfile(
			//PERSONAL INFORMATION
			this.state.data.encode_name,
			this.state.data.presentaddress,
			this.state.data.prescelno,
			this.state.data.provincialaddress,
			this.state.data.provcelno,
			// this.state.data.birthdate,
			this.state.data.birthplace,
			this.state.data.civilstatus,
			this.state.data.religion,
			this.state.data.sex,
			this.state.data.height,
			this.state.data.weight,
			// this.state.data.age,
			this.state.data.nameofspouse,
			this.state.data.numofchild,
			this.state.data.fathersname,
			this.state.data.fatoccupation,
			this.state.data.mothersname,
			this.state.data.motoccupation,
			this.state.data.emergencycontact,
			this.state.data.addressemercontact,
			this.state.data.cellnoemercontact,
			//EDUCATIONAL BACKGROUND
			this.state.data.elementary,
			this.state.data.elemyeargrad,
			this.state.data.highschool,
			this.state.data.hsyeargrad,
			this.state.data.college,
			this.state.data.colyeargrad,
			this.state.data.course,
			this.state.data.courseyrgrad,
			this.state.data.vocational,
			this.state.data.vocyrgrad,
			this.state.data.skills,
			//EMPLOYMENT HISTORY
			this.state.data.from1,
			this.state.data.from2,
			this.state.data.from3,
			this.state.data.to1,
			this.state.data.to2,
			this.state.data.to3,
			this.state.data.compaddress1,
			this.state.data.compaddress2,
			this.state.data.compaddress3,
			this.state.data.posi1,
			this.state.data.posi2,
			this.state.data.posi3,
			this.state.data.sal1,
			this.state.data.sal2,
			this.state.data.sal3,
			//CHARACTER REFERENCE
			this.state.data.refname1,
			this.state.data.refname2,
			this.state.data.refname3,
			this.state.data.refoccuaddress1,
			this.state.data.refoccuaddress2,
			this.state.data.refoccuaddress3,
			this.state.data.refcelno1,
			this.state.data.refcelno2,
			this.state.data.refcelno3,
			this.state.data.sssno,
			this.state.data.philhealthno,
			this.state.data.tinno,
			this.state.data.pagibigno,
			profilePic
			).then(
				response => {
					// console.log(response);
					this.setState({
						message: response.message,
						data: this.state.data
					});
					let res = response.data.data[0];
					// console.log(res);
					this.setState({currentUser:response});
					localStorage.setItem('logUser',JSON.stringify(response));
					setTimeout(() => this.setState({ successful: true }), 5000);
					setTimeout(() => this.setState({ successful: false }), 8000);
					
					this.props.history.push("/profile/"+res.encode_name);
				},
				error => {
					console.log(error);
				}
			);

			setTimeout(() => this.setState({ submitted: false }), 5000);
		});
	}

	prevStep = () => {
		let { step } = this.state;
		if (step > 1) {
			step--;
		}
		this.setState({ step });
	}

	nextStep = () => {
		this.form.isFormValid(false).then((isValid) => {
			if (isValid) {
				let { step } = this.state;
				if (step < 4) {
					step++;
				}
				this.setState({ step });
			}
		});
	}

	validatorListener = (result) => {
		this.setState({ disabled: !result });
	}

	getAge(dateString) {
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}


  renderStep = () => {
	// currentUser, currentUserAfterSubmit,
		const { step, data, loading, logUser } = this.state;
		// console.log(logUser);
		let content = null;
		switch (step) {
			case 1:
				if(!loading) {
					content = (<div>loading</div>)
				} else {
				content = (
					<div style={{marginTop: '-145px'}}>
						<Typography component="h4" variant="h5" style={{opacity:'.5', fontWeight:'700', fontSize:'17px'}}>
							PERSONAL DATA
						</Typography>
						<hr />
						<br />
						<Grid container spacing={2}>
							<Grid item xs={4}>
								{/* <label style={{fontSize:'12px'}}>First Name </label> */}
								{/* <span style={{fontWeight:'bold',textTransform: 'capitalize'}}>{currentUser.data.first_name}</span> */}
								<TextValidator
									name="firstname"
									label="Firstname"
									value={logUser.first_name}
									inputProps={{style: { marginBottom: '5px', marginTop:'5px', textTransform: 'capitalize' }}}
									fullWidth
									style={{marginTop: '-25px'}}
									readOnly
								/>
							</Grid>
							<Grid item xs={4}>
								{/* <label style={{fontSize:'12px'}}>Middle Name</label>
								<span style={{fontWeight:'bold',textTransform: 'capitalize'}}>{currentUser.data.middle_name}</span> */}
								<TextValidator
									name="middlename"
									label="Middle Name"
									value={logUser.middle_name}
									InputLabelProps={{ shrink: true }}
									inputProps={{style: { marginBottom: '5px', marginTop:'5px', textTransform: 'capitalize'  }}}
									fullWidth
									readOnly
									style={{marginTop: '-25px'}}
								/>
							</Grid>
							<Grid item xs={4}>
								{/* <label style={{fontSize:'12px'}}>Last Name</label>
								<span style={{fontWeight:'bold',textTransform: 'capitalize'}}>{currentUser.data.last_name}</span> */}
								<TextValidator
									name="lastname"
									label="Lastname"
									value={logUser.last_name}
									inputProps={{style: { marginBottom: '5px', marginTop:'5px', textTransform: 'capitalize' }}}
									fullWidth
									readOnly
									style={{marginTop: '-25px'}}
								/>
							</Grid>
							<br />
							<Grid item xs={6}>
									{/* <label style={{fontSize:'12px'}}>Email</label>
									<span style={{fontWeight:'bold'}}>{currentUser.data.email}</span> */}
								<TextValidator
									name="email"
									label="Email"
									value={logUser.email}
									inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
									fullWidth
									style={{marginTop: '-20px'}}
								/>
							</Grid>
							<Grid item xs={6}>
								{/* <label style={{fontSize:'12px'}}>Birthdate</label>
								<span style={{fontWeight:'bold',textTransform: 'capitalize'}}>{currentUser.data.birthdate}</span> */}
								<TextValidator
									name="birthdate"
									label="Birth Date"
									type="date"
									InputLabelProps={{ shrink: true }}
									validators={['required']}
									errorMessages={['Birth Date is required.']}
									value={data.birthdate}
									onChange={this.onChange}
									inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
									validatorListener={this.validatorListener}
									fullWidth
									style={{marginTop: '-20px'}}
								/>
							</Grid>
							{/* <Grid item xs={4}>
								<label style={{fontSize:'12px'}}>Age</label>
								<span style={{fontWeight:'bold'}}>{this.getAge(currentUser.data.birthdate)}</span>
							</Grid> */}
							<Grid item xs={8}>
								<TextValidator
									name="presentaddress"
									label="Present Address"
									validators={['required']}
									errorMessages={['Present Address is required.']}
									value={data.presentaddress}
									onChange={this.onChange}
									inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
									validatorListener={this.validatorListener}
									fullWidth
									style={{marginTop: '-20px'}}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextValidator
									name="prescelno"
									label="Cel. No."
									type="number"
									validators={['required']}
									errorMessages={['Present Cel. No. is required.']}
									value={data.prescelno}
									onChange={this.onChange}
									inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
									validatorListener={this.validatorListener}
									fullWidth
									style={{marginTop: '-20px'}}
								/>
							</Grid><br />
							<Grid item xs={8}>
								<TextValidator
									name="provincialaddress"
									label="Provincial Address"
									validators={['required']}
									errorMessages={['Provincial Address is required.']}
									value={data.provincialaddress}
									onChange={this.onChange}
									inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
									validatorListener={this.validatorListener}
									fullWidth
									style={{marginTop: '-20px'}}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextValidator
									name="provcelno"
									label="Cel. No."
									type="number"
									validators={['required']}
									errorMessages={['Provincial Cel. No. is required.']}
									value={data.provcelno}
									onChange={this.onChange}
									inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
									validatorListener={this.validatorListener}
									fullWidth
									style={{marginTop: '-20px'}}
								/>
							</Grid><br />
						<Grid item xs={5}>
	  						<TextValidator
								name="birthplace"
								label="Birth Place"
								validators={['required']}
								errorMessages={['Birth Place is required.']}
								value={data.birthplace}
								onChange={this.onChange}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
								validatorListener={this.validatorListener}
								fullWidth
								style={{marginTop: '-20px'}}
							/>
						</Grid>
						<br />
						<Grid item xs={4}>
  							<TextValidator
								name="civilstatus"
								label="Civil Status"
								select
								value={data.civilstatus}
								InputLabelProps={{ shrink: true }}
								validators={['required']}
								errorMessages={['Civil status is required.']}
								onChange={this.onChange}
								validatorListener={this.validatorListener}
								fullWidth
								style={{marginTop: '-15px'}}
							>
								<MenuItem value={'Single'} selected>Single</MenuItem>
								<MenuItem value={'Married'}>Married</MenuItem>
							</TextValidator>
						</Grid>
						<Grid item xs={3}>
							<TextValidator
								name="sex"
								label="Sex"
								select
								InputLabelProps={{ shrink: true }}
								validators={['required']}
								errorMessages={['Sex is required.']}
								onChange={this.onChange}
								value={data.sex}
								validatorListener={this.validatorListener}
								fullWidth
								style={{marginTop: '-15px'}}
							>
								<MenuItem value={'Male'}>Male</MenuItem>
								<MenuItem value={'Female'}>Female</MenuItem>
							</TextValidator>
						</Grid>
						<Grid item xs={4}>
	  						<TextValidator
								name="religion"
								label="Religion"
								validators={['required']}
								errorMessages={['Religion is required.']}
								value={data.religion}
								onChange={this.onChange}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validatorListener={this.validatorListener}
								fullWidth
								style={{marginTop: '-20px'}}
							/>
						</Grid>
						<br />
						<Grid item xs={4}>
  							<TextValidator
								name="height"
								label="Height"
								validators={['required']}
								errorMessages={['Height is required.']}
								value={data.height}
								onChange={this.onChange}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validatorListener={this.validatorListener}
								fullWidth
								style={{marginTop: '-25px'}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextValidator
								name="weight"
								label="Weight"
								validators={['required']}
								errorMessages={['Weight is required.']}
								value={data.weight}
								onChange={this.onChange}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validatorListener={this.validatorListener}
								fullWidth
								style={{marginTop: '-25px'}}
							/>
						</Grid><br />
						<Grid item xs={12}>
	  						<TextValidator
								name="nameofspouse"
								label="Name of Spouse"
								validators={['required']}
								errorMessages={['Name of spouse is required.']}
								value={data.nameofspouse}
								onChange={this.onChange}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validatorListener={this.validatorListener}
								fullWidth
								style={{marginTop: '-25px'}}
							/>
						</Grid><br />
						<Grid item xs={12}>
	  						<TextValidator
								name="numofchild"
								label="Number of childre their name and ages"
								value={data.numofchild}
								onChange={this.onChange}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validatorListener={this.validatorListener}
								fullWidth
								style={{marginTop: '-25px'}}
							/>
						</Grid><br />
						<Grid item xs={6}>
	  						<TextValidator
								name="fathersname"
								label="Father's Name"
								validators={['required']}
								errorMessages={['Fathers name is required.']}
								value={data.fathersname}
								onChange={this.onChange}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validatorListener={this.validatorListener}
								fullWidth
								style={{marginTop: '-25px'}}
							/>
						</Grid>
						<Grid item xs={6}>
	  						<TextValidator
								name="fatoccupation"
								label="Occupation"
								validators={['required']}
								errorMessages={['Occupation is required.']}
								value={data.fatoccupation}
								onChange={this.onChange}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validatorListener={this.validatorListener}
								fullWidth
								style={{marginTop: '-25px'}}
							/>
						</Grid><br />
						<Grid item xs={6}>
	  						<TextValidator
								name="mothersname"
								label="Mother's Name"
								validators={['required']}
								errorMessages={['Mothers name is required.']}
								value={data.mothersname}
								onChange={this.onChange}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validatorListener={this.validatorListener}
								fullWidth
								style={{marginTop: '-25px'}}
							/>
						</Grid>
						<Grid item xs={6}>
	  						<TextValidator
								name="motoccupation"
								label="Occupation"
								validators={['required']}
								errorMessages={['Occupation is required.']}
								value={data.motoccupation}
								onChange={this.onChange}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validatorListener={this.validatorListener}
								fullWidth
								style={{marginTop: '-25px'}}
							/>
						</Grid><br />
						<Grid item xs={12}>
	  						<TextValidator
								name="emergencycontact"
								label="Person to be notified in case of emergency"
								validators={['required']}
								errorMessages={['Emergency contact name is required.']}
								value={data.emergencycontact}
								onChange={this.onChange}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validatorListener={this.validatorListener}
								fullWidth
								style={{marginTop: '-25px'}}
							/>
						</Grid><br />
						<Grid item xs={6}>
	  						<TextValidator
								name="addressemercontact"
								label="Address"
								validators={['required']}
								errorMessages={['Address is required.']}
								value={data.addressemercontact}
								onChange={this.onChange}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validatorListener={this.validatorListener}
								fullWidth
								style={{marginTop: '-25px'}}
							/>
						</Grid>
						<Grid item xs={6}>
	  						<TextValidator
								name="cellnoemercontact"
								label="Cel. No."
								validators={['required']}
								errorMessages={['Cel. No. is required.']}
								value={data.cellnoemercontact}
								onChange={this.onChange}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validatorListener={this.validatorListener}
								fullWidth
								style={{marginTop: '-25px'}}
							/>
						</Grid>
					</Grid>	
					</div>
				);
			}
				break;
			case 2:
				content = (
					<div>
						<Typography component="h5" variant="h6">
							EDUCATIONAL BACKGROUND
						</Typography>
						<span style={{fontSize: '13px',color: 'red'}}>Please provide exact detail, we will based here of what jobs you are qualified, please put <span style={{fontWeight:'bold'}}>NA</span> if not applicable.</span>
						<hr />
						<TableContainer component={Paper}>
						<Table aria-label="educ table">
							<TableHead>
								<TableRow>
									<TableCell>LEVEL</TableCell>
									<TableCell align="left">NAME OF SCHOOL AND ADDRESS</TableCell>
									<TableCell align="left">YEAR GRADUATED</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell component="th" scope="row">ELEMENTARY</TableCell>
									<TableCell align="left">
										<TextValidator
											key={2}
											name="elementary"
											validators={['required']}
											errorMessages={['Elementary is required.']}
											value={data.elementary}
											onChange={this.onChange}
											validatorListener={this.validatorListener}
											fullWidth
										/>
									</TableCell>
									<TableCell align="left">
										<TextValidator
											name="elemyeargrad"
											type="number"
											validators={['required']}
											errorMessages={['Year Graduated is required.']}
											value={data.elemyeargrad}
											onChange={this.onChange}
											validatorListener={this.validatorListener}
											fullWidth
										/>
										{/* <MuiPickersUtilsProvider utils={DateFnsUtils} >
											<DatePicker
												views={["year"]}
												label="Year only"
												value={selectedDate}
												onChange={handleDateChange}
											/>
										</MuiPickersUtilsProvider> */}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">SECONDARY</TableCell>
									<TableCell align="left">
										<TextValidator
											name="highschool"
											validators={['required']}
											errorMessages={['Highschool is required.']}
											value={data.highschool}
											onChange={this.onChange}
											validatorListener={this.validatorListener}
											fullWidth
										/>
									</TableCell>
									<TableCell align="left">
										<TextValidator
											name="hsyeargrad"
											type="number"
											validators={['required']}
											errorMessages={['Year Graduated is required.']}
											value={data.hsyeargrad}
											onChange={this.onChange}
											validatorListener={this.validatorListener}
											fullWidth
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">COLLEGE</TableCell>
									<TableCell align="left">
										<TextValidator
											name="college"
											validators={['required']}
											errorMessages={['College is required.']}
											value={data.college}
											onChange={this.onChange}
											validatorListener={this.validatorListener}
											fullWidth
										/>
									</TableCell>
									<TableCell align="left">
										<TextValidator
											name="colyeargrad"
											type="number"
											validators={['required']}
											errorMessages={['Year Graduated is required.']}
											value={data.colyeargrad}
											onChange={this.onChange}
											validatorListener={this.validatorListener}
											fullWidth
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">COURSE</TableCell>
									<TableCell align="left">
										<TextValidator
											name="course"
											validators={['required']}
											errorMessages={['Course is required.']}
											value={data.course}
											onChange={this.onChange}
											validatorListener={this.validatorListener}
											fullWidth
										/>
									</TableCell>
									<TableCell align="left">
										<TextValidator
											name="courseyrgrad"
											type="number"
											validators={['required']}
											errorMessages={['Year Graduated is required.']}
											value={data.courseyrgrad}
											onChange={this.onChange}
											validatorListener={this.validatorListener}
											fullWidth
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell component="th" scope="row">VACATIONAL</TableCell>
									<TableCell align="left">
										<TextValidator
											name="vocational"
											validators={['required']}
											errorMessages={['Vocational is required.']}
											value={data.vocational}
											onChange={this.onChange}
											validatorListener={this.validatorListener}
											fullWidth
										/>
									</TableCell>
									<TableCell align="left">
										<TextValidator
											name="vocyrgrad"
											type="number"
											validators={['required']}
											errorMessages={['Year Graduated is required.']}
											value={data.vocyrgrad}
											onChange={this.onChange}
											validatorListener={this.validatorListener}
											fullWidth
										/>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
						</TableContainer>
						<TextValidator
							name="skills"
							label="Skills"
							validators={['required']}
							errorMessages={['Skills is required.']}
							value={data.skills}
							onChange={this.onChange}
							inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
							validatorListener={this.validatorListener}
							fullWidth
						/>
					</div>
				);
				break;
			case 3:
				content = (
					<div>
						<Typography component="h5" variant="h6">
							EMPLOYMENT HISTORY
						</Typography>
						<hr />
						<Grid container spacing={2}>
							<Grid item xs={2}>
								From
							</Grid>
							<Grid item xs={2}>
								To
							</Grid>
							<Grid item xs={4}>
								Name and Adress of Company
							</Grid>
							<Grid item xs={3}>
								Position
							</Grid>
							<Grid item xs={1}>
								Salary
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={2}>
								<TextValidator
									key={3}
									name="from1"
									type="date"
									value={data.from1}
									onChange={this.onChange}
									fullWidth
								/>
							</Grid>
							<Grid item xs={2}>
								<TextValidator
									// key={3}
									name="to1"
									type="date"
									// label="email 3"
									// validators={['required']}
									// errorMessages={['required field']}
									value={data.to1}
									onChange={this.onChange}
									// validatorListener={this.validatorListener}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextValidator
									// key={3}
									name="compaddress1"
									// label="email 3"
									// validators={['required']}
									// errorMessages={['required field']}
									value={data.compaddress1}
									onChange={this.onChange}
									// validatorListener={this.validatorListener}
									fullWidth
								/>
							</Grid>
							<Grid item xs={3}>
								<TextValidator
									// key={3}
									name="posi1"
									// label="email 3"
									// validators={['required']}
									// errorMessages={['required field']}
									value={data.posi1}
									onChange={this.onChange}
									// validatorListener={this.validatorListener}
								/>
							</Grid>
							<Grid item xs={1}>
								<TextValidator
									// key={3}
									name="sal1"
									type="number"
									// label="email 3"
									// validators={['required']}/
									// errorMessages={['required field']}
									value={data.sal1}
									onChange={this.onChange}
									// validatorListener={this.validatorListener}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={2}>
								<TextValidator
									name="from2"
									type="date"
									// validators={['required']}
									// errorMessages={['required field']}
									value={data.from2}
									onChange={this.onChange}
									// validatorListener={this.validatorListener}
									fullWidth
								/>
							</Grid>
							<Grid item xs={2}>
								<TextValidator
									name="to2"
									type="date"
									value={data.to2}
									onChange={this.onChange}
									// validators={['required']}
									// errorMessages={['required field']}
									// validatorListener={this.validatorListener}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextValidator
									name="compaddress2"
									value={data.compaddress2}
									onChange={this.onChange}
									// validators={['required']}
									// errorMessages={['required field']}
									// validatorListener={this.validatorListener}
									fullWidth
								/>
							</Grid>
							<Grid item xs={3}>
								<TextValidator
									name="posi2"
									value={data.posi2}
									onChange={this.onChange}
									// validators={['required']}
									// errorMessages={['required field']}
									// validatorListener={this.validatorListener}
								/>
							</Grid>
							<Grid item xs={1}>
								<TextValidator
									name="sal2"
									type="number"
									value={data.sal2}
									onChange={this.onChange}
									// validators={['required']}
									// errorMessages={['required field']}
									// validatorListener={this.validatorListener}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={2}>
								<TextValidator
									name="from3"
									type="date"
									value={data.from3}
									onChange={this.onChange}
									// validators={['required']}
									// errorMessages={['required field']}
									// validatorListener={this.validatorListener}
									fullWidth
								/>
							</Grid>
							<Grid item xs={2}>
								<TextValidator
									name="to3"
									type="date"
									value={data.to3}
									onChange={this.onChange}
									// validators={['required']}
									// errorMessages={['required field']}
									// validatorListener={this.validatorListener}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextValidator
									name="compaddress3"
									value={data.compaddress3}
									onChange={this.onChange}
									// validators={['required']}
									// errorMessages={['required field']}
									// validatorListener={this.validatorListener}
									fullWidth
								/>
							</Grid>
							<Grid item xs={3}>
								<TextValidator
									name="posi3"
									value={data.posi3}
									onChange={this.onChange}
									// validators={['required']}
									// errorMessages={['required field']}
									// validatorListener={this.validatorListener}
								/>
							</Grid>
							<Grid item xs={1}>
								<TextValidator
									name="sal3"
									type="number"
									value={data.sal3}
									onChange={this.onChange}
									// validators={['required']}
									// errorMessages={['required field']}
									// validatorListener={this.validatorListener}
								/>
							</Grid>
						</Grid>
					</div>
				);
				break;
				case 4:
				content = (
					<div>
						<Typography component="h5" variant="h6">
							CHARACTER REFERENCE
						</Typography>
						<span style={{opacity: '.5', fontSize: '13px'}}>(Not Related To You)</span>
						<hr />
						<Grid container spacing={2}>
							<Grid item xs={4}>
								NAME
							</Grid>
							<Grid item xs={4}>
								OCCUPATION & ADDRESS
							</Grid>
							<Grid item xs={4}>
								TEL. NO./CEL.NO.
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<TextValidator
									key={4}
									name="refname1"
									validators={['required']}
									errorMessages={['required field']}
									value={data.refname1}
									onChange={this.onChange}
									validatorListener={this.validatorListener}
									fullWidth
								/>
							</Grid>
							<Grid item xs={4}>
								<TextValidator
									key={4}
									name="refoccuaddress1"
									// label="email 3"
									validators={['required']}
									errorMessages={['required field']}
									value={data.refoccuaddress1}
									onChange={this.onChange}
									validatorListener={this.validatorListener}
									fullWidth
								/>
							</Grid>
							<Grid item xs={4}>
								<TextValidator
									// key={3}
									name="refcelno1"
									type="number"
									validators={['required']}
									errorMessages={['required field']}
									value={data.refcelno1}
									onChange={this.onChange}
									validatorListener={this.validatorListener}
									fullWidth
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<TextValidator
									name="refname2"
									// label="email 3"
									validators={['required']}
									errorMessages={['required field']}
									value={data.refname2}
									onChange={this.onChange}
									validatorListener={this.validatorListener}
									fullWidth
								/>
							</Grid>
							<Grid item xs={4}>
								<TextValidator
									// key={3}
									name="refoccuaddress2"
									// label="email 3"
									validators={['required']}
									errorMessages={['required field']}
									value={data.refoccuaddress2}
									onChange={this.onChange}
									validatorListener={this.validatorListener}
									fullWidth
								/>
							</Grid>
							<Grid item xs={4}>
								<TextValidator
									// key={3}
									name="refcelno2"
									type="number"
									validators={['required']}
									errorMessages={['required field']}
									value={data.refcelno2}
									onChange={this.onChange}
									validatorListener={this.validatorListener}
									fullWidth
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<TextValidator
									name="refname3"
									// label="email 3"
									validators={['required']}
									errorMessages={['required field']}
									value={data.refname3}
									onChange={this.onChange}
									validatorListener={this.validatorListener}
									fullWidth
								/>
							</Grid>
							<Grid item xs={4}>
								<TextValidator
									// key={3}
									name="refoccuaddress3"
									// label="email 3"
									validators={['required']}
									errorMessages={['required field']}
									value={data.refoccuaddress3}
									onChange={this.onChange}
									validatorListener={this.validatorListener}
									fullWidth
								/>
							</Grid>
							<Grid item xs={4}>
								<TextValidator
									// key={3}
									name="refcelno3"
									type="number"
									validators={['required']}
									errorMessages={['required field']}
									value={data.refcelno3}
									onChange={this.onChange}
									validatorListener={this.validatorListener}
									fullWidth
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<TextValidator
									name="sssno"
									label="SSS No."
									validators={['required']}
									errorMessages={['required field']}
									value={data.sssno}
									onChange={this.onChange}
									validatorListener={this.validatorListener}
									inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
									fullWidth
								/>
							</Grid>
							<Grid item xs={4}>
								<TextValidator
									name="philhealthno"
									label="PHILHEALTH NO."
									validators={['required']}
									errorMessages={['required field']}
									value={data.philhealthno}
									onChange={this.onChange}
									validatorListener={this.validatorListener}
									inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
									fullWidth
								/>
							</Grid>
							<Grid item xs={4}>
								<TextValidator
									name="tinno"
									label="TIN No."
									validators={['required']}
									errorMessages={['required field']}
									value={data.tinno}
									onChange={this.onChange}
									validatorListener={this.validatorListener}
									inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
									fullWidth
								/>
							</Grid>
							<Grid item xs={4}>
								<TextValidator
									name="pagibigno"
									label="PAG-IBIG No."
									validators={['required']}
									errorMessages={['required field']}
									value={data.pagibigno}
									onChange={this.onChange}
									validatorListener={this.validatorListener}
									inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
									fullWidth
								/>
							</Grid>
						</Grid><br />
						<Grid container spacing={4}>
							<Grid item xs={12}>Clicking submit means you hereby certify that the facts contained in this information sheet are true and complet and any false data given could be ground for termination.</Grid>
						</Grid>
					</div>
				);
				break;
			default: 
			content = (<div></div>);
		}
		return content;
	}

	onDrop = (picture) => {
        this.setState({
            pictures: this.state.pictures.concat(picture),
        });
		document.getElementsByClassName("uploadPicture")[0].setAttribute("id", "uploadPhoto");
		localStorage.setItem('photo',document.getElementById('uploadPicture').src);
    }

  render() {
	// photo,
    const { currentUser, step, disabled, submitted,  logUser } = this.state;
    return (
      <div className="container"  id="createApplicContainer">
        {(this.state.userReady) ?
        <div className="page-content">
		<Paper style={{padding: '10px', marginBottom: '20px'}}>
			<Typography component="h1" variant="h5" style={{textAlign:'center',fontSize:'20px'}}>
				EMPLOYEE INFORMATION SHEET 
			</Typography>

			<Typography component="h1" variant="h5" style={{textAlign:'center', 'opacity':'.5', fontSize: '15px'}}>
				Please complete information to take exam
			</Typography>
			<div style={{textAlign: 'center'}}><br />
				<div>
					<div className="fileContainer">
						<div className="uploadPictureContainer">
							<img src={`${(logUser.photo === '' || logUser.photo === 'null')?profpic:logUser.photo }`} className="uploadPicture" alt="preview" />
						</div>
						<ImageUploader
							withIcon={false}
							buttonText='Choose Photo'
							onChange={this.onDrop}
							imgExtension={['.jpg', '.gif', '.png', '.gif']}
							maxFileSize={5242880}
							withPreview={true}
							singleImage={true}
							style={{marginTop:'-60px'}}
						/>
						{/* </div> */}
					</div>
					<table style={{textAlign: 'center',width: '25%',marginLeft: '35%'}}>
						<thead>
						</thead>
						<tbody>
							<tr>
								<td style={{width: '200px'}}>
									<span style={{fontSize: '17px', color: 'darkblue', textTransform: 'uppercase'}}>{currentUser.first_name} {currentUser.last_name}</span> <br/>
									<span style={{opacity: '.5',fontSize: '12px'}}>{currentUser.email}</span> <br/>
									<span style={{opacity: '.5',fontSize: '12px'}}>{currentUser.phone}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<ValidatorForm
				ref={(r) => { this.form = r; }}
				onSubmit={this.handleSubmit}
				instantValidate
			>
				{this.renderStep()}
				{this.state.successful && (
					<Alert severity="success" style={{ marginTop: '15px' }}>
						<AlertTitle>Success</AlertTitle>
						Profile completed successfully.
					</Alert>
            	)}
				<Button
					onClick={this.prevStep}
					style={{ marginRight: '16px', marginTop: '16px' }}
					disabled={step === 1}
				>
					previous
				</Button>
				<Button
					color="primary"
					variant="contained"
					style={{ marginRight: '16px', marginTop: '16px' }}
					onClick={step < 4 ? this.nextStep : this.submit}
					disabled={disabled || submitted}
				>
					{
						(submitted && 'Saving data...')
						|| (step < 4 ? 'Next' : 'Submit')
					}
				</Button>
			</ValidatorForm>
		</Paper>

      </div>: null}

      </div>
    );
  }
}
