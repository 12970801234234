import React, { Component } from "react";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import {encode as base64_encode} from 'base-64';
import Bg from "../image/whats-new.jpg";
import Logo from "../assets/image/cgsilogo2.png";
import { send } from 'emailjs-com';

import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';

import './Component.css';

import AuthService from "../services/auth.service";

export default class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
        formData: {
            username: '',
            firstname: '',
            lastname: '',
            middlename: '',
            birthday:'',
            email: '',
            password: '',
            repeatPassword: '',
			qualifications: [],
			job_level: [],
			mobile: '',
			educ_attainment: '',
			provinces:'',
			municipality:'',
			barangay:''
        },
		toSend: {
			from_name: '',
			to_name: '',
			message: '',
			reply_to: '',
		},
        container: {
            backgroundImage: `url(${Bg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            position: 'absolute',
            width: '100vw',
            // height: '100%',
            left: '0px',
            top: '0px'
        },
        submitted: false,
        loading: false,
        message: "",
		qualifOptions: [
			"Accounting / Finance",
			"Admin / Office / Clerical",
			"Arts / Media / Design",
			"Engineering / Architecture",
			"Food / Restaurant",
			"HR / Recruitment / Training",
			"Health / Medical / Science",
			"Hotel / Spa / Salon",
			"IT / Computers",
			"Logistics / Warehousing",
			"Production / Manufacturing",
			"Purchasing / Buyer",
			"Sales / Marketing / Retail",
			"Skilled Work / Technical"
		],
		job_level_options: [
			"No Experience",
			"1 Year or less",
			"High school graduate are welcome",
			"2 to 4 years",
			"5+ Years",
			"Manegerial/Supervisory"
		],
		provinces:[],
		municipality:[],
		barangay:[]
    }
  }
  
	componentDidMount() {
		AuthService.getAllAddress().then(
			response => {
				// console.log(response);
				const provinces = [];
				for (var key in response) {
					// skip loop if the property is from prototype
					if (!response.hasOwnProperty(key)) continue;

					var obj = response[key];
					for (var prop in obj) {
						// skip loop if the property is from prototype
						if (!obj.hasOwnProperty(prop)) continue;

						// your code
						// console.log(obj[prop]);
						var prov = obj[prop];
						for ( var provi in prov) {
							if(isNaN(provi)){
								// console.log(provi)
								provinces.push(provi);
							}
						}
					}
				}
				// console.log(provinces);
				this.setState({provinces: provinces.sort()});
			},
			error => {
				console.log(error);
			}
		);

		// custom rule will have name 'isPasswordMatch'
		ValidatorForm.addValidationRule('isUsername', (value) => {
			if (this.hasWhiteSpace(value)) {
				return false;
			}
			return true;
		});

		// custom rule will have name 'isPasswordMatch'
		ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
		if (value !== this.state.formData.password) {
			return false;
		}
		return true;
		});

		// custom rule will have name 'validate Age'
		ValidatorForm.addValidationRule('isAge', (value) => {
			if (this.getAge(value) < 18) {
				return false;
			}
			return true;
		});

		// custom rule will validate mobile
		ValidatorForm.addValidationRule('isMobile', (value) => {
			let regex = /((63)|((\+|0))){1}[0-9]{10}\b/,
			result = regex.test(value);
			console.log(value, result);
			return result;
		});

	}

  componentWillUnmount() {
      // remove rule when it is not needed
      ValidatorForm.removeValidationRule('isPasswordMatch');
	  ValidatorForm.removeValidationRule('isAge');
  }

	hasWhiteSpace(s) {
		return (/\s/).test(s);
	}

	getAge(dateString) {
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}

	handleChangeProvinces = (event) => {
		const { formData } = this.state;
		// console.log(event.target.value);
		formData[event.target.name] = event.target.value;
		this.setState({ formData });

		AuthService.getAllAddress().then(
			response => {
				// console.log(response);
				const municipalty = [];
				for (var key in response) {
					// skip loop if the property is from prototype
					if (!response.hasOwnProperty(key)) continue;

					var obj = response[key];
					for (var prop in obj) {
						// skip loop if the property is from prototype
						if (!obj.hasOwnProperty(prop)) continue;

						// your code
						// console.log(obj[prop]);
						var prov = obj[prop];
						// console.log(obj[prop])
						for ( var provi in prov) {
							if(isNaN(provi)){
								// console.log(provi)
								if (provi === event.target.value ) {
									let municipality = obj[prop][event.target.value]['municipality_list'];
									

									for (var munici in municipality) {
										// console.log(munici);
										municipalty.push(munici);
									}

								}
							}
						}
					}
				}
				// console.log(municipalty);
				this.setState({municipality: municipalty.sort()});
			},
			error => {
				console.log(error);
			}
		);


	}

	handleChangeBarangay = (event) => {
		const { formData } = this.state;
		console.log(formData.provinces);
		// console.log(formData.municipality);
		console.log(event.target.value);
		formData[event.target.name] = event.target.value;
		this.setState({ formData });

		AuthService.getAllAddress().then(
			response => {
				// console.log(response);
				const Brgy = [];
				for (var key in response) {
					// skip loop if the property is from prototype
					if (!response.hasOwnProperty(key)) continue;

					var obj = response[key];
					for (var prop in obj) {
						// skip loop if the property is from prototype
						if (!obj.hasOwnProperty(prop)) continue;

						// your code
						// console.log(obj[prop]);
						var prov = obj[prop];
						// console.log(obj[prop])
						for ( var provi in prov) {
							if(isNaN(provi)){
								// console.log(provi)
								if (provi === formData.provinces ) {
									let barangays = obj[prop][formData.provinces]['municipality_list'][event.target.value]['barangay_list'];
									for (var i=0; i< barangays.length; i++) {
										Brgy.push(barangays[i]);
									}
								}
							}
						}
					}
				}
				this.setState({barangay: Brgy.sort()});
			},
			error => {
				console.log(error);
			}
		);
	}

	handleChangeCompleteAddress = (event) => {
		const { formData } = this.state;
		formData[event.target.name] = event.target.value;
		this.setState({ formData });
	}

	handleChange = (event) => {
		const { formData } = this.state;
		formData[event.target.name] = event.target.value;
		// console.log(formData);
		this.setState({ formData });
	}

  handleSubmit = () => {
      this.setState({
        resMessage: "",
        loading: true
      });
      this.setState({ submitted: true }, () => {
			let timeStamp = Math.floor((new Date()).getTime() / 1000);
			let encodeName = base64_encode(this.state.formData.firstname+this.state.formData.lastname+timeStamp);
			let nameId = encodeName.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
			let presentAddress = 'BRGY. '+this.state.formData.barangay +', '+this.state.formData.municipality+', '+this.state.formData.provinces+ ' PROVINCE';
			AuthService.register(
				this.state.formData.username,
				this.state.formData.email,
				this.state.formData.firstname,
				this.state.formData.lastname,
				this.state.formData.password,
				nameId,
				this.state.formData.middlename,
				this.state.formData.birthday,
				this.state.formData.mobile,
				JSON.stringify(this.state.formData.qualifications),
				this.state.formData.educ_attainment,
				JSON.stringify(this.state.formData.job_level),
				presentAddress
			).then(
				response => {
					const toSend =  {
						to_name: this.state.formData.firstname +' '+this.state.formData.lastname,
						to_email: this.state.formData.email
					};
					send(
						'service_37v4ai9',
						'template_spf43vh',
						toSend,
						'user_jXEHEjDvf6F3v7yYF4fMj',
					)
					.then((response) => {
						this.setState({
							message: "Thank you for registering CGSI Job Portal. Your account was successfully created.",
							successful: true
						});
					})
					.catch((err) => {
						console.log('FAILED...', err);
					});
					if(this.state.message) {
						setTimeout(() => 
						window.location.href = "/login", 5000);
					}
				},
				error => {

				const resMessage = error.response.data;

				this.setState({
				successful: false,
				message: resMessage,
				loading: false
				});
				}
			);
			setTimeout(() => 
			this.setState({ submitted: false }), 5000);
      });
  }

  render() {
    const { formData, submitted } = this.state;
    return (
      <div className="col-md-12" style={this.state.container} >
        <div className="card" id="regformCard">
			<div id="regformtitle">
				<Grid container spacing={2}>
					<Grid item xs={5}> 
						<span style={{display:'block'}}><img src={Logo} alt="website logo" style={{"width": "300px"}}/></span>
					</Grid>
					<Grid item xs={7} style={{borderLeft: '5px solid #3046e7'}}> 
						<span style={{fontSize: '18px',fontWeight: 'bold', color: '#133bcb'}}>SIGNUP to create CGSI applicant account</span>
						<span style={{fontSize: '14px',display:'block'}}>Please provide real information, we will based here when we contact you.</span>
						<Grid item style={{fontSize: '14px',display:'block'}}>
						{"Registered Already, "}
						<Link href="/login" variant="body2">
							{"Click here to Login"}
						</Link>
						</Grid>
					</Grid>
				</Grid>
			</div>
			<div id="regform_mobile_title">
				<Grid container spacing={2}>
					<Grid item xs={12}> 
						<span style={{display:'block'}}><img src={Logo} alt="website logo" style={{"width": "300px"}}/></span>
						<span style={{fontSize: '18px',fontWeight: 'bold', color: '#133bcb'}}>SIGNUP to create CGSI applicant account</span>
						<span style={{fontSize: '14px',display:'block'}}>Please provide real information, we will based here when we contact you.</span>
						<Grid item style={{fontSize: '14px',display:'block'}}>
						{"Registered Already, "}
						<Link href="/login" variant="body2">
							{"Click here to Login"}
						</Link>
						</Grid>
					</Grid>
				</Grid>
			</div>
          {!this.state.successful && ( <ValidatorForm
                  ref="form"
                  onSubmit={this.handleSubmit}
            >
				<div id="regform">

				<div style={{paddingTop: '25px', marginBottom: '10px'}}>
					<span style={{display: 'block',fontSize: '18px',fontWeight: 'bold',color: '#5959f5'}}>Personal Information</span>
				</div>

              <Grid container spacing={2} style={{marginTop: '-20px'}}>
                <Grid item xs={4}> 
                  <TextValidator
                      label="Firstname*"
                      onChange={this.handleChange}
                      name="firstname"
                      value={formData.firstname}
                      inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
                      validators={['required']}
                      errorMessages={['Firstname is required']}
					  autoFocus
					  fullWidth
                  />
                </Grid>
                <Grid item xs={4}> 
                  <TextValidator
                      label="Middlename*"
                      onChange={this.handleChange}
                      name="middlename"
                      value={formData.middlename}
                      inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
                      validators={['required']}
                      errorMessages={['Middlename is required']}
                      fullWidth
                  />
                </Grid>
				<Grid item xs={4}>
                  <TextValidator
                      label="Lastname*"
                      onChange={this.handleChange}
                      name="lastname"
                      value={formData.lastname}
                      inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
                      validators={['required']}
                      errorMessages={['Lastname is required']}
					  fullWidth
                  />
                </Grid>
              </Grid>

				<Grid container spacing={2} style={{marginTop: '-20px'}}>
					<Grid item xs={6}> 
						<label style={{fontSize: '10px',opacity: '.5'}}> Birthday* </label>
						<TextValidator
							// label="Birthday"
							onChange={this.handleChange}
							name="birthday"
							type="date"
							value={formData.birthday}
							inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
							validators={['isAge','required']}
							//   placeholder="none"
							errorMessages={['Required age must be 18 years old.','Birthday is required']}
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}> 
						<TextValidator
							label="Mobile/Contact Number*"
							name="mobile"
							onChange={this.handleChange}
							type="number"
							value={formData.mobile}
							InputLabelProps={{ shrink: true }}
							inputProps={{style: { marginBottom: '5px', marginTop:'15px' }}}
							validators={['required','isMobile']}
							errorMessages={['Mobile is required','Mobile number is invalid - format (63+10 digit number or 0+10 digit number )']}
							fullWidth
						/>
					</Grid>
              </Grid>
			  <label style={{fontSize: '15px',opacity: '.5', marginTop:'10px'}}>Present Address* </label>
			  <Grid container spacing={2}>
				<Grid item xs={4} style={{marginTop: '10px'}}> 
						<TextValidator
							name="provinces"
							label="Province*"
							select
							value={formData.provinces}
							InputLabelProps={{ shrink: true }}
							validators={['required']}
							errorMessages={['Province is required.']}
							onChange={(e) => {this.handleChangeProvinces(e)}}
							validatorListener={this.validatorListener}
							fullWidth
							style={{marginTop: '7px'}}
							// renderalue={selected => selected}
							>
							{this.state.provinces.map(name => (
								<MenuItem key={name} value={name}>{name}</MenuItem>
							))}
						</TextValidator>
				</Grid>
				<Grid item xs={4} style={{marginTop: '10px'}}> 
						<TextValidator
							name="municipality"
							label="Municipality*"
							select
							value={formData.municipality}
							InputLabelProps={{ shrink: true }}
							validators={['required']}
							errorMessages={['Municipality is required.']}
							onChange={(e) => {this.handleChangeBarangay(e)}}
							validatorListener={this.validatorListener}
							fullWidth
							style={{marginTop: '7px'}}
							>
							{this.state.municipality.map(name => (
								<MenuItem key={name} value={name}>{name}</MenuItem>
							))}
						</TextValidator>
				</Grid>
				<Grid item xs={4} style={{marginTop: '10px'}}> 
						<TextValidator
							name="barangay"
							label="Barangay*"
							select
							value={formData.barangay}
							InputLabelProps={{ shrink: true }}
							validators={['required']}
							errorMessages={['Barangay is required.']}
							onChange={(e) => {this.handleChangeCompleteAddress(e)}}
							validatorListener={this.validatorListener}
							fullWidth
							style={{marginTop: '7px'}}
							>
							{this.state.barangay.map(name => (
								<MenuItem key={name} value={name}>{name}</MenuItem>
							))}
						</TextValidator>
				</Grid>
			</Grid>

			<div style={{paddingTop: '25px', marginBottom: '10px'}}>
					<span style={{display: 'block',fontSize: '18px',fontWeight: 'bold',color: '#5959f5'}}>Educational Information</span>
			</div>

			  <Grid container spacing={2}>
					<Grid item xs={12}> 
						<TextValidator
							name="educ_attainment"
							label="Highest Educational Attainment*"
							select
							value={formData.educ_attainment}
							InputLabelProps={{ shrink: true }}
							validators={['required']}
							errorMessages={['Education Attain is required.']}
							onChange={this.handleChange}
							validatorListener={this.validatorListener}
							fullWidth
							style={{marginTop: '7px'}}
							>
							<MenuItem value={'Highschool_Level'} selected>High School Level</MenuItem>
							<MenuItem value={'Highschool_Grad'}>High School Graduate</MenuItem>
							<MenuItem value={'College_Level'} >College Level</MenuItem>
							<MenuItem value={'College_Grad'}>College Graduate</MenuItem>
						</TextValidator>
					</Grid>
			  </Grid>

			<div style={{paddingTop: '25px', marginBottom: '10px'}}>
					<span style={{display: 'block',fontSize: '18px',fontWeight: 'bold',color: '#5959f5'}}>Account Information</span>
			</div>

			<Grid container spacing={2}>
                  <Grid item xs={6}> 
                    <TextValidator
                        label="Username*"
                        onChange={this.handleChange}
                        name="username"
                        value={formData.username}
                        inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
                        validators={['required','isUsername']}
                        errorMessages={['Username is required','Username must not contain space']}
						fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextValidator
                        label="Email*"
                        onChange={this.handleChange}
                        name="email"
                        value={formData.email}
                        inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
                        validators={['required', 'isEmail']}
                        errorMessages={['Email is required', 'Email is not valid']}
                        fullWidth
                    />
                  </Grid>
              </Grid>
			<Grid container spacing={2}>
			<Grid item xs={6}> 
				<TextValidator
					label="Password*"
					onChange={this.handleChange}
					name="password"
					type="password"
					value={formData.password}
					inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
					validators={['required']}
					errorMessages={['Password is required']}
					fullWidth
				/>
			</Grid>
			<Grid item xs={6}>
				<TextValidator
					label="Repeat Password*"
					onChange={this.handleChange}
					name="repeatPassword"
					type="password"
					value={formData.repeatPassword}
					inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
					validators={['required','isPasswordMatch']}
					errorMessages={['Password is required', 'Password not matched.']}
					fullWidth
				/>
			</Grid>
			</Grid>

			<div style={{paddingTop: '25px', marginBottom: '10px'}}>
					<span style={{display: 'block',fontSize: '18px',fontWeight: 'bold',color: '#5959f5'}}>Work Information</span>
			</div>


			<Grid container spacing={2}>
				<Grid item xs={12} > 
						<label style={{fontSize: '12px',opacity: '.5'}}> Job Category* </label>
						<Select
							label="Qualification*"
							labelId="qualification_select"
							id="qualification"
							multiple
							value={formData.qualifications}
							name="qualifications"
							onChange={this.handleChange}
							input={<Input />}
							renderValue={selected => selected.join(", ")}
							fullWidth
						>
						{this.state.qualifOptions.map(name => (
							<MenuItem key={name} value={name}>
								<Checkbox checked={formData.qualifications.indexOf(name) > -1} />
							<ListItemText primary={name} />
							</MenuItem>
						))}
						</Select>
					</Grid> 
					<Grid item xs={12}> 
						<label style={{fontSize: '12px',opacity: '.5'}}> Job Level* </label>
						<Select
							label="Job Level*"
							labelId="job_level_select"
							id="job_level"
							name="job_level"
							multiple
							value={formData.job_level}
							onChange={this.handleChange}
							input={<Input />}
							renderValue={selected => selected.join(", ")}
							fullWidth
						>
						{this.state.job_level_options.map(name => (
							<MenuItem key={name} value={name}>
								<Checkbox checked={formData.job_level.indexOf(name) > -1} />
							<ListItemText primary={name} />
							</MenuItem>
						))}
						</Select>
					</Grid>
			</Grid>



			  </div>

			  <div id="regform_mobile">
                <Grid container spacing={2}>
                  <Grid item xs={12}> 
                    <TextValidator
                        label="Username*"
                        onChange={this.handleChange}
                        name="username"
                        value={formData.username}
                        inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
                        validators={['required','isUsername']}
                        errorMessages={['Username is required','Username must not contain space']}
                        autoFocus
						fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                        label="Email*"
                        onChange={this.handleChange}
                        name="email"
                        value={formData.email}
                        inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
                        validators={['required', 'isEmail']}
                        errorMessages={['Email is required', 'Email is not valid']}
                        fullWidth
                    />
                  </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}> 
                  <TextValidator
                      label="Firstname*"
                      onChange={this.handleChange}
                      name="firstname"
                      value={formData.firstname}
                      inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
                      validators={['required']}
                      errorMessages={['Firstname is required']}
					  fullWidth
					  autoFocus
                  />
                </Grid>
                <Grid item xs={12}> 
                  <TextValidator
                      label="Middlename*"
                      onChange={this.handleChange}
                      name="middlename"
                      value={formData.middlename}
                      inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
                      validators={['required']}
                      errorMessages={['Middlename is required']}
                      fullWidth
                  />
                </Grid>
				<Grid item xs={12}>
                  <TextValidator
                      label="Lastname*"
                      onChange={this.handleChange}
                      name="lastname"
                      value={formData.lastname}
                      inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
                      validators={['required']}
                      errorMessages={['Lastname is required']}
					  fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}> 
                  <label style={{fontSize: '10px',opacity: '.5'}}> Birthday* </label>
                  <TextValidator
                      // label="Birthday"
                      onChange={this.handleChange}
                      name="birthday"
                      type="date"
                      value={formData.birthday}
                      inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
                      validators={['isAge','required']}
                    //   placeholder="none"
                      errorMessages={['Required age must be 18 years old.','Birthday is required']}
                      fullWidth
                  />
                </Grid>
				<Grid item xs={12}> 
                  <TextValidator
                      label="Mobile/Contact Number*"
					  name="mobile"
                      onChange={this.handleChange}
                      type="number"
                      value={formData.mobile}
					  InputLabelProps={{ shrink: true }}
                      inputProps={{style: { marginBottom: '5px', marginTop:'15px' }}}
                      validators={['required']}
					  placeholder="900000000"
                      errorMessages={['Mobile is required']}
                      fullWidth
                  />
                </Grid>
				<Grid item xs={12}> 
					<label style={{fontSize: '12px',opacity: '.5'}}> Job Category* </label>
					<Select
						label="Qualification*"
						labelId="qualification_select"
						id="qualification"
						multiple
						value={formData.qualifications}
						name="qualifications"
						onChange={this.handleChange}
						input={<Input />}
						renderValue={selected => selected.join(", ")}
						fullWidth
					>
					{this.state.qualifOptions.map(name => (
						<MenuItem key={name} value={name}>
							<Checkbox checked={formData.qualifications.indexOf(name) > -1} />
						<ListItemText primary={name} />
						</MenuItem>
					))}
					</Select>
				</Grid> 
              </Grid>
			  <Grid container spacing={2}>
					<Grid item xs={12}> 
						<TextValidator
							name="educ_attainment"
							label="Educational Attainment*"
							select
							value={formData.educ_attainment}
							InputLabelProps={{ shrink: true }}
							validators={['required']}
							errorMessages={['Education Attain is required.']}
							onChange={this.handleChange}
							validatorListener={this.validatorListener}
							fullWidth
							style={{marginTop: '7px'}}
							>
							<MenuItem value={'Highschool_Grad'} selected>High School Graduate</MenuItem>
							<MenuItem value={'College_Level'} >College Level</MenuItem>
							<MenuItem value={'College_Grad'}>College Graduate</MenuItem>
						</TextValidator>
					</Grid>

					<Grid item xs={12}> 
						{/* <TextValidator
							name="job_level"
							label="Job Level*"
							select
							value={formData.job_level}
							InputLabelProps={{ shrink: true }}
							validators={['required']}
							errorMessages={['Job level is required.']}
							onChange={this.handleChange}
							validatorListener={this.validatorListener}
							fullWidth
							style={{marginTop: '7px'}}
						>
						<MenuItem value={'noexp'} selected>No Experience</MenuItem>
						<MenuItem value={'1year'} >One year or less</MenuItem>
						<MenuItem value={'2years'}>2 to 4 years</MenuItem>
						<MenuItem value={'5years'}>5+ Years  </MenuItem>
						<MenuItem value={'5manegerial'}>Manegerial/Supervisory </MenuItem>
						</TextValidator> */}
						<label style={{fontSize: '12px',opacity: '.5'}}> Job Level* </label>
						<Select
							label="Job Level*"
							labelId="job_level_select"
							id="job_level"
							name="job_level"
							multiple
							value={formData.job_level}
							onChange={this.handleChange}
							input={<Input />}
							renderValue={selected => selected.join(", ")}
							fullWidth
						>
							{this.state.job_level_options.map(name => (
								<MenuItem key={name} value={name}>
									<Checkbox checked={formData.job_level.indexOf(name) > -1} />
								<ListItemText primary={name} />
								</MenuItem>
							))}
						</Select>
					</Grid>
			  </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}> 
                  <TextValidator
                      label="Password*"
                      onChange={this.handleChange}
                      name="password"
                      type="password"
                      value={formData.password}
                      inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
                      validators={['required']}
                      errorMessages={['Password is required']}
					  fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                        label="Repeat Password*"
                        onChange={this.handleChange}
                        name="repeatPassword"
                        type="password"
                        value={formData.repeatPassword}
                        inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
                        validators={['required','isPasswordMatch']}
                        errorMessages={['Password is required', 'Password not matched.']}
                        fullWidth
                    />
                </Grid>
              </Grid>
			  </div>

                <div className="form-group" style={{marginTop: '-10px'}}>
                  <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={submitted}
					  className="registerAccountBtn"
                      style={{ 'width': '100%', marginTop: '30px'}}>

                      {this.state.loading && (
                        <div><span className="spinner-border spinner-border-sm"></span> &nbsp;</div>
                      )}

                      {
                          (submitted && ' Creating Account..')
                          || (!submitted && 'Signup ')
                      }
                  </Button>
                  <br />
                  {this.state.message && (
                    <div className="form-group" style={{marginTop: '10px'}}>
                      <div className="alert alert-danger" role="alert">
                        {this.state.message}
                      </div>
                    </div>
                  )}
                  {/* <Grid container>
                    <Grid item xs>
                    </Grid>
                    <Grid item>
                      {"Already registered? "}
                      <Link href="/login" variant="body2">
                        {"Login"}
                      </Link>
                    </Grid>
                  </Grid> */}
                </div>
            </ValidatorForm>)}
            {this.state.successful && (
              <div className="form-group" style={{paddingTop: '30px'}}>
                <div
                  className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}
