import React, { Component } from "react";
import JobsService from "../../services/job.service";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { List } from 'react-content-loader';
import ReactPaginate from 'react-paginate';

import "../Component.css";

import * as moment from 'moment';
import { Link } from "react-router-dom";

export default class Cgsijobs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			redirect: null,
			userReady: false,
			currentUser: null,
			jobs: [],
			filter: "",
			box:'',
			offset: 0,
			perPage: 10,
			currentPage: 0,
			pageCount: 0, 
			search: false,
			Category:[],
			job_id: 0, 
			jobActive: false,
			jobName: ''
		};
	}

    handleChange = (event) => {
		this.setState({ filter: event.target.value , search: event.target.value? true:false});
		console.log(event.target.value);
    }

	componentDidMount() {
			this.setState({loading: true});
			let id = 0;
			JobsService.getJobCategory().then(
				response => {
					console.log(response);
					if (response.status) {
						const jobCategory = response.data;
						this.setState({Category: jobCategory, loading: false});
					}
				},
				error => {
					this.setState({jobsList: false});
				}
			);
			this.setState({ userReady: true, job_id: id })
			this.receivedData(id);
	}

	receivedData = (id) => {
		JobsService.getApplicantJobs(id).then(
			response => {
				if (response.status) {
					const jobs = response.data;
					this.setState({jobs, loading: false, search: false});
					const slice = jobs.slice(this.state.offset, this.state.offset + this.state.perPage);
					const postData = slice.map(job => <React.Fragment key={job.id}>
						<Link to={`/jobdescription/${job.id}`} style={{textDecoration: 'none'}}>
							<Paper className={(job.availability ==='Urgent')? 'jobList Urgent': 'jobList n_Urgent'} elevation={3}>
								<Grid item xs={12} style={{padding: "10px"}} className={(job.applied)? 'box': 'job'}>
									{
										(job.applied)?
										<div className="ribbon ribbon-top-right"><span>Applied</span></div>:''
									}
									<Grid container spacing={2} key={job.id}>
										<Grid item xs={3} key={job.id}>
											<span style={{
												fontSize: '20px',
												fontWeight: '600',
												color:'dodgerblue'
											}}>{job.job_position}</span>
											<span style={{ display:'block',marginBottom: '-27px', fontSize: '13px'}}>Vacancy: {job.vacancy} {job.availability}  {job.employment_type} <br /><b>{(job.job_category === 0)?'': job.job_category}</b></span>
											<br />
											{this.required(job.job_required)}
										</Grid>
										<Grid item xs={3}>
											<i className="fas fa-check-circle" style={{color:'green'}}></i>&nbsp;
											<span style={{fontWeight: 'bold',fontSize: '17px'}}>{job.company}</span>
											<span style={{fontSize: '12px',  display: 'block',fontStyle:'oblique'}}>Office: {job.city}<br/>{job.street}</span>
										</Grid>
										<Grid item xs={3}>
											<i className='fas fa-map-marker-alt' style={{color: 'olive'}}></i>
											<span> {(job.assgnmnt_area === '')?'Mandaue':job.assgnmnt_area}</span>
											 
										</Grid>
										<Grid item xs={3}>
											<i className="fas fa-clock" style={{color:'royalblue'}}></i> 
											&nbsp;{moment(job.created).format('MMMM DD, YYYY')}
											<span style={{ display: 'block',fontSize: '0.66em'}}>{moment(job.created).startOf('hour').fromNow()}</span>
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						</Link>
					</React.Fragment>)
					console.log(postData);
					this.setState({pageCount: Math.ceil(jobs.length / this.state.perPage), postData});
				}
			},
			error => {
				console.log(error);
				const postData = (<React.Fragment key={'1'}>
						<Paper elevation={3}>
							<Grid item xs={12} style={{padding: "10px",textAlign: 'center', marginTop: '12px', fontWeight: 'bold'}}>
								No list for this job for now.
							</Grid>
						</Paper>
				</React.Fragment>)
				this.setState({pageCount: Math.ceil(0 / 0), postData});
			}
		);
	}

	// filter the required options
	required(req) {
		const qualif = JSON.parse(req);
		var reqdesc = [];
		qualif.forEach(element => {
			var withEx = 'Year';
			if(element.match(new RegExp(`${withEx}`, 'gi'))){
				var sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(224, 57, 151)'
					}}></i>
			}

			if(element.match(new RegExp(`No`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(242, 113, 28)'
					}}></i>
			}

			if(element.match(new RegExp(`High`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(251, 189, 8)'
					}}></i>
			}

			if(element.match(new RegExp(`years`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(33, 186, 69)'
					}}></i>
			}

			if(element.match(new RegExp(`5+`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(33, 133, 208)'
					}}></i>
			}

			if(element.match(new RegExp(`Manegerial/Supervisory`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(33, 133, 208)'
					}}></i>
			}

			if(element.match(new RegExp(`College Level`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(33, 133, 210)'
					}}></i>
			}

			if(element.match(new RegExp(`College Graduate`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(35, 140, 210)'
					}}></i>
			}


			reqdesc.push(<div key={element}><span style={{fontSize: '13px'}}>
							{sprite} &nbsp;
							{element}
						</span><br /></div>)
		});
		return reqdesc;
	}

	handlePageClick = (e) => {
		const selectedPage = e.selected;
		localStorage.removeItem('selectedPage');
		const offset = selectedPage * this.state.perPage;
		this.setState({
			currentPage: selectedPage,
			offset: offset
		}, () => {
			this.receivedData(localStorage.getItem('jobId'));
		});
  
	};

	changeJobCategory = (catname, key) => {
		localStorage.setItem('jobId', catname);
		this.setState({jobActive: key, jobName: catname});
		this.receivedData(catname);
	}

	hideCat = () => {
		this.setState({hideCat: true});
	}

	render() {
		const { filter, jobs } = this.state;
		const lowercasedFilter = filter.toString().toLowerCase();
		const filteredData = jobs.filter(item => {
			return Object.keys(item).some(key =>
				item[key].toString().toLowerCase().includes(lowercasedFilter)
			);
		});
		return (
			// container
			<div className="" id="createApplicContainer" style={{padding: '5px'}}>
			{
				(this.state.userReady) ?
					<div style={{paddingTop:'10px'}}>
							<Typography component="h1" variant="h5" style={{fontWeight: 'bold', fontSize: '30px'}}>
							{this.state.jobName} Jobs 
							</Typography>
							<br />
							<TextField 
								label="Search job"
								onChange={this.handleChange}
								name="search_job"
								value={filter}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								fullWidth
								style={{marginTop:'-35px'}}
							/>
							{
								this.state.loading? 
								<div><br/><List /><br/></div>
							:<div>
							{
								this.state.search &&
							<div>
								{/* container */}
								<div className="">
										<div className="row">
											<div className="col"><br/>
												<Paper style={{left: '2%',top: '15%',fontWeight:'400',background: '#fff',padding: '10px'}} >
													<label style={{left: '2%',fontWeight:'900'}}>Job Category</label>
													<label  className={(this.state.jobActive === 0)? 'jobActive' : 'jobInactive'} data-id={'0'} onClick={() => this.changeJobCategory('',0)}>All</label>
													{this.state.Category.map((category,key) => {
														let maxVal = 0xFFFFFF;
														let randomNumber = Math.random() * maxVal; 
														randomNumber = Math.floor(randomNumber);
														const randColor = randomNumber.toString(16);
														return (
															<div key={key} >
																<label  className={(this.state.jobActive === key+1)? 'jobActive' : 'jobInactive'} data-id={category.category_name} onClick={() => this.changeJobCategory(category.category_name, key+1)}>{category.category_name}<span className='countJobs' style={{color: `#${randColor}`, fontWeight: 'bold' }}>{category.cjobs}</span></label>
															</div>
														)
													})}
												</Paper>
											</div>
											<div className="col-10">
											{filteredData.map((job,key) => {
												return(
													<Link to={`/jobInfo/${job.id}`} key={key} style={{textDecoration: 'none'}}>
														<Paper className={(job.availability ==='Urgent')? 'jobList Urgent': 'jobList n_Urgent'}  key={job.id} style={{width: "100%", marginTop: '15px'}} elevation={3}>
															<Grid item xs={12} style={{padding: "10px"}} key={job.id} className={(job.applied)? 'box': 'job'}>
																{
																	(job.applied)?
																	<div className="ribbon ribbon-top-right"><span>Applied</span></div>:''
																}
																<Grid container spacing={2} key={job.id}>
																	<Grid item xs={3} key={job.id}>
																		<span style={{
																			fontSize: '20px',
																			fontWeight: '600',
																			color:'dodgerblue'
																		}}>{job.job_position}</span>
																		<span style={{ display:'block',marginBottom: '-27px', fontSize: '13px'}}>Vacancy: {job.vacancy} {job.availability}  {job.employment_type}<br /><b>{(job.job_category === 0)?'': job.job_category}</b></span>
																		<br />
																		{this.required(job.job_required)}
																	</Grid>
																	<Grid item xs={3}>
																		<i className="fas fa-check-circle" style={{color:'green'}}></i>&nbsp;
																		<span style={{fontWeight: 'bold',fontSize: '17px'}}>{job.company}</span>
																		<span style={{fontSize: '12px',  display: 'block',fontStyle:'oblique'}}>Office: {job.city}<br/>{job.street}</span>
																	</Grid>
																	<Grid item xs={3}>
																		<i className='fas fa-map-marker-alt' style={{color: 'olive'}}></i>
																		<span> {(job.assgnmnt_area === '')?'Mandaue':job.assgnmnt_area}</span>
																		
																	</Grid>
																	<Grid item xs={3}>
																		<i className="fas fa-clock" style={{color:'royalblue'}}></i> 
																		&nbsp;{moment(job.created).format('MMMM DD, YYYY')}
																		<span style={{ display: 'block',fontSize: '0.66em'}}>{moment(job.created).startOf('hour').fromNow()}</span>
																	</Grid>
																</Grid>
															</Grid>
														</Paper>
													</Link>
												)
											})}
											</div>
										</div>
									</div>
							</div>
							}
							{
								!this.state.search &&
								<div>
									{/* container */}
									<div className="">
										<div className="row">
											<div className="col"><br/>
												<Paper style={{left: '2%',top: '15%',fontWeight:'400',background: '#fff',padding: '10px'}} >
													<label style={{left: '2%',fontWeight:'900'}}>Job Category</label>
													<label  className={(this.state.jobActive === 0)? 'jobActive' : 'jobInactive'} data-id={'0'} onClick={() => this.changeJobCategory('',0)}>All</label>
													{this.state.Category.map((category,key) => {
														let maxVal = 0xFFFFFF;
														let randomNumber = Math.random() * maxVal; 
														randomNumber = Math.floor(randomNumber);
														const randColor = randomNumber.toString(16);
														return (
															<div key={key} >
																<label className={(this.state.jobActive === key+1)? 'jobActive' : 'jobInactive'} data-id={category.category_name} onClick={() => this.changeJobCategory(category.category_name, key+1)}>{category.category_name}<span className='countJobs' style={{color: `#${randColor}`, fontWeight: 'bold' }}>{category.cjobs}</span></label>
															</div>
														)
													})}
												</Paper>
											</div>
											<div className="col-10">
												{this.state.postData}
												<ReactPaginate
													previousLabel={"prev"}
													nextLabel={"next"}
													breakLabel={"..."}
													breakClassName={"break-me"}
													pageCount={this.state.pageCount}
													marginPagesDisplayed={2}
													pageRangeDisplayed={5}
													onPageChange={this.handlePageClick}
													containerClassName={"pagination"}
													subContainerClassName={"pages pagination"}
													activeClassName={"active"}
												/>
											</div>
										</div>
									</div>
								</div>
							}
							</div>
							}
					</div>
				: null
			}
			</div>
		);
	}
}

