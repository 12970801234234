import React, { Component } from "react";
import Typography from '@material-ui/core/Typography';
// import Badge from '@material-ui/core/Badge';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Chip from '@material-ui/core/Chip';
// import { userGrid } from '@mui/x-data-grid-generator';

// import JobService from "../../services/job.service";
import AuthService from "../../services/auth.service";
import '../Component.css';

export default class Applicantlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
        submitted: false,
        loading: true,
        message: "",
		checked: true,
		rows: [],
		jobApplied:[]
    }
  }
  
  componentDidMount() {
		if(localStorage.getItem('openNav') === '0') {
			document.getElementById("createApplicContainer").style.paddingLeft = "0px";
		} else {
			document.getElementById("createApplicContainer").style.paddingLeft = "216px";
		}
		//Get current user
		const user = AuthService.getCurrentUser();
		if (user === null) {
			this.setState({ redirect: "/dashboard" });
		} else {
			AuthService.getAllApplicant().then(
				response => {
					console.log(response+'test');
					try {
						this.setState({ rows:response, loading: false });
					}catch (error) {
						console.log(error);
					}
				},
				error => {
					console.log(error);
				}
			);
	  	}
  }	

  render() {
    const { rows, loading } = this.state;
	const columns = [
		{
			field: 'first_name',
			headerName: 'FIRST NAME',
			width: 150,
			editable: true,
			// cellClassName: 'rowName',
			renderCell: (params) => {
				return (<div><span style={{textTransform: 'uppercase'}}>{params.value}</span></div>);
			}
		},
		{
			field: 'middle_name',
			headerName: 'MIDDLE NAME',
			width: 150,
			editable: true,
			cellClassName: 'rowName',
			renderCell: (params) => {
				return (<div><span style={{textTransform: 'uppercase'}}>{params.value}</span></div>);
			}
		},
		{
			field: 'last_name',
			headerName: 'LAST NAME',
			width: 150,
			editable: true,
			cellClassName: 'rowName',
			renderCell: (params) => {
				return (<div><span style={{textTransform: 'uppercase'}}>{params.value}</span></div>);
			}
		},
		{
			field: 'final_rating',
			headerName: 'EXAM',
			width: 120,
			// editable: true,
			height:500,
			renderCell: (params) => {
				if (params.value === 'Passed') {
					return (<div><span style={{backgroundColor:'green',color: 'white', padding: '5px', borderRadius:'8px'}}><i className="fas fa-check" style={{color:'white'}}></i>&nbsp;{'Passed'}</span></div>);
				} else if (params.value === 'Failed') {
					return (<div><span style={{backgroundColor:'red',color: 'white', padding: '5px', borderRadius:'8px'}}><i className="fas fa-times" style={{color:'white'}}></i>&nbsp;{'Failed'}</span></div>);
				} else {
					return (<div><span style={{backgroundColor:'rgb(135 110 120)',color: 'white', padding: '5px', borderRadius:'8px'}}><i className="fas fa-exclamation-circle" style={{color:'white'}}></i>&nbsp;{'Pending'}</span></div>);
				}
			  }
		},
		{
			field: 'appliedJobList',
			headerName: 'JOBS APPLIED',
			width: 250,
			cellClassName: (params) => {
				let jobs_applied = [];
				let jobsApplied = params.value;
				for(let i=0; i < jobsApplied.length; i++) {
					if(jobsApplied[i] !== 'null') {
						return 'jobApplied';
					}
				}
				return jobs_applied;
			},
			renderCell: (params) => {
				let jobs_applied = [];
				const cat = params.value;
				let maxVal = 0xFFFFFF;
				let randomNumber = Math.random() * maxVal; 
				randomNumber = Math.floor(randomNumber);
				const randColor = randomNumber.toString(16);
				let joBList = (cat.filter(n => n));
				for(let i=0; i < joBList.length; i++) {
					jobs_applied.push(<div key={i}><Chip label={joBList[i]} style={{background: `#${randColor}`,color: 'rgb(219 237 253)',padding: '12px'}}></Chip></div>);
				}
				return jobs_applied;
			}
		},
		{
			field: 'job_applied_cat',
			headerName: 'JOBS CATEGORY',
			width: 250,
			editable: true,
			cellClassName: (params) => {
				let jobs_applied = [];
				if(params.value !== "") {
					let jobsApplied = JSON.parse(params.value);
					for(let i=0; i < jobsApplied.length; i++) {
						if(jobsApplied[i] !== '') {
							return 'jobApplied';
						}
					}
				}
				return jobs_applied;
			},
			renderCell: (params) => {
				let jobs_applied = [];
				if(params.value !== "") {
					const cat = JSON.parse(params.value);
					let maxVal = 0xFFFFFF;
					let randomNumber = Math.random() * maxVal; 
					randomNumber = Math.floor(randomNumber);
					const randColor = randomNumber.toString(16);
					for(let i=0; i < cat.length; i++) {
						jobs_applied.push(<div key={i}><Chip label={cat[i]} style={{background: `#${randColor}`,color: 'rgb(219 237 253)',padding: '12px'}}></Chip></div>);
					}
				}
				return jobs_applied;
			}
		},
		{
			field: 'quick_present_address',
			headerName: 'PRESENT ADDRESS',
			width: 200,
			editable: true,
			cellClassName: 'rowName'
		},
		{
			field: 'def_educ_attain',
			headerName: 'EDUCATION ATTAINMENT',
			width: 200,
			renderCell: (params) => {
				if(params.value !== "") {
					return (<div><span style={{textTransform: 'uppercase'}}>{params.value.replace('_',' ')}</span></div>);
				}
				
			}
		},
		{
			field: 'def_job_level',
			headerName: 'EXPERIENCE LEVEL',
			width: 250,
			cellClassName: (params) => {
				let jobs_applied = [];
				if(params.value !== "") {
					let jobsApplied = JSON.parse(params.value);
					for(let i=0; i < jobsApplied.length; i++) {
						if(jobsApplied[i] !== '') {
							return 'jobApplied';
						}
					}
				}
				return jobs_applied;
			},
			renderCell: (params) => {
				let jobs_applied = [];
				if(params.value !== "") {
					const cat = JSON.parse(params.value);
					let maxVal = 0xFFFFFF;
					let randomNumber = Math.random() * maxVal; 
					randomNumber = Math.floor(randomNumber);
					const randColor = randomNumber.toString(16);
					for(let i=0; i < cat.length; i++) {
						jobs_applied.push(<div key={i}><Chip label={cat[i]} style={{background: `#${randColor}`,color: 'rgb(219 237 253)',padding: '12px'}}></Chip></div>);
					}
				}
				return jobs_applied;
			}
		},
		{
			field: 'phone',
			headerName: 'MOBILE NO',
			width: 150,
		},{
			field: 'phone',
			headerName: 'SOURCE',
			width: 150,
			renderCell: (params) => {
				return <span>WEB</span>;
			}
		}
	  ];
	  const applicContainter = {
		paddingTop:'3%',
		paddingBottom: '10px', 
		position: 'absolute',
		left: '40px', 
		right: '30px'
	  };
	  return (
		<div id="createApplicContainer" style={applicContainter}>
			<Typography component="h2" variant="h6">
				ALL APPLICANT
			</Typography>
			<div className="alert alert-warning alert-dismissible fade show" role="alert">
			<strong>Note</strong> Double click to see exam result.
			</div>
			<div style={{ width: '100%', marginTop: '15px' }}>
				<div style={{ display: 'flex', height: '100%' }}>
					<DataGrid
						rows={rows}
						columns={columns}
						pageSize={10}
						autoHeight= {true}
						rowsPerPageOptions={[10, 20, 50]}
						components={{
							Toolbar: GridToolbar,
						}}
						onCellDoubleClick={(params, event) => {
							if (!event.ctrlKey) {
								event.defaultMuiPrevented = true;
								localStorage.setItem('encode_name',params.row.encode_name);
								localStorage.setItem('applicantFullName',params.row.first_name +' '+params.row.middle_name+' '+params.row.last_name);
								this.props.history.push("/examresultperapplicant/");
							}
						}}
						checkboxSelection
						disableSelectionOnClick
						loading={loading}
						// density="compact"
					/>
				</div>
			</div>
		</div>
		);
  }
}
