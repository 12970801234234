import React, { Component } from "react";
import AuthService from "../../services/auth.service";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

// width: 236px;
//     height: 105px;
//     background-color: rgb(214, 227, 245);
//     position: fixed;
//     bottom: 30px;
//     right: 82px;
//     cursor: pointer;
//     padding-top: 15px;
//     box-shadow: rgb(0 0 0 / 20%) 0px 2px 10px;
//     font-weight: bold;
//     margin-bottom: 120px;

export default class Exam extends Component {
  constructor(props) {
    super(props);

    this.state = {
		redirect: null,
		userReady: false,
		currentUser: null,
		currentUserData: null,
		showing: true,
		checked: false
    };
  }

  componentDidMount() {
    const currentUserData = AuthService.getCurrentUser();
    if (currentUserData === null) {
      this.setState({ redirect: "/home" });
    } else {
      const currentUser = currentUserData.data;
      this.setState({ currentUser: currentUser, userReady: true })
    }
  }

 handleOnScroll = (e) => {
	const { scrollTop, scrollHeight, offsetHeight } = e.target;
	const hasScrollReachedBottom =
		offsetHeight + scrollTop > scrollHeight - 40;

	if (hasScrollReachedBottom) {
		console.log('test');
	}
}

handleCheckClick = () => {
	this.setState({checked:!this.state.checked})
}

hadleClickProceed = () => {
	this.props.history.push("/exam-questions");
}

  render() {
	const { checked } = this.state;
    return (
      <div className="container" onScroll={this.handleOnScroll}>
        {(this.state.userReady) ?
        
		<div style={{paddingTop:'70px'}}>
		<Paper style={{padding: '10px', marginBottom: '20px'}}>
			<Typography component="h1" variant="h5" style={{textAlign:'center'}}>
				INDUSTRIAL COGNITIVE ABILITY TEST (ICAT)
			</Typography>

			<Typography component="h1" variant="h5" style={{textAlign:'center', 'opacity':'.5', fontSize: '15px'}}>
				INTRODUCTION
			</Typography>
			<p style={{textAlign: 'justify'}}><br />
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This test is designed to measure the intellectual capacity of the applicants (Vocational Graduates, Associate Graduates, and College Graduates) of Cebu General Services, Inc. under three domains namely: Verbal, Mathematical, and Analytical intelligences. Verbal Intelligence is composed of 24 items that contain questions with Synonym-Antonym, Grammar, and Sentence Formation. Mathematical Intelligence is composed of another 24 items that contain questions with arithmetic, pattern completion, and numerical reasoning. Analytical Intelligence has 24 items that contain questions with reading comprehension, statement chronology, and logical reasoning. The test is a multiple-choice type of test with three/four given choices every item. The choices are in letters: A, B, C, and D. Furthermore, this can be answered in 1 hour and 10 minutes.
			</p>
			<p style={{textAlign: 'justify'}}>
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Verbal Intelligence is very essential in the workplace because everyday at work the verbal skills are used by all the employees in a company. For example an employee needs to communicate to various applicants, the need to report to an immediate superior about an incident, the need to write an endorsement letter to a client, the need to read a message via email about a request from a client, etc. Moreover, the mathematical intelligence is also very important in the day to day experience of the employees because the mathematical skills are valuable on how to complete the tasks instantly without sacrificing the quality, how to solve problems at work like on-time deployment of manpower in the field, how to compute the wages of the employees, etc. Lastly, the analytical intelligence is as well significant while employees are doing jobs because the analytical skills are applied by using rational decisions in the workplace like selecting the right applicant to hire, choosing on what to prioritize from all the tasks that need to be done, deciding on the in-take dates of the new hires, arranging meetings in a company, arranging time allotted to every activity related to the goals of a certain company.
			</p>
			<p style={{textAlign: 'justify'}}>
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{fontWeight:'bold'}}>Verbal Intelligence</span> measures the ability of an individual to read, write, and understand both the spoken and written language. Verbal or linguistic intelligence is part of the theory of Howard Gardner’s Multiple Intelligences. According to Gardner, writers, public speakers, and even teachers tend to possess strong levels of this form of intelligence. While almost everyone has some of this type of intelligence, some appear to be more gifted than others at using words in a powerful way. Under verbal one is Synonym-Antonym. It determines the ability of the test taker to remember or recall the meaning of the words that the test taker encounters. Second is grammar that verifies the ability of the test taker to construct sentences under the set of structural rules governing the composition of clauses, phrases, and words in the given language. Last is Sentence Formation. This pertains to how the test taker arranges set of words in a manner that it makes sense.  
			</p>
			<p style={{textAlign: 'justify'}}>
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{fontWeight:'bold'}}>Mathematical Intelligence</span> measures the ability of an individual to calculate, compute, figure, and carry out mathematical operations. It measures the skills of an individual with in terms to arithmetic, pattern completion, and numerical reasoning. In mathematical, first is arithmetic; it is the oldest branch of mathematics. It consists of traditional operations like addition, subtraction, multiplication, and division. Moreover, this makes the test taker remember the different operations and perform what is being asked. Aside from that, pattern completion is also part of this; it involves drawings that have a missing part. Furthermore, it requires the test taker’s ability to complete or provide the missing part of the drawings as fast as the test taker can. Lastly, numerical reasoning focuses on situations or statements that involve computations or calculations. Additionally, this makes the test takers perform calculations or computations of what is being asked however the item appears to be in a sentence form.
			</p>
			<p style={{textAlign: 'justify'}}>
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{fontWeight:'bold'}}>Analytical Intelligence</span> measures the ability of an individual to process and analyze information. It also allows an individual to make decisions and find solutions to the problems. For analytical, first is the reading comprehension that is the ability to read statements, process and understand the meaning of them. Second is statement chronology in which test takers are given statements or situations and the test takers will have to arrange them in a manner that shows appropriate order. Last is logical reasoning that involves a process of rational and systematic thinking based on the given statements.
			</p>
			<p style={{textAlign: 'justify'}}>
			<span style={{fontWeight:'bold'}}>Instructions:</span>
			<br />
			1. Click the letter of the best answer (e.g., A, B, C, and D).<br />
			2. You are given 1 hour & 10 minutes to answer the 72-item test.
			</p>
			<div style={{textAlign: 'center'}}>
				<div style={{width: '210px',height: '96px',backgroundColor: '#d6e3f5',position: 'fixed',bottom: '30px',right: '82px',cursor: 'pointer',paddingTop: '15px',boxShadow: '0px 2px 10px rgba(0,0,0,0.2)',fontWeight:'bold', padding:'5ox'}}>
					<input type="checkbox" checked={this.state.checked} onChange={this.handleCheckClick} className="filled-in" id="filled-in-box"/>
					&nbsp; Please check if you fully understand the instructions and Proceed to exam.
					<Button
						style={{ display:(checked ? 'block' : 'none'), marginLeft:'27%'}}
						color="primary"
						variant="contained"
						size="large"
						onClick={this.hadleClickProceed}
					>
					{
						'PROCEED TO EXAM'
					}
					</Button>
				</div>
			</div>
		</Paper>
      </div>: null}

      </div>
    );
  }
}
