import React, { Component } from "react";
import axios from 'axios';
import AuthService from "../../services/auth.service";
import ExamService from "../../services/exam.service";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Countdown from 'react-countdown';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Images question
import guide from "../../assets/image/question_images/guide.jpg";
import given1 from "../../assets/image/question_images/given1.png";
import given2 from "../../assets/image/question_images/given2.png";
import given3 from "../../assets/image/question_images/given3.png";
import given4 from "../../assets/image/question_images/given4.png";

export default class Examquestionproduction extends Component {
  constructor(props) {
    super(props);

	this.state = {
		redirect: null,
		userReady: false,
		selectedValue: null,
		currentUserData: null,
		verbalExamList: [],
		verbalNext: false,
		step: 1,
		disabled_step: 1,
		data: {},
		cirlce: [],
		disabled: false,
		submitted: false,
		open: false,
		successful: false,
		timeSA: Date.now() + 5000000,
		timeICAT: Date.now() + 4200000,
		speedAccu: '0:0:00',
		speedaccuExamList:[],
		prodExam_1: {
			given1: '',
			given2: '',
			given3: '',
			given4: ''
		}
	};
  }

	componentDidMount() {
		const currentUserData = AuthService.getCurrentUser();
		if(this.state.selectedValue==null) {
			this.setState({disabled: true});
		}

		const apiURL = ['','','analyticexam','speedaccuexam'];
		console.log(apiURL);

		// Get Verbal Exam
		ExamService.getVerbalExam().then(
			response => {
				this.setState({ verbalExamList:this.shuffleArray(response) });
			},
			error => {
				console.log(error);
			}
		);

		// Get Mathematical Exam
		ExamService.getMathExam().then(
			response => {
				this.setState({ mathExamList:this.shuffleArray(response) });
			},
			error => {
				console.log(error);
			}
		);

		
		// Get Mathematical Exam
		ExamService.getAnalExam().then(
			response => {
				this.setState({ analyticExamList:this.shuffleArray(response) });
			},
			error => {
				console.log(error);
			}
		);

		// Get Speed Accurate Exam
		ExamService.getSpeedExamProd().then(
			response => {
				this.setState({ speedaccuExamList:this.shuffleArray(response) });
			},
			error => {
				console.log(error);
			}
		);

		if (currentUserData === null) {
			this.setState({ redirect: "/home" });
		} else {
			const currentUser = currentUserData.data;
			this.setState({ currentUser: currentUser, userReady: true })
		}
	}

	shuffleArray = (array) => {
		let i = array.length - 1;
		for (; i > 0; i--) {
		  const j = Math.floor(Math.random() * (i + 1));
		  const temp = array[i];
		  array[i] = array[j];
		  array[j] = temp;
		}
		return array;
	  }

	// Renderer callback with condition
	renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
			console.log('complete step');
			this.handleNext();
			return <span id="countDown" style={{'float': 'right',marginRight: '15px', fontSize: '35px', position:'fixed',right:'126px',fontFamily:'fantasy', color:'red', marginTop: '15px', zIndex:'99'}}><i className="fas fa-clock" style={{fontSize: '30px',color: 'cornflowerblue'}}></i>&nbsp;{hours}:{minutes}:{seconds}</span>;
		} else {
			// Render a countdown
			return <span id="countDown" style={{'float': 'right',marginRight: '15px', fontSize: '35px', position:'fixed',right:'126px',fontFamily:'fantasy', color:'red', marginTop: '15px', zIndex:'9999'}}><i className="fas fa-clock" style={{fontSize: '30px',color: 'cornflowerblue'}}></i>&nbsp;{hours}:{minutes}:{seconds}</span>;
		}
	}

	onChange = (event) => {	
		const { data } = this.state;
		data[event.target.name] = event.target.value;
		this.setState({ data });
	}

	submit = () => {	
		this.form.submit();
	}	

	handleSubmit = () => {
		const ICATtime = document.getElementById('countDown').innerText;
		const curUser = JSON.parse(localStorage.getItem('user'));
		ExamService.calculateExamResult(
			JSON.stringify(this.state.selectedValue),
			curUser.data.encode_name,
			this.state.speedAccu,
			ICATtime
		).then(
		response => {
				if (response.status) {
					this.props.history.push("/exam-result");
				}
			}, error => {
				const resMessage = error.response.data;
				console.log(resMessage);
			}
		);
		this.setState({ submitted: true }, () => {
			setTimeout(() => this.setState({ submitted: false }), 5000);
		});
	}

	prevStep = () => {
		let { step } = this.state;
		if (step > 1) {
			step--;
		}
		this.setState({ step });
	}

	nextStep = () => {	
		let { step } = this.state;
		this.setState({ open: true});
		this.setState({ step });
	}

	handleClose = () => {
		this.setState({ verbalNext: false, open: false});
	}

	handleNext = () => {
		if (this.state.step === 1) {
			const speedAccu = document.getElementById('countDown').innerText;
			this.setState({disabled_step: 2, open: false, step: this.state.step+1, disabled: true, speedAccu: speedAccu});
		} else if (this.state.step === 2) {
			this.setState({disabled_step: 3, open: false, step: this.state.step+1, disabled: true});
		} else if (this.state.step === 3) {
			this.setState({disabled_step: 4, open: false, step: this.state.step+1, disabled: true});
		}
		console.log(this.state.step);
	}

	validatorListener = (result) => {
		this.setState({ disabled: !result });
	}

	renderAnswer(examid){
		const API_URL = "http://180.232.152.179:70/applicantapi/api/verbalexam/"+examid;
		axios({
			method: "get",
			url: API_URL,
		})
		.then(response => {
			localStorage.setItem("examanswer", JSON.stringify(response.data));
		})
	}

	// selected answer in every question
	onChangeAnswer = (event) => {
		const { name, value } = event.target;

		const { data } = this.state;
		data[name] = value;
		this.setState({
			selectedValue: data,
		});
		console.log(this.state.selectedValue);
	}

	onBscMatchChange = (event) => {
		const { data } = this.state;
		data[event.target.name] = event.target.value;
		this.setState({ data });
		console.log(data);
	}

	getCorrectChar = (e) => {
		let tarID = e.target.id.split('_')[1];
		console.log(tarID);
		let tarROWID = e.target.id.split('_')[0];
		document.getElementById(e.target.id).setAttribute('class','fa-solid fa-circle');
		localStorage.setItem('setCircle',tarROWID+'_'+tarID);
		localStorage.setItem('getSelCircle', e.target.id);
		let maxVal = 0xFFFFFF;
		let randomNumber = Math.random() * maxVal; 
		randomNumber = Math.floor(randomNumber);
		const randColor = randomNumber.toString(16);
		document.getElementById('selectLegend').setAttribute('style','position: absolute; margin-left: 300px; padding: 15px; background:#'+randColor);
		localStorage.setItem('setGiven',tarROWID+'_'+tarID);

		const { cirlce } = this.state;
		cirlce[e.target.id.split('_')[1]] = e.target.id;	

		this.setState({cirlce});
		console.log(this.state.cirlce);
	}

	getGiven = (e) => {
		console.log(e.target.value);
	}

	removeItemAll = (arr, elementToRemove) => {

		console.log(arr);
		console.log(elementToRemove);
		// return arr.filter(function(el){return el !== elementToRemove});
	  }

	getCorrectCharRemove = (e) => {
		// console.log(this.state.cirlce);
		this.removeItemAll(this.state.cirlce,e.target.id);
		document.getElementById(e.target.id).setAttribute('class','fa-regular fa-circle');
	}


	
	onSelectLegend = (event) => {
		// const targetValue = event.target.value;
		console.log(event.target.value);
		console.log(localStorage.getItem('getSelCircle'));
		// localStorage.setItem('selectedGiven',event.target.value);
		// localStorage.setItem('getSetCoordinates',localStorage.getItem('setGiven')+'-'+localStorage.getItem('selectedGiven'));
		// console.log(localStorage.getItem('setGiven'));
		// const getAssign = document.getElementById(localStorage.getItem('setGiven').split('_')[0]).getElementsByTagName('i'), obj = {};

		// for (var i = 0, l = getAssign.length; i < l; i++) {
		// 	console.log(getAssign[i].id);
			
		// 	if (targetValue === '1' || targetValue === '3') {
		// 		console.log(localStorage.getItem('setGiven'));
		// 		document.getElementById(localStorage.getItem('setGiven')).setAttribute('class','fa-regular fa-circle-dot');
		// 	} else if (targetValue === '2' || targetValue === '4') {
		// 		document.getElementById(localStorage.getItem('setGiven')).setAttribute('class','fa-solid fa-circle');
		// 	} else if (targetValue === '5') {
		// 		document.getElementById(localStorage.getItem('setGiven')).setAttribute('class','fa-solid fa-star');
		// 	}
		// }
		// console.log(obj);
	}
	
	

  renderStep = () => {
		const { step } = this.state;
		let content = null;
		switch (step) {
			case 1:
				const legendStyle = {
					position: 'absolute',
					marginLeft: '300px', 
					padding: '15px', 
					background: 'skyblue'
				}
				const legendStyle2 = {
					position: 'absolute',
					marginLeft: '300px', 
					padding: '15px', 
					background: 'skyblue',
					marginTop: '10%'
				}
				const legendStyle3 = {
					position: 'absolute',
					marginLeft: '300px', 
					padding: '15px', 
					background: 'skyblue',
					marginTop: '18%'
				}
				const legendStyle4 = {
					position: 'absolute',
					marginLeft: '300px', 
					padding: '15px', 
					background: 'skyblue',
					marginTop: '26%'
				}
				content = (
					<div>
						<Typography component="h4" variant="h5">
							CONNECTING DOTS
						</Typography>
						<span>There are thirty-six (36) characters on the guide and thirty-six (36) dots on the answer sheet.<br/>The dots on the answer sheet represent the characters on the guide.<br/>
						1.) Find the given five (5) characters on the guid and plot it on the answer sheet.<br/>
						2.) Click the dots that signify the position of characters. Encircle the 1st and 3rd dots,<br /> shade the 2nd and 4th dots and star for the 5th dot.<br/>
						3.) Connect the dots according to the sequence of the given five (5) characters.
						<br /><br/></span>
						<span>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<span style={{position:'block',fontWeight:'bold'}}>GUIDE:</span><br/>
								<img src={guide} alt="img" style={{height: '300px'}}/>
							</Grid>
							<Grid item xs={6}>
								<span style={{position:'block',fontWeight:'bold'}}>GIVEN:</span><br/>
							</Grid>
						</Grid>
						</span>
						<hr />
						<span style={{opacity: '.5',display: 'block',fontSize: '15px'}}>Please select best answer based on guide connected to given.<br />
						Click the dots that signify the position of characters.  </span>
						<span>
						 <b>Encircle the 1st  and 3rd dots</b>, <b>shade the 2nd and 4th dots </b>and <b>star for the 5th dot. </b></span><br /><br />
						<Grid container spacing={2} style={{marginLeft:'25px'}}>
							<Paper style={legendStyle}>
								<img src={given1} alt="img"/>
								<table style={{width: '90%', marginLeft: '20%'}}>
									<tr>
										<td><input type="checkbox" name="g1" onClick={this.getGiven} value={'g1p'}/> </td>
										<td><input type="checkbox" name="g1" onClick={this.getGiven} value={'g1and'}/> </td>
										<td><input type="checkbox" name="g1" onClick={this.getGiven} value={'g1pls'}/> </td>
										<td><input type="checkbox" name="g1" onClick={this.getGiven} value={'g1j'}/> </td>
										<td><input type="checkbox" name="g1" onClick={this.getGiven} value={'g1crl'}/> </td>
									</tr>
								</table>
							</Paper>
							<Paper style={legendStyle2}>
								<img src={given2} alt="img"/>
								<table style={{width: '90%', marginLeft: '20%'}}>
									<tr>
										<td><input type="checkbox" name="g2"  onClick={this.getGiven}  value={'g2and'}/> </td>
										<td><input type="checkbox" name="g2"  onClick={this.getGiven}  value={'g2z'}/> </td>
										<td><input type="checkbox" name="g2"  onClick={this.getGiven}  value={'g2qst'}/> </td>
										<td><input type="checkbox" name="g2"  onClick={this.getGiven}  value={'g2semi'}/> </td>
										<td><input type="checkbox" name="g2"  onClick={this.getGiven}  value={'g2and2'}/> </td>
									</tr>
								</table>
							</Paper>
							<Paper style={legendStyle3}>
								<img src={given3} alt="img"/>
								<table style={{width: '90%', marginLeft: '20%'}}>
									<tr>
										<td><input type="checkbox" name="g3" onClick={this.getGiven} value={'g3perc'}/> </td>
										<td><input type="checkbox" name="g3" onClick={this.getGiven} value={'g3j'}/> </td>
										<td><input type="checkbox" name="g3" onClick={this.getGiven} value={'g3ex'}/> </td>
										<td><input type="checkbox" name="g3" onClick={this.getGiven} value={'g3at'}/> </td>
										<td><input type="checkbox" name="g3" onClick={this.getGiven} value={'g3ini'}/> </td>
									</tr>
								</table>
							</Paper>
							<Paper style={legendStyle4}>
								<img src={given4} alt="img"/>
								<table style={{width: '90%', marginLeft: '20%'}}>
									<tr>
										<td><input type="checkbox" name="g4" onClick={this.getGiven}  value={'g4at'}/> </td>
										<td><input type="checkbox" name="g4" onClick={this.getGiven}  value={'g4ini'}/> </td>
										<td><input type="checkbox" name="g4" onClick={this.getGiven}  value={'g4k'}/> </td>
										<td><input type="checkbox" name="g4" onClick={this.getGiven}  value={'g4q'}/> </td>
										<td><input type="checkbox" name="g4" onClick={this.getGiven}  value={'g4grt'}/> </td>
									</tr>
								</table>
							</Paper>
							{[...Array(4)].map((x, i) =>
							<Grid item xs={12} key={i} style={{display: 'block', marginLeft:'10px'}}>
								<div id={'givenDiv'+(i+1)}>
									<span style={{position: 'absolute',fontSize: '24px', marginLeft:'-15px'}}>
										{i+1+')'}</span>

										{[...Array(36)].map((x, j) =>
											<span id={"givenNumber"+j} key={j}><i id={'circle_'+i+j} onDoubleClick={this.getCorrectCharRemove} onClick={this.getCorrectChar} className="fa-regular fa-circle" style={{marginLeft: '15px'}}></i><span style={{ display: j === 5 || j === 11 || j === 17 || j === 23 || j === 29 ? 'block': ''}}></span></span>
										)}
								</div>
							</Grid>
							)}
						</Grid>
					</div>
				);
				break;
			case 2:
				content = (
					<div>
						<Typography component="h4" variant="h5">
							SPEED AND ACCURACY
							<span style={{opacity: '.5',display: 'block',fontSize: '15px'}}>Click the character that are exactly the same as with the given item.</span>
						</Typography>
						
						<hr />
						<Grid container spacing={2}>
							{this.state.speedaccuExamList.map((list,key) => {
								return(
									<Grid item xs={12} key={list.examid}>
										<Paper key={key} style={{width: "100%", marginTop: '15px', padding: '10px'}}  variant="outlined">
											<Grid container spacing={2} >
												<Grid item xs={12}>
												<span style={{fontWeight:'bold'}}>{key + 1}. &nbsp;{list.question}</span>
												</Grid>
												<Grid item xs={3}>
													<input type="radio" 
													value={'a_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a1}
												</Grid>
												<Grid item xs={3}>
													<input type="radio" 
													value={'b_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a2}
												</Grid>
												<Grid item xs={2}>
													<input type="radio" 
													value={'c_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a3}
												</Grid>
												<Grid item xs={2}>
													<input type="radio" 
													value={'d_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a4}
												</Grid>
												<Grid item xs={2}>
													<input type="radio" 
													value={'d_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a5}
												</Grid>
											</Grid>
										</Paper>
									</Grid>
								)
							})}
						</Grid>
					</div>
				);
				break;
			case 3:
				const inputWdth = {
					width:'70px'
				}
				content = (
					<div>
						<Typography component="h4" variant="h5">	
							BASIC MATH
							<span style={{opacity: '.5',display: 'block',fontSize: '15px'}}>Find the missing numbers in the box and input your answers. This test is good for four (4) minutes.</span>
						</Typography>
						
						<hr />
						<Grid container spacing={2} style={{fontWeight:'bold', fontSize:"20px"}}>

							<Grid item xs={6}><span>1) 125 ÷  5 =  <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv1'}/></span></Grid>
							<Grid item xs={6}><span>11) 5 ÷  5 =  <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv11'}/></span></Grid>

							<Grid item xs={6}><span>2) 24 +  <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv2'}/> = 30 </span></Grid>
							<Grid item xs={6}><span>12) 9 x 2 =  <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv12'}/></span></Grid>

							<Grid item xs={6}><span>3) <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv3'}/> x 2 = 126 </span></Grid>
							<Grid item xs={6}><span>13) 68 - <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv13'}/> = 66</span></Grid>

							<Grid item xs={6}><span>4) 54 ÷  <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv4'}/> = 9 </span></Grid>
							<Grid item xs={6}><span>14) 19 + <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv14'}/> = 20</span></Grid>

							<Grid item xs={6}><span>5) 90 ÷  <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv5'}/> = 9 </span></Grid>
							<Grid item xs={6}><span>15) <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv15'}/> ÷ 6 = 11</span></Grid>

							<Grid item xs={6}><span>6) 102 - 96 = <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv6'}/></span></Grid>
							<Grid item xs={6}><span>16) 4 x <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv16'}/> = 20</span></Grid>

							<Grid item xs={6}><span>7) 44 x 4 = <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv7'}/></span></Grid>
							<Grid item xs={6}><span>17) 9 x 8 = <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv17'}/></span></Grid>

							<Grid item xs={6}><span>8) <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv8'}/> + 12 = 100 </span></Grid>
							<Grid item xs={6}><span>18) 36 ÷ 9 = <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv18'}/></span></Grid>

							<Grid item xs={6}><span>9) 11 - <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv9'}/> = 5 </span></Grid>
							<Grid item xs={6}><span>19) <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv19'}/> + 10 = 16 </span></Grid>

							<Grid item xs={6}><span>10) 16 ÷ <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv10'}/> = 4 </span></Grid>
							<Grid item xs={6}><span>20) 7 x 8 = <input type="number" onChange={this.onBscMatchChange} style={inputWdth} name={'giv20'}/></span></Grid>

						</Grid>
					</div>
				);
				break;
			default: 
			content = (<div></div>);
		}
		return content;
	}

  render() {
    const { step, disabled, submitted, disabled_step, timeSA, timeICAT } = this.state;
    return (
      <div className="container">
        {(this.state.userReady) ?
		<div style={{paddingTop:'70px'}}>
			<Dialog
				open={this.state.open}
				onClose={this.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Submit Confirmation"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you proceed next exam, if you click NEXT you cannot go back to the previous questions, Please check again your answer and Click next. GOODLUCK.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose}>Review Again</Button>
					<Button onClick={this.handleNext} autoFocus>
						Proceed Next
					</Button>
				</DialogActions>
			</Dialog>
		{!this.state.successful && (	
		<div>
		<Typography component="h1" variant="h5" style={{textAlign:'center', color:'#4240e3', fontFamily:'fantasy', fontSize:'30px' }}>
			EXAM
        </Typography>
		
		<Typography component="h1" variant="h5" style={{textAlign:'center', 'opacity':'.5', fontSize: '15px',
			marginBottom: '10px'
		}}>
			BEGIN HERE
        </Typography>
		 { step === 1 && (
			<Countdown
				date={timeSA}
				renderer={this.renderer}
			/> 
		 )} 
		 { step !== 1 && ( 
			<Countdown
				date={timeICAT}
				renderer={this.renderer}
			/> 
		 )} 
		<Paper style={{padding: '10px', marginBottom: '20px'}}>
			<ValidatorForm
				ref={(r) => { this.form = r; }}
				onSubmit={this.handleSubmit}
				instantValidate
			>
				{this.renderStep()}
				<br />
				<Button
					onClick={this.prevStep}
					style={{ marginRight: '16px' }}
					disabled={step === disabled_step}
				>
					previous
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={step < 4 ? this.nextStep : this.submit}
					disabled={disabled || submitted}
				>
					{
						(submitted && 'CALCULATING EXAM RESULT, PLEASE WAIT...')
						|| (step < 4 ? 'Next' : 'Submit')
					}
				</Button>
			</ValidatorForm>
		</Paper>
		</div> )}
      </div>: null}

      </div>
    );
  }
}
