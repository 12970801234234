import React, { Component } from "react";
import { ValidatorForm } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from 'draft-convert';
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import DOMPurify from 'dompurify';

import * as moment from 'moment';

import AuthService from "../../services/auth.service";
import JobsService from "../../services/job.service";

export default class Jobview extends Component {
  constructor(props) {
    super(props);
	this.state = {editorState: EditorState.createEmpty()};
    this.state = {
		user: '',
        formData: {
            job_position: '',
			company: '',
            vacancy: '',
            job_details: '',
			employment_type: '',
            street: '',
            city: '',
			No: false,
			Year: false,
			High: false,
			More: false,
			job_required: "",
			qualifications: [],
			jobRequired:[],
        },
		qualifications:[],
        submitted: false,
        loading: false,
        message: "",
		checked: true,
		disableDetails: false,
		disabled: true,
		alert: '',
		alertIcon: '',
		qualifOptions: [
			"No Experience",
			"1 Year or less",
			"High school graduate are welcome",
			"2 to 4 years"
		],
		successful: false,
		myjob: false
    }
  }
  
	componentDidMount() {
		const currentUserData = AuthService.getCurrentUser();
		if (currentUserData === null) {
			this.setState({ redirect: "/applicantjobs" });
		} else {
			const { id }  = this.props.match.params;
			// console.log(this.props.history);
			this.setState({ loading: false, jobID: id });
			this.setState({user: currentUserData.data, currentUser: currentUserData.data});
			// Get Job by ID
			JobsService.getjobByID(id, currentUserData.data.encode_name, currentUserData.data.role).then(
				response => {
					if (response.status) {
						const jobDetails = response.data;
						for(let i=0; i<jobDetails.length;i++) {
							if(jobDetails[i].user_id === currentUserData.data.encode_name) {
								this.setState({myjob: true});
							}
						}
						if (jobDetails[0].job_details) {
							this.setState({ editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(jobDetails[0].job_details))) });
							this.saveContent(this.state.editorState.getCurrentContent());
						} else {
							this.setState({ editorState: EditorState.createEmpty() });
						}
						this.setState({ jobRequired: JSON.parse(response.data[0].job_required) });
						this.setState({ formData: { ...response.data[0], qualifications:JSON.parse(response.data[0].job_required) }, disabled: false });
					}
				},
				error => {
					console.log(error);
				}
			);
		}
	}

	handleChange = (event) => {
		const { formData } = this.state;
		formData[event.target.name] = event.target.value;
		if (formData.qualifications.length < 1) {
			this.setState({ disabled: true });
		} else {
			this.setState({ disabled: false });
		}
		this.setState({ formData });
	}

	handleChangeChecked = (event) => {
		const { formData } = this.state;
		formData[event.target.name] = event.target.checked;
		// console.log(formData);
		if(formData.High || formData.More || formData.No || formData.Year) {
			this.setState({checkedData: true});
		} else {
			this.setState({checkedData: false});
		}
	}

	onChange = (editorState) => {
		const contentState = editorState.getCurrentContent();
		console.log('content state', convertToRaw(contentState));
		if(contentState.hasText()) {
			this.setState({disableDetails: true, disabled: false});
		} else {
			this.setState({disableDetails: false, disabled: true});
		}
		this.saveContent(contentState);
		this.setState({
			editorState,
		});
	}

	saveContent = (content) => {
		this.setState({job_details: convertToHTML(content)})
	}

	closeDiv = () => {
		this.props.history.goBack();
	}

	// filter the required options
	required(req) {
		// const qualif = JSON.parse(req);
		var reqdesc = [];
		req.forEach(element => {
			var withEx = 'Year';
			if(element.match(new RegExp(`${withEx}`, 'gi'))){
				var sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(224, 57, 151)'
					}}></i>
			}

			if(element.match(new RegExp(`No`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(242, 113, 28)'
					}}></i>
			}

			if(element.match(new RegExp(`High`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(251, 189, 8)'
					}}></i>
			}

			if(element.match(new RegExp(`years`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(33, 186, 69)'
					}}></i>
			}

			reqdesc.push(<div key={element}><span style={{fontSize: '13px'}}>
							{sprite} &nbsp;
							{element}
						</span><br /></div>)
		});
		return reqdesc;
	}

	handleSubmit = () => {
		this.setState({
			// resMessage: "",
			loading: true
		});
		this.setState({ submitted: true }, () => {
			const { id }  = this.props.match.params;
			JobsService.applyJob(
				id,
				this.state.user.encode_name
			).then(
			response => {
					// console.log(response);
					if (response.status) {
						setTimeout(() => 
							this.setState({ 
								message: response.message,
								successful: true,
								loading: false,
								alert: 'success',
								alertIcon:'Success'
							}), 4100);
							setTimeout(() => 
							this.setState({ 
								successful: false
							}), 6000);
							setTimeout(() => 
							this.props.history.push('/applicantjobs'), 6200);
					} else {
						setTimeout(() => 
							this.setState({ 
								message: response.message,
								successful: true,
								loading: false,
								alert: 'error',
								alertIcon:'Failed'
							}), 4100);
							setTimeout(() => 
							this.setState({ 
								successful: false
							}), 6000);
							setTimeout(() => 
							this.props.history.push('/applicantjobs'), 6200);
					}
				}, error => {
					const resMessage = error.response.data;
					console.log(resMessage);
				}
			);
			setTimeout(() => 
			this.setState({ submitted: false }), 5000);
		});
		}

  render() {
    const { formData, submitted, disabled } = this.state; 
	const createMarkup = (html) => {
		return  {
		  __html: DOMPurify.sanitize(html)
		}
	  }
    return (
		<div className="container" id="createjobContainer" style={{paddingTop:'45px',paddingBottom: '10px'}}>
			<Paper style={{maxWidth: '500px !important' }}>
			<span onClick={this.closeDiv } style={{color: '#0a0d0a',float: 'right',paddingRight: '10px',fontSize: '21px', cursor: 'pointer'}}><i className="fas fa-times"></i></span>
				<ValidatorForm
					ref="form"
					onSubmit={this.handleSubmit}
					style={{padding: '10px'}}
				>
					<Typography component="h1" variant="h5" style={{textAlign:'center'}}>
						Job Details
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12}> 
							<span style={{fontSize: '30px',fontWeight: 'bold'}}>{formData.job_position}</span>
							<span style={{display:'block',fontWeight:'bold',color: '#4982e7',fontSize: '18px'}}> {formData.company}</span>
							<span><span style={{fontWeight:'bold'}}>Total vacancies for this job title:</span> 
							&nbsp;{formData.vacancy}</span>
							<span style={{display:'block'}}><span style={{fontWeight:'bold'}}>Employment type:</span> 
							&nbsp;{formData.employment_type}</span>
							{this.required(formData.qualifications)}
							<span><i className="fas fa-map-marker-alt" style={{
								fontSize: '20px',
								color: 'rgb(33, 186, 69)'
							}}></i> {formData.street}, {formData.city}</span>
							<span style={{display:'block'}}>
								<i className="fas fa-clock" style={{color:'royalblue'}}></i> 
								&nbsp;{moment(formData.created).format('MMM DD YYYY h:mm A')}
								<span style={{ display: 'block',fontSize: '0.66em'}}>{moment(formData.created).startOf('hour').fromNow()}</span>
							</span>
						</Grid>
					</Grid>
					<hr />
					<Grid container spacing={2}>
						<Grid item xs={12}> 
							<h3>Job Description</h3>
								<div className="preview" dangerouslySetInnerHTML={createMarkup(this.state.job_details)}></div>
						</Grid>
					</Grid>
					{this.state.successful && (
						<Alert severity={this.state.alert} style={{ marginTop: '15px' }}>
							<AlertTitle>{this.state.alertIcon}</AlertTitle>
							{this.state.message}
						</Alert>
					)}
					{formData.user_id !== this.state.user.encode_name && (
						<div>
						{!this.state.myjob && (
						<div className="form-group" style={{marginTop: '-30px'}}>
							<Button
								color="primary"
								variant="contained"
								type="submit"
								disabled={disabled || submitted}
								style={{ 'width': '100%', marginTop: '30px'}}
							>
								{this.state.loading && (
									<div><span className="spinner-border spinner-border-sm"></span>&nbsp;</div>
								)}

								{
									(submitted && ' Applying Job..')
									|| (!submitted && 'Apply this job ')
								}
							</Button>
						</div>
						) }
						</div>
					) }
					</ValidatorForm>
			</Paper>
		</div>
		);
  }
}

