import React, { Component } from "react";
import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

import AuthService from "../../services/auth.service";
import '../Component.css';

export default class Adminlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
        submitted: false,
		// open: false,	
        loading: true,
        message: "",
		checked: true,
		rows: [],
    }
  }
  
  componentDidMount() {
	    // if(localStorage.getItem('openNav') === 0) {
		// 	document.getElementById("createApplicContainer").style.paddingLeft = "0px";
		// } else {
		// 	document.getElementById("createApplicContainer").style.paddingLeft = "216px";
		// }
		//Get current user
		const user = AuthService.getCurrentUser();
		if (user === null) {
			this.setState({ redirect: "/dashboard" });
		} else {
			AuthService.getAllAdmin().then(
				response => {
					console.log(response);
					try {
						this.setState({ rows:response, loading: false });
					}catch (error) {
						console.log(error);
					}
				},
				error => {
					console.log(error);
				}
			);
	  	}
  }	

	openForm = () => {
		// this.setState({open: true});
		console.log(1);
		this.props.history.push("/add-admin");
	}

  render() {
    const { rows, loading } = this.state;
	const columns = [
		{
			field: 'first_name',
			headerName: 'First name',
			width: 250,
			editable: true
		},
		{
			field: 'middle_name',
			headerName: 'Middle name',
			width: 250,
			editable: true
		},
		{
			field: 'last_name',
			headerName: 'Last name',
			width: 250,
			editable: true
		},
		{
			field: 'userid',
			headerName: 'Action',
			width: 200,
			// editable: true,
			// height:500,
			renderCell: (params) => {
				// console.log(params.value);
				// if (params.value === 'Passed') {
				// 	return (<div><span style={{backgroundColor:'green',color: 'white', padding: '5px', borderRadius:'8px'}}><i className="fas fa-check" style={{color:'white'}}></i>&nbsp;{'Passed'}</span></div>);
				// } else if (params.value === 'Failed') {
				// 	return (<div><span style={{backgroundColor:'red',color: 'white', padding: '5px', borderRadius:'8px'}}><i className="fas fa-times" style={{color:'white'}}></i>&nbsp;{'Failed'}</span></div>);
				// } else {
				// 	return (<div><span style={{backgroundColor:'gray',color: 'white', padding: '5px', borderRadius:'8px'}}><i className="fas fa-exclamation-circle" style={{color:'white'}}></i>&nbsp;{'Pending'}</span></div>);
				// }
				// const onClick = (e) => {
				//   e.stopPropagation(); // don't select this row after clicking
		  
				//   const api: GridApi = params.api;
				//   const thisRow: Record<string, GridCellValue> = {};
		  
				//   api
				// 	.getAllColumns()
				// 	.filter((c) => c.field !== "__check__" && !!c)
				// 	.forEach(
				// 	  (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
				// 	);
		  
				//   return alert(JSON.stringify(thisRow, null, 4));
				// };
				return <div> <Button><span className="fa fa-edit" onClick={this.openEdit}></span></Button><Button><span className="fa fa-trash" style={{color:'red'}}></span></Button></div>;
				// return <Button onClick={onClick}>Click</Button>;
			  }
		},
	  ];
	  const applicContainter = {
		paddingTop:'45px',paddingBottom: '10px', position: 'absolute',left: '40px', right: '30px'
	  };
	  return (
		<div id="createApplicContainer" style={applicContainter}>
			<Typography component="h4" variant="h6" style={{textAlign:'left', paddingBottom: '10px', marginTop: '15px'}}>
				Admin List
			</Typography>
			<span><Button variant="contained" style={{position: 'absolute',right: '0px',marginTop:'-45px'}} onClick={this.openForm}>Add Admin</Button></span>
			<div style={{ height: 500, width: '100%' }}>
				<DataGrid
					rows={rows}
					columns={columns}
					pageSize={10}
					autoHeight= {true}
					rowsPerPageOptions={[10, 20, 50]}
					components={{
						Toolbar: GridToolbar,
					  }}
					onCellDoubleClick={(params, event) => {
						if (!event.ctrlKey) {
							event.defaultMuiPrevented = true;
							console.log(params.row);
							this.props.history.push("/profile?id="+params.row.encode_name);
						}
					}}
					checkboxSelection
					disableSelectionOnClick
					loading={loading}
				/>
			</div>
		</div>
		);
  }
}
