import React, { Component } from "react";
import { Switch, Route, Link} from "react-router-dom";

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
// import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import Typography from '@material-ui/core/Typography';

//Icons
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import Person from '@material-ui/icons/Person';
import DashboardIcon from '@material-ui/icons/Dashboard';
import JobsIcon from '@material-ui/icons/Business';
import TakeExam from '@material-ui/icons/AccountTree';
import ExamResultIcon from '@material-ui/icons/FormatListNumbered';
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ArrowDownWard from '@material-ui/icons/ArrowDropDown';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import GroupIcon from '@material-ui/icons/Group';

import Divider from '@material-ui/core/Divider';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Logo from "./image/cgsilogo.png";

import AuthService from "./services/auth.service";

// Main page
import Login from "./components/login.component";
import Login2 from "./components/login2.component";
import Register from "./components/register.component";
import Home from "./components/home.component";

// Profile
import Profile from "./components/profile.component";
import ApplicantProfile from "./components/Profile/profile.component";
import QuickApply from "./components/Applicant/quickapplicant.component";

// import BoardUser from "./components/board-user.component";
import Dashboard from "./components/dashboard.component";

// Exam page
import ExamQuestion from "./components/exam/exam-question.component";
import ExamQuestionProd from "./components/exam/examquestionproduction.component";
import Exam from "./components/exam/exam.component";
import ExamResult from "./components/exam/examresult.component";
import ExamResultPerApplic from "./components/Applicant/examresultperapplicant.component";

// Job page
import Jobs from "./components/Jobs/jobs.component";
import JobInfo from "./components/Jobs/jobinfo.component";
import JobApplicantView from "./components/Jobs/jobapplicantview.component";
import AddJobs from "./components/Jobs/createjobs.component";
import JobsApplied from "./components/Jobs/appliedjobs.component";
import ApplicantJobs from "./components/Jobs/applicantjobs.component";
import JobView from "./components/Jobs/jobview.component";
import JobDetails from "./components/Jobs/jobdetails.component";
import CgsiJobs from "./components/Jobs/cgsijobs.component";
import JobDescp from "./components/Jobs/jobdescription.component";

//Applicant page
import ApplicantList from "./components/Applicant/applicantlist.component";
import AddApplicant from "./components/Applicant/addapplicant.component";
import WalkIn from "./components/Applicant/walkinapplicant.component";

//Applicant page
import AdminList from "./components/Admin/adminlist.component";
import AddAdmin from "./components/Admin/addadmin.component";

import EventBus from "./common/EventBus";

class App extends Component {
	constructor(props) {
		super(props);
		this.logOut = this.logOut.bind(this);

		this.state = {
			showApplicantBoard: false,
			showAdminBoard: false,
			currentUser: undefined,
			anchorEl: null,
			anchorEl2: null,
			setAnchorEl: null,
			open: false,
			open2: false,
			getContentAnchorEl: null,
			topTitle: false,
			firstLetterName: '',
			activeNav:false,
			activeMenu:'dashBoard',
			sideNav:'sidenav', 
			currentURL:'',
			cgsijobsdisplay: false
		};
	}

	componentDidMount() {
		const pathname = window.location.pathname;
		// console.log(pathname);
		this.setState({currentURL:pathname});
		// console.log(pathname.split('/')[1]);
		// const pathSplit = 

		if(pathname === '/cgsi-jobs') {
			this.setState({cgsijobsdisplay: true});
		} 

		this.setState({sideNav:'sidenavOpen'})
		localStorage.setItem('openNav',1);

		if(localStorage.getItem('openNav') === '0') {
			this.setState({sideNav:'sidenav'});
			this.setState({topTitle: false});
		} else {
			let tabNameActive = localStorage.getItem('activeTab');
			if (tabNameActive === 'applicant') {
				this.setState({activeMenu:'applicList'});
			} else if (tabNameActive === "jobs") {
				this.setState({activeMenu:'jobList'});
			} else if (tabNameActive === "addjob") {
				this.setState({activeMenu:'addJob'});
			} else if (tabNameActive === "applicantjobs") {
				this.setState({activeMenu:'applicantJobs'});
			} else if (tabNameActive === "appliedjobs") {
				this.setState({activeMenu:'appliedJobs'});
			}
			this.setState({sideNav:'sidenavOpen'});
			this.setState({topTitle: true});
		}
		const user = AuthService.getCurrentUser();
		if (user === null) {
			console.log(1);
			this.setState({ redirect: "/login" });
		} else {
			if(user.data.id == null) {
				this.setState({ redirect: "/login" });
			} else {
				if (user.data.id) {
					this.setState({
						currentUser: user.data
					});
					if(user.data.role === '2') {
						this.setState({
							showAdminBoard: true
						});
					} 
					if (user.data.role === '1') {
						this.setState({
							showApplicantBoard: true
						});
					} 
				} else {
					const loguser = (JSON.parse(localStorage.getItem('logUser'))).data.data[0];
					this.setState({
						currentUser: loguser
					});
					if(loguser.role === '2') {
						this.setState({
							showAdminBoard: true
						});
					} 
					if (loguser.role === '1') {
						this.setState({
							showApplicantBoard: true
						});
					}
				}
			}
		}
		
		EventBus.on("logout", () => {
			this.logOut();
		});
	}

	componentWillUnmount() {
		EventBus.remove("logout");
	}

	logOut() {
		AuthService.logout();
		this.setState({
			showApplicantBoard: false,
			showAdminBoard: false,
			currentUser: undefined,
		});
		window.location.href="/login";
	}

	profile = () => {
		const user = AuthService.getCurrentUser();
		window.location.href="/profile?id="+user.data.encode_name;
	}

  openNav = () => {
	const pathname = window.location.pathname;
	this.setState({sideNav:'sidenavOpen'})
	localStorage.setItem('openNav',1);
	// console.log(pathname.split('/')[1]);
	document.getElementById("rightMenu").style.paddingRight = "250px";
	document.getElementById("mySidenav").style.width = "250px";
	document.getElementById("mainContainer").style.marginLeft = "245px";
	document.getElementById("mainContainer").style.paddingRight = "0px";
	document.getElementById("mainContainer").style.width = "80%";
	// applicant container
	if (window.location.pathname === '/applicant-list') {
		document.getElementById("createApplicContainer").style.paddingLeft = "220px";
	} else if (window.location.pathname === '/admin-list') {
		document.getElementById("createApplicContainer").style.paddingLeft = "220px";
	} else if (window.location.pathname === '/jobs') {
		document.getElementById("createApplicContainer").style.paddingLeft = "0px";
	} else if (window.location.pathname === '/dashboard') {
		document.getElementById("createApplicContainer").style.paddingLeft = "15px";
	} else if (pathname.split('/')[1] === '/applicantprofile') {
		document.getElementById("createApplicContainer").style.paddingLeft = "15px";
	} else if (pathname.split('/')[1] === '/applicantjobs') {
		document.getElementById("createApplicContainer").style.paddingLeft = "15px";
	} else if (window.location.pathname === '/appliedjobs') {
		document.getElementById("jobPortalContainer").style.paddingLeft = "15px";
	} else if (pathname.split('/')[1] === '/jobInfo') {
		document.getElementById("createApplicContainer").style.paddingLeft = "0px !important";
	} else if (pathname.split('/')[1] === '/addjobs') {
		document.getElementById("createApplicContainer").style.paddingLeft = "0px";
	} else if (pathname.split('/')[1] === '/exam-result') {
		document.getElementById("jobPortalContainer").style.paddingLeft = "0px";
	}
	this.setState({topTitle: true});
  }

  closeNav = () => {
	const pathname = window.location.pathname;
	localStorage.setItem('openNav',0);
	this.setState({sideNav:'sidenav'})
	document.getElementById("mySidenav").style.width = "0";
	document.getElementById("mainContainer").style.marginLeft = "0px";
	document.getElementById("mainContainer").style.paddingRight = "0px";
	document.getElementById("mainContainer").style.width = "100%";
	document.getElementById("topBar").style.width = "100%";
	document.getElementById("rightMenu").style.paddingRight = "0px";
	// applicant container
	if (window.location.pathname === '/applicant-list' || window.location.pathname === '/admin-list') {
		document.getElementById("createApplicContainer").style.paddingLeft = "0px";
	} else if (window.location.pathname === '/jobs') {
		document.getElementById("createApplicContainer").style.paddingLeft = "0px";
	} else if (window.location.pathname === '/addjobs') {
		document.getElementById("createApplicContainer").style.paddingLeft = "0px";
	} else if (window.location.pathname === '/dashboard') {
		document.getElementById("createApplicContainer").style.paddingLeft = "0px";
	} else if (pathname.split('/')[1] === '/applicantprofile') {
		document.getElementById("createApplicContainer").style.paddingLeft = "15px";
	} else if (window.location.pathname === '/applicantjobs') {
		document.getElementById("createApplicContainer").style.paddingLeft = "15px";
	} else if (window.location.pathname === '/appliedjobs') {
		document.getElementById("jobPortalContainer").style.paddingLeft = "15px";
	} else if (pathname.split('/')[1] === '/jobInfo') {
		document.getElementById("createApplicContainer").style.paddingLeft = "0px";
	}
	this.setState({topTitle: false});
  }

  handleClick = (event) => {
	this.setState({anchorEl: event.currentTarget, open: true});
  }
  handleClick2 = (event) => {
	this.setState({anchorEl2: event.currentTarget, open2: true});
  }
  handleClose = () => {
	this.setState({anchorEl: null, open: false});
  }

  handleClose2 = () => {
	this.setState({anchorEl2: null, open2: false});
  }

  getActiveTab = (tabName) => {
	localStorage.setItem('activeTab', tabName);
	if (tabName === 'applicant') {
		this.setState({activeMenu:'applicList'});
	} else if (tabName === "jobs") {
		this.setState({activeMenu:'jobList'});
	} else if (tabName === "addjob") {
		this.setState({activeMenu:'addJob'});
	} else if (tabName === "dashboard") {
		this.setState({activeMenu:'dashBoard'});
	} else if (tabName === "adminlist") {
		this.setState({activeMenu:'adminList'});
	} else if (tabName === "applicantjobs") {
		this.setState({activeMenu:'applicantJobs'});
	} else if (tabName === "appliedjobs") {
		this.setState({activeMenu:'appliedJobs'});
	} else if (tabName === "quickapplicant") {
		this.setState({activeMenu:'quickApply'});
	} else if (tabName === "exam-result") {
		this.setState({activeMenu:'examResult'});
	} else if (tabName === "exam") {
		this.setState({activeMenu:'exam'});
	}
  }

  render() {
    const { 
		currentUser, 
		showApplicantBoard, 
		showAdminBoard, 
		anchorEl2, 
		open, 
		open2,
		anchorEl
	} = this.state;
    if (window.location.pathname === '/login') 
    return (<div style={this.state.container}>
       <Switch>
          <Route exact path="/login" component={Login} />
       </Switch>
    </div>);
    if (window.location.pathname === '/register') 
    return (<div>
       <Switch>
          <Route exact path="/register" component={Register} />
       </Switch>
    </div>);
    return (
      <div id="mainContainer" style={{marginLeft: '0px', paddingRight: '0px', width: '100%'}}>
          {currentUser ? (
			<nav id="topBar" className="navbar navbar-expand navbar-light bg-primary navbar-fixed-top" style={{position:'fixed',width: '100%',marginTop: '0px',zIndex:'99', height: '50px'}}>
				{!this.state.topTitle && !this.state.cgsijobsdisplay && (
					<div style={{height:'35px'}}>
						<span style={{marginLeft: '40px', curson: 'pointer'}} onClick={this.openNav}>
							<i className="fas fa-bars" style={{
							fontSize: '25px',
							color: 'white',
							cursor: 'pointer', 
							position: 'absolute'}}>	
							</i>
						</span>
						<img src={Logo} alt="website logo" style={{width: "77px", marginLeft:'30px'}}/>
						<span style={{
							fontSize: '20px',
							marginLeft: '8px',
							position:'absolute',
							fontFamily: 'revert',
							textShadow: '-1px 1px 3px rgb(0 0 0 / 30%)',
							fontWeight: '400',
							color: 'white'
						}}>Cebu General Services Incorporated</span>
					</div>
				)}

				{this.state.cgsijobsdisplay && (
					<div style={{height:'35px'}}>
						<img src={Logo} alt="website logo" style={{width: "77px", marginLeft:'30px'}}/>
						<span style={{
							fontSize: '20px',
							marginLeft: '8px',
							position:'absolute',
							fontFamily: 'revert',
							textShadow: '-1px 1px 3px rgb(0 0 0 / 30%)',
							fontWeight: '400',
							color: 'white'
						}}>Cebu General Services Incorporated</span>
					</div>
				)}	

				{!this.state.cgsijobsdisplay && (
				<div id="mySidenav" className={`${this.state.sideNav==='sidenavOpen'?'sidenavOpen':'sidenav'}`}>
					{this.state.topTitle && (
						<div className="sideBarTitle">
							<img src={Logo} alt="website logo" style={{width: '80px',marginLeft: '62px'}}/>
							{/* <span className="sideCGSILogo">C</span>
							<span>ebu</span>
							<span className="sideCGSILogo">G</span>
							<span>eneral</span><br/>
							<span className="services">SERVICES</span> */}
						</div>
					)}
					<span className="closebtn" onClick={this.closeNav}>&times;</span>

					<div className='containerLeftIcon'>
						<div style={{marginTop:'30px'}}>
							<img src={Logo} alt="website logo" style={{"width": "45px"}} className='iconDetails'/>
							<div style={{marginLeft:'60px',color: 'white'}}>
								<span className="capitalize">{currentUser.first_name+' '+currentUser.last_name}</span>
								<div style={{fontSize:".6em"}}>{currentUser.email}&nbsp;<ArrowDownWard onClick={this.handleClick2}/></div>
							</div>
						</div>
						<Menu
							anchorEl={anchorEl2}
							open={open2}
							onClose={this.handleClose2}
							getContentAnchorEl={null}
							PaperProps={{
							elevation: 0,
							sx: {
								overflow: 'visible',
								filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
								mt: 1.5,
								'& .MuiAvatar-root': {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
								},
								'&:before': {
								content: '""',
								display: 'block',
								position: 'absolute',
								top: 0,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: 'background.paper',
								transform: 'translateY(-50%) rotate(45deg)',
								zIndex: 0,
								},
							},
							style: {
								backgroundColor: 'white',
								boxShadow: '0px 2px 8px rgba(0,0,0,0.32)',
								},
							}}
							transformOrigin={{ horizontal: 'right', vertical: 'top' }}
							anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
						>
							<MenuItem 
							>
								<Link to={"/profile/"+currentUser.encode_name} style={{textDecoration: 'none'}}>
									<ListItemIcon>
										<Person fontSize="medium" className="menuIcon"/> 
										<span style={{ fontWeight: 'bold', marginLeft:'12px'}}>Profile</span>
									</ListItemIcon>
								</Link>
							</MenuItem>
							{showApplicantBoard && (
							<MenuItem 
							>
								<Link to={"/exam-result"} style={{textDecoration: 'none'}}>
									<ListItemIcon>
										<ExamResultIcon fontSize="medium"  className="menuIcon"/> 
										<span style={{ fontWeight: 'bold',marginLeft:'12px'}}>Exam Result</span>
									</ListItemIcon>
								</Link>
							</MenuItem>
							)}
						</Menu>
					</div>
					<hr />
					{showAdminBoard && (
					<div>
						<div className="sidenav1">
							<Link to={"/dashboard"} onClick={() => this.getActiveTab('dashboard')} className={`${this.state.activeMenu==='dashBoard'?'activeNavbar':''}`}><DashboardIcon  className="menuIcon"/> Dashboard</Link>
							<button className="dropdown-btn"><GroupIcon /> Applicant 
								<i className="fa fa-caret-down"></i>
							</button>
							<div className="dropdown-container">
								<Link to={"/applicant-list"} onClick={() => this.getActiveTab('applicant')} className={`${this.state.activeMenu==='applicList'?'activeNavbar':''}`}>All List</Link>
								<Link to={"/walkin"} onClick={() => this.getActiveTab('walkin')} className={`${this.state.activeMenu==='walkin'?'activeNavbar':''}`}>Walk In </Link>
								<Link to={"/quick-applicant-list"} onClick={() => this.getActiveTab('quickapplicant')} className={`${this.state.activeMenu==='quickApply'?'activeNavbar':''}`}>QuickApply </Link>
							</div>
							<button className="dropdown-btn"><AssignmentIndIcon  className="menuIcon"/>  Admin 
								<i className="fa fa-caret-down"></i>
							</button>
							<div className="dropdown-container">
								<Link to={"/admin-list"} onClick={() => this.getActiveTab('adminlist')} className={`${this.state.activeMenu==='adminList'?'activeNavbar':''}`}>All List</Link>
							</div>
							<button className="dropdown-btn"><JobsIcon  className="menuIcon"/> Jobs 
								<i className="fa fa-caret-down"></i>
							</button>
							<div className="dropdown-container">
								{showAdminBoard && (
									<div>
									<Link to={"/addjobs"} onClick={() => this.getActiveTab('addjob')} className={`${this.state.activeMenu==='addJob'?'activeNavbar':''}`}> Add Job</Link>
									<Link to={"/jobs"} onClick={() => this.getActiveTab('jobs')} className={`${this.state.activeMenu==='jobList'?'activeNavbar':''}`}> Jobs</Link>
									</div>
								)}
							</div>
							<Link to={"/"} onClick={this.logOut}><i className="fas fa-sign-out-alt"></i> Log Out</Link>
						</div>
					</div>
					)}
						{showApplicantBoard && (
						<div>
							<Link to={"/applicantjobs"}  onClick={() => this.getActiveTab('applicantjobs')} className={`${this.state.activeMenu==='applicantJobs'?'activeNavbar':''}`}><WorkOutlineIcon  className="menuIcon"/> Jobs</Link>
							<Link to={"/appliedjobs"}   onClick={() => this.getActiveTab('appliedjobs')} className={`${this.state.activeMenu==='appliedJobs'?'activeNavbar':''}`}><WorkIcon  className="menuIcon"/> Applied Jobs</Link>
						</div>
						)}
					{showApplicantBoard && (
					<div>
						{currentUser.complete_status && (
							<Link to={"/exam?id="+currentUser.encode_name} onClick={() => this.getActiveTab('exam')} className={`${this.state.activeMenu==='exam'?'activeNavbar':''}`}>
								<TakeExam /> Take Exam
							</Link>	
						)}	
						<Link to={"/exam-result"} onClick={() => this.getActiveTab('exam-result')} className={`${this.state.activeMenu==='examResult'?'activeNavbar':''}`}><ExamResultIcon /> Exam Result</Link>
						<Link to={"/"} onClick={this.logOut}><i className="fas fa-sign-out-alt"></i> Log Out</Link>
					</div>
					)}
					
				</div>
				)}

			<div className="navbar-nav">
              {currentUser && (
				<div>
					<div className="navbar-nav ml-auto" id="rightMenu" style={{ position: 'absolute',right: '20px', top: '10px'}}>
						<li className="nav-item">
							<Tooltip title="Account">
								<IconButton 
									onClick={this.handleClick} 
									size="small" sx={{ ml: 2 }} 
									style={{marginTop:'-7px'}}
								>
								{/* <img src='https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png' className='iconDetails' alt="logo"/>	 */}
								</IconButton>
							</Tooltip>
							<Menu
								anchorEl={anchorEl}
								open={open}
								onClose={this.handleClose}
								getContentAnchorEl={null}
								PaperProps={{
								elevation: 0,
								sx: {
									overflow: 'visible',
									filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
									mt: 1.5,
									'& .MuiAvatar-root': {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
									},
									'&:before': {
									content: '""',
									display: 'block',
									position: 'absolute',
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: 'background.paper',
									transform: 'translateY(-50%) rotate(45deg)',
									zIndex: 0,
									},
								},
								style: {
									backgroundColor: 'white',
									boxShadow: '0px 2px 8px rgba(0,0,0,0.32)',
								  },
								}}
								transformOrigin={{ horizontal: 'right', vertical: 'top' }}
								anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
							>
								<MenuItem 
								>
									<Link to={"/profile/"+currentUser.encode_name} style={{textDecoration: 'none'}}>
										<ListItemIcon>
											<Person fontSize="medium" />
										</ListItemIcon>
										Profile
									</Link>
								</MenuItem>
								<Divider />
								<MenuItem to={"/"} onClick={this.logOut}>
									<ListItemIcon>
										<ArrowForwardIos fontSize="medium" />
									</ListItemIcon>
									Logout
								</MenuItem>
							</Menu>
						</li>
					</div>
				</div>
              )}
            </div>
        </nav>
          ) : (
            <nav className="navbar navbar-expand navbar-light bg-primary">
			<div style={{height:'35px'}}>
				<img src={Logo} alt="website logo" style={{width: "77px", marginLeft:'30px'}}/>
				<span style={{
					fontSize: '20px',
					marginLeft: '8px',
					position:'absolute',
					fontFamily: 'revert',
					textShadow: '-1px 1px 3px rgb(0 0 0 / 30%)',
					fontWeight: '400',
					color: 'white'
				}}>Cebu General Services Incorporated</span>
				<Link to={"/login"} style={{textDecoration: 'none',color: 'white', position: 'absolute',right: '20px'}}>
					<button className="btn btn-info" style={{width: '200px'}}><i className="fa fa-user"></i> LOGIN</button>
				</Link>
				<Link to={"/register"} style={{textDecoration: 'none',color: 'white', position: 'absolute',right: '230px'}}>
					<button className="btn btn-success" style={{width: '200px'}}><i className="fa fa-user"></i> CREATE ACCOUNT</button>
				</Link>
			</div>
            </nav>
          )}
 		{/* className="container mt-3" */}
        <div>
          <Switch>
            <Route exact path={["/", "/home"]} component={Home} />
            <Route exact path="/login" component={Login} />
			<Route exact path="/login2" component={Login2} />
            <Route exact path="/register" component={Register} />

			{/* Profile View */}
            <Route exact path="/profile/:id" component={Profile} />
			<Route exact path="/applicantprofile/:id" component={ApplicantProfile} />


            <Route exact path="/exam" component={Exam} />
            <Route exact path="/exam-questions" component={ExamQuestion} />
			<Route exact path="/examquesprod" component={ExamQuestionProd} />
			<Route exact path="/exam-result" component={ExamResult} />
			<Route exact path="/examresultperapplicant" component={ExamResultPerApplic} />
            <Route exact path="/dashboard" component={Dashboard} />

			{/* Job View */}
			<Route exact path="/jobdetails/:id" component={JobDetails} />
			<Route exact path="/jobdescription/:id" component={JobDescp} />
			<Route exact path="/jobInfo/:id" component={JobInfo} />
			<Route exact path="/jobapplicantview/:id" component={JobApplicantView} />
			<Route exact path="/jobview/:id" component={JobView} />
            <Route exact path="/jobs" component={Jobs} />
			<Route exact path="/applicantjobs" component={ApplicantJobs} />
			<Route exact path="/addjobs" component={AddJobs} />
			<Route exact path="/appliedjobs" component={JobsApplied} />
			<Route exact path="/job-list" component={ApplicantList} />
			<Route exact path="/cgsi-jobs" component={CgsiJobs} />
			
			{/* Applicant View */}
			<Route exact path="/applicant-list" component={ApplicantList} />
			<Route exact path="/add-applicant" component={AddApplicant} />
			<Route exact path="/quick-applicant-list" component={QuickApply} />
			<Route exact path="/walkin" component={WalkIn} />
			

			<Route exact path="/admin-list" component={AdminList} />
			<Route exact path="/add-admin" component={AddAdmin} />
          </Switch>
        </div>
        
      </div>
    );
  }
}

export default App;
