import React, { Component } from "react";
import axios from 'axios';
import AuthService from "../../services/auth.service";
import ExamService from "../../services/exam.service";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Countdown from 'react-countdown';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Images question
import q9 from "../../assets/image/question_images/q9.png";
import q10 from "../../assets/image/question_images/q10.png";
import q11 from "../../assets/image/question_images/q11.png";
import q12 from "../../assets/image/question_images/q12.png";
import q13 from "../../assets/image/question_images/q13.png";
import q14 from "../../assets/image/question_images/q14.png";
import q15 from "../../assets/image/question_images/q15.png";
import q16 from "../../assets/image/question_images/q16.png";

export default class Exam extends Component {
  constructor(props) {
    super(props);

	this.state = {
		redirect: null,
		userReady: false,
		selectedValue: null,
		currentUserData: null,
		verbalExamList: [],
		verbalNext: false,
		step: 1,
		disabled_step: 1,
		data: {},
		disabled: false,
		submitted: false,
		open: false,
		successful: false,
		timeSA: Date.now() + 300000,
		timeICAT: Date.now() + 4200000,
		speedAccu: '0:0:00',
		speedaccuExamList:[]
	};
  }

	componentDidMount() {
		const currentUserData = AuthService.getCurrentUser();
		if(this.state.selectedValue==null) {
			this.setState({disabled: true});
		}

		const apiURL = ['','','analyticexam','speedaccuexam'];
		console.log(apiURL);

		// Get Verbal Exam
		ExamService.getVerbalExam().then(
			response => {
				// this.setState({ verbalExamList:this.shuffleArray(response) });
				this.setState({ verbalExamList:response });
			},
			error => {
				console.log(error);
			}
		);

		// Get Mathematical Exam
		ExamService.getMathExam().then(
			response => {
				// this.setState({ mathExamList:this.shuffleArray(response) });
				this.setState({ mathExamList:response });
			},
			error => {
				console.log(error);
			}
		);

		
		// Get Mathematical Exam
		ExamService.getAnalExam().then(
			response => {
				// this.setState({ analyticExamList:this.shuffleArray(response) });
				this.setState({ analyticExamList:response});
			},
			error => {
				console.log(error);
			}
		);

		// Get Mathematical Exam
		ExamService.getSpeedExam().then(
			response => {
				// this.setState({ speedaccuExamList:this.shuffleArray(response) });
				this.setState({ speedaccuExamList:response});
			},
			error => {
				console.log(error);
			}
		);

		if (currentUserData === null) {
			this.setState({ redirect: "/home" });
		} else {
			const currentUser = currentUserData.data;
			this.setState({ currentUser: currentUser, userReady: true })
		}
	}

	// shuffleArray = (array) => {
	// 	let i = array.length - 1;
	// 	for (; i > 0; i--) {
	// 	  const j = Math.floor(Math.random() * (i + 1));
	// 	  const temp = array[i];
	// 	  array[i] = array[j];
	// 	  array[j] = temp;
	// 	}
	// 	return array;
	//   }

	// Renderer callback with condition
	renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
			console.log('complete step');
			this.handleNext();
			return <span id="countDown" style={{'float': 'right',marginRight: '15px', fontSize: '35px', position:'fixed',right:'126px'}}><i className="fas fa-clock" style={{fontSize: '30px',color: 'cornflowerblue'}}></i>&nbsp;{hours}:{minutes}:{seconds}</span>;
		} else {
			// Render a countdown
			return <span id="countDown" style={{'float': 'right',marginRight: '15px', fontSize: '35px', position:'fixed',right:'126px'}}><i className="fas fa-clock" style={{fontSize: '30px',color: 'cornflowerblue'}}></i>&nbsp;{hours}:{minutes}:{seconds}</span>;
		}
	}

	onChange = (event) => {	
		const { data } = this.state;
		data[event.target.name] = event.target.value;
		this.setState({ data });
	}

	submit = () => {
		this.form.submit();
	}	

	handleSubmit = () => {
		const ICATtime = document.getElementById('countDown').innerText;
		const curUser = JSON.parse(localStorage.getItem('user'));
		ExamService.calculateExamResult(
			JSON.stringify(this.state.selectedValue),
			curUser.data.encode_name,
			this.state.speedAccu,
			ICATtime
		).then(
		response => {
				if (response.status) {
					this.props.history.push("/exam-result");
				}
			}, error => {
				const resMessage = error.response.data;
				console.log(resMessage);
			}
		);
		this.setState({ submitted: true }, () => {
			setTimeout(() => this.setState({ submitted: false }), 5000);
		});
	}

	prevStep = () => {
		let { step } = this.state;
		if (step > 1) {
			step--;
		}
		this.setState({ step });
	}

	nextStep = () => {	
		let { step } = this.state;
		this.setState({ open: true});
		this.setState({ step });
	}

	handleClose = () => {
		this.setState({ verbalNext: false, open: false});
	}

	handleNext = () => {
		if (this.state.step === 1) {
			const speedAccu = document.getElementById('countDown').innerText;
			this.setState({disabled_step: 2, open: false, step: this.state.step+1, disabled: true, speedAccu: speedAccu});
		} else if (this.state.step === 2) {
			this.setState({disabled_step: 3, open: false, step: this.state.step+1, disabled: true});
		} else if (this.state.step === 3) {
			this.setState({disabled_step: 4, open: false, step: this.state.step+1, disabled: true});
		}
		console.log(this.state.step);
	}

	validatorListener = (result) => {
		this.setState({ disabled: !result });
	}

	renderAnswer(examid){
		const API_URL = "http://localhost/applicantapi/api/verbalexam/"+examid;
		axios({
			method: "get",
			url: API_URL,
		})
		.then(response => {
			localStorage.setItem("examanswer", JSON.stringify(response.data));
		})
	}

	// selected answer in every question
	onChangeAnswer = (event) => {
		const { name, value } = event.target;

		const { data } = this.state;
		data[name] = value;
		this.setState({
			selectedValue: data,
		});
		console.log(this.state.selectedValue);
		if (name > 74 || name < 99) {
			this.setState({
				disabled: false
			});
		} else if (name > 0 || name < 25) {
			this.setState({
				disabled: false
			});
		}
		return false;
	}

	analyticExamChange = (list, key) => {
		if (
			list.examid === '66' || list.examid === '67' || 
			list.examid === '68' || list.examid === '69' || 
			list.examid === '70' || list.examid === '71' ||
			list.examid === '72' || list.examid === '73' || 
			list.examid === '74') {
				return ('')
			} else {
				return ( 
					<Grid item xs={3}>
						<input type="radio" 
						value={'d_'+list.exam_category}
						onChange={this.onChangeAnswer}
						name={list.examid}/>
						&nbsp; {list.a4}
					</Grid> )
			}
	}

	storyBoard = (story, id) => {
		if(	id === '52' || id === '54' || 
			id === '56' || id === '58' || 
			id === '59' || id === '60' || 
			id === '61' || id === '62' || 
			id === '63' || id === '65' ||
			id === '66' || id === '64' ||
			id === '67' || id === '69' ||
			id === '70' || id === '71' || 
			id === '72' || id === '73' ||
			id === '74' || id === '68') {
			const st = story.split('_');
			var sto = [];
			for(let i=0; i<st.length;i++) {
				sto.push(<div key={i}>{st[i]}<br/></div>);
			}
			return (<Grid item xs={12} style={{textAlign:'justify'}}>
						{ sto }
					</Grid>)
		} else {
			return (<Grid item xs={12} style={{textAlign:'justify'}}>
						{story}
					</Grid>)
		}
	}

	imageQuestion = (examid) => {
		if ( examid === '33' ) {
			return (<div><img src={q9} alt="img"/></div>)
		} else if ( examid === '34') {
			return (<div><img src={q10} alt="img"/></div>)
		} else if ( examid === '35') {
			return (<div><img src={q11} alt="img"/></div>)
		} else if ( examid === '36') {
			return (<div><img src={q12} alt="img"/></div>)
		} else if ( examid === '37') {
			return (<div><img src={q13} alt="img"/></div>)
		} else if ( examid === '39') {
			return (<div><img src={q14} alt="img"/></div>)
		} else if ( examid === '40') {
			return (<div><img src={q15} alt="img"/></div>)
		} else if ( examid === '41') {
			return (<div><img src={q16} alt="img"/></div>)
		}
	}
	
	

  renderStep = () => {
		const { step } = this.state;
		let content = null;
		switch (step) {
			case 2:
				content = (
					<div>
						<Typography component="h4" variant="h5" style={{opacity:'.5'}}>
							VERBAL INTELLIGENCE
						</Typography>
						
						<hr />
						<Grid container spacing={2}>
							{this.state.verbalExamList.map((list,key) => {
								return(
									<Grid item xs={12} key={key}>
										<Paper key={key} style={{width: "100%", marginTop: '15px', padding: '10px'}} variant="outlined">
											<Grid container spacing={2}  key={list.examid}>
												<Grid item xs={12}>
												{key + 1}. &nbsp;{list.question}
												</Grid>
												<Grid item xs={3}>
													<input type="radio" 
													value={'a_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a1}
												</Grid>
												<Grid item xs={3}>
													<input type="radio" 
													value={'b_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a2}
												</Grid>
												<Grid item xs={3}>
													<input type="radio" 
													value={'c_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a3}
												</Grid>
												<Grid item xs={3}>
													<input type="radio" 
													value={'d_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a4}
												</Grid>
											</Grid>
										</Paper>
									</Grid>
								)
							})}
						</Grid>
					</div>
				);
				break;
			case 3:
				content = (
					<div>
						<Typography component="h4" variant="h5" style={{opacity:'.5'}}>
							MATHEMATICAL INTELLIGENCE
						</Typography>
						
						<hr />
						<Grid container spacing={2}>
							{this.state.mathExamList.map((list,key) => {
								return(
									<Grid item xs={12} key={list.examid}>
										<Paper key={key} style={{width: "100%", marginTop: '15px', padding: '10px'}}  variant="outlined">
											<Grid container spacing={2} >
												<Grid item xs={12}>
												{key + 1}. &nbsp;{list.question}
												{this.imageQuestion(list.examid)}
												</Grid>
												<Grid item xs={3}>
													<input type="radio" 
													value={'a_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a1}
												</Grid>
												<Grid item xs={3}>
													<input type="radio" 
													value={'b_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a2}
												</Grid>
												<Grid item xs={3}>
													<input type="radio" 
													value={'c_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a3}
												</Grid>
												<Grid item xs={3}>
													<input type="radio" 
													value={'d_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a4}
												</Grid>
											</Grid>
										</Paper>
									</Grid>
								)
							})}
						</Grid>
					</div>
				);
				break;
			case 4:
				content = (
					<div>
						<Typography component="h4" variant="h5" style={{opacity:'.5'}}>
							ANALYTICAL INTELLIGENCE
						</Typography>
						
						<hr />
						<Grid container spacing={2}>
							{this.state.analyticExamList.map((list,key) => {
								return(
									<Grid item xs={12} key={list.examid}>
										<Paper key={key} style={{width: "100%", marginTop: '15px', padding: '10px'}}  variant="outlined">
											<Grid container spacing={2} >
												{/* Story Board */}
												{ this.storyBoard(list.story,list.examid) }
												<Grid item xs={12} style={{textAlign:'justify'}}>
												{key + 1}. &nbsp;{list.question}
												</Grid>
												<Grid item xs={3}>
													<input type="radio" 
													value={'a_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}
													id={list.examid}/>
													&nbsp; {list.a1}
												</Grid>
												<Grid item xs={3}>
													<input type="radio" 
													value={'b_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a2}
												</Grid>
												<Grid item xs={3}>
													<input type="radio" 
													value={'c_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a3}
												</Grid>
												{this.analyticExamChange(list, key)}
											</Grid>
										</Paper>
									</Grid>
								)
							})}
						</Grid>
					</div>
				);
				break;
				case 1:
				content = (
					<div>
						<Typography component="h4" variant="h5" style={{opacity:'.5'}}>
							SPEED AND ACCURACY
						</Typography>
						
						<hr />
						<Grid container spacing={2}>
							{this.state.speedaccuExamList.map((list,key) => {
								return(
									<Grid item xs={12} key={list.examid}>
										<Paper key={key} style={{width: "100%", marginTop: '15px', padding: '10px'}}  variant="outlined">
											<Grid container spacing={2}>
												<Grid item xs={12} style={{textAlign:'justify'}}>
												{key + 1}. &nbsp;{list.question}
												</Grid>
												<Grid item xs={3}>
													<input type="radio" 
													value={'1_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a1}
												</Grid>
												<Grid item xs={3}>
													<input type="radio" 
													value={'2_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a2}
												</Grid>
												<Grid item xs={2}>
													<input type="radio" 
													value={'3_'+list.exam_category}
													onChange={this.onChangeAnswer}
													name={list.examid}/>
													&nbsp; {list.a3}
												</Grid>
												<Grid item xs={2}>
													<input type="radio" 
														value={'4_'+list.exam_category}
														onChange={this.onChangeAnswer}
														name={list.examid}/>
													&nbsp; {list.a4}
												</Grid>
												<Grid item xs={2}>
													<input type="radio" 
														value={'4_'+list.exam_category}
														onChange={this.onChangeAnswer}
														name={list.examid}/>
													&nbsp; {list.a5}
												</Grid>
											</Grid>
										</Paper>
									</Grid>
								)
							})}
						</Grid>
					</div>
				);
				break;
			default: 
			content = (<div></div>);
		}
		return content;
	}

  render() {
    const { step, disabled, submitted, disabled_step, timeSA, timeICAT } = this.state;
    return (
      <div className="container">
        {(this.state.userReady) ?
		<div style={{paddingTop:'70px'}}>
			<Dialog
				open={this.state.open}
				onClose={this.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Submit Confirmation"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you proceed next exam, if you click NEXT you cannot go back to the previous questions, Please check again your answer and Click next. GOODLUCK.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose}>Review Again</Button>
					<Button onClick={this.handleNext} autoFocus>
						Proceed Next
					</Button>
				</DialogActions>
			</Dialog>
		{!this.state.successful && (	
		<div>
		<Typography component="h1" variant="h5" style={{textAlign:'center'}}>
			EXAM
        </Typography>
		
		<Typography component="h1" variant="h5" style={{textAlign:'center', 'opacity':'.5', fontSize: '15px',
			marginBottom: '10px'
		}}>
			BEGIN HERE
        </Typography>
		 { step === 1 && (
			<Countdown
				date={timeSA}
				renderer={this.renderer}
			/> 
		 )} 
		 { step !== 1 && ( 
			<Countdown
				date={timeICAT}
				renderer={this.renderer}
			/> 
		 )} 
		<Paper style={{padding: '10px', marginBottom: '20px'}}>
			<ValidatorForm
				ref={(r) => { this.form = r; }}
				onSubmit={this.handleSubmit}
				instantValidate
			>
				{this.renderStep()}
				<br />
				<Button
					onClick={this.prevStep}
					style={{ marginRight: '16px' }}
					disabled={step === disabled_step}
				>
					previous
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={step < 4 ? this.nextStep : this.submit}
					disabled={disabled || submitted}
				>
					{
						(submitted && 'CALCULATING EXAM RESULT, PLEASE WAIT...')
						|| (step < 4 ? 'Next' : 'Submit')
					}
				</Button>
			</ValidatorForm>
		</Paper>
		</div> )}
      </div>: null}

      </div>
    );
  }
}
