import React, { Component } from "react";
import AuthService from "../../services/auth.service";
import JobsService from "../../services/job.service";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { List } from 'react-content-loader';

import "../Component.css";

import * as moment from 'moment';
import { Link } from "react-router-dom";

export default class AppliedJobs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			redirect: null,
			userReady: false,
			currentUser: null,
			jobs: [],
			filter: "",
			jobsList: false,
			loading: false
		};
	}

    handleChange = (event) => {
		this.setState({ filter: event.target.value });
    }

	componentDidMount() {
		// if(localStorage.getItem('openNav') === '0') {
		// 	document.getElementById("jobPortalContainer").style.paddingLeft = "0px";
		// } else {
		// 	document.getElementById("jobPortalContainer").style.paddingLeft = "145px";
		// }
		sessionStorage.setItem('jobListType',2);
		const currentUserData = AuthService.getCurrentUser();
		if (currentUserData === null) {
			this.setState({ redirect: "/dashboard" });
		} else {
			this.setState({loading: true});
			const id = currentUserData.data.encode_name;
			JobsService.getJobsApplied(id,1).then(
				response => {
					console.log(response);
					if (response.status) {
						const jobs = response.data;
						this.setState({jobs, jobsList: true, loading: false});
					}
				},
				error => {
					this.setState({jobsList: false});
				}
			);
			this.setState({ currentUser: currentUserData.data, userReady: true })
		}
	}
	// filter the required options
	required(req) {
		const qualif = JSON.parse(req);
		var reqdesc = [];
		qualif.forEach(element => {
			var withEx = 'Year';
			if(element.match(new RegExp(`${withEx}`, 'gi'))){
				var sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(224, 57, 151)'
					}}></i>
			}

			if(element.match(new RegExp(`No`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(242, 113, 28)'
					}}></i>
			}

			if(element.match(new RegExp(`High`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(251, 189, 8)'
					}}></i>
			}

			if(element.match(new RegExp(`years`, 'gi'))){
				sprite = <i className="fas fa-circle" style={{
					fontSize: '11px',
					color: 'rgb(33, 186, 69)'
					}}></i>
			}

			reqdesc.push(<div key={element}><span style={{fontSize: '13px'}}>
							{sprite} &nbsp;
							{element}
						</span><br /></div>)
		});
		return reqdesc;
	}

	render() {
		const { filter, jobs } = this.state;
		const lowercasedFilter = filter.toString().toLowerCase();
		const filteredData = jobs.filter(item => {
			return Object.keys(item).some(key =>
				// not continue if null value
				item[key].toString().toLowerCase().includes(lowercasedFilter)
			);
		});
		return (
			<div className="container" id="jobPortalContainer" style={{marginBottom: '30px'}}>
			{
				(this.state.userReady) ?
					<div style={{paddingTop:'50px'}}>
							<Typography component="h1" variant="h5">
								Jobs
							</Typography>
							<br />
							<TextField 
								label="Search job"
								onChange={this.handleChange}
								name="search_job"
								value={filter}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								fullWidth
								style={{marginTop:'-35px'}}
							/>
							{
								this.state.loading? 
								<div>
									{!this.state.jobsList && (
										<div>No applied jobs found.</div>
									)}
									<br/><List /><br/>
								</div>
							:<div>
							{!this.state.jobsList && (
								<div>No applied jobs found.</div>
							)}
							{this.state.jobsList && (
							<div>
							{filteredData.map((job,key) => {
								return(
									<Link to={`/jobview/${job.id}`} key={key} style={{textDecoration: 'none'}}>
										<Paper className="jobList" key={job.id} style={{width: "100%", marginTop: '15px'}} elevation={3}>
											<Grid item xs={12} style={{padding: "10px"}} key={job.id}>
												<Grid container spacing={2} >
													<Grid item xs={3}>
														<span style={{
															fontSize: '20px',
															fontWeight: '600',
															color:'dodgerblue'
														}}>{job.job_position}</span>
														<br />
														{this.required(job.job_required)}
													</Grid>
													<Grid item xs={3}>
														<i className="fas fa-check-circle" style={{color:'green'}}></i>&nbsp;
														<span style={{fontWeight: 'bold',fontSize: '17px'}}>{job.company}</span>
														<span style={{display:'block',fontSize: '13px',color: 'dodgerblue', opacity: '.5'}}>{job.employment_type}</span>
													</Grid>
													<Grid item xs={3}>
													<i className='fas fa-map-marker-alt'></i> {job.city}<br/>{job.street}
													</Grid>
													<Grid item xs={3}>
														<i className="fas fa-clock" style={{color:'royalblue'}}></i> 
														&nbsp;{moment(job.created).format('MMM DD YYYY')}
														<span style={{ display: 'block',fontSize: '0.66em', opacity:'.5'}}>{moment(job.created).startOf('hour').fromNow()}</span>
													</Grid>
												</Grid>
											</Grid>
										</Paper>
									</Link>
								)
							})}
							</div>
							)}
							</div>
						}
					</div>
				: null
			}
			</div>
		);
	}
}

