import axios from "axios";
// const baseUrl = "http://180.232.152.179:70";
const baseUrl = "https://f25426ad5f6c6cf7.ngrok.app";
const JOBS_API_URL = baseUrl+"/applicantapi/api/jobs";
const JOBS_APLCT = baseUrl+"/applicantapi/api/jobsapplied";
const JBAPLICIDVIEW = baseUrl+"/applicantapi/api/jobsforapplicant";
const APPLICANT_JOB_APPLIED_LIST = baseUrl+"/applicantapi/api/applicantjobapplied";
const WALK_IN = baseUrl+"/applicantapi/api/walkin/";
const APPLICANTALLJOB = baseUrl+"/applicantapi/api/applicantalljobs";
const JOB_CATEGORY = baseUrl+"/applicantapi/api/jobcategory";


class JobsService {
	addNewJob(jobid, position, vacancy, street, city, details, qualifications, employment_type, company, availability, status , created, assgnmnt_area, job_category, incharge_email) {
		var bodyFormData = new FormData();
		bodyFormData.append('job_id', jobid);
		bodyFormData.append('job_position', position);
		bodyFormData.append('vacancy', vacancy);
		bodyFormData.append('street', street);
		bodyFormData.append('city', city);
		bodyFormData.append('job_required',qualifications);
		bodyFormData.append('job_details', details);
		bodyFormData.append('employment_type', employment_type);
		bodyFormData.append('company', company);
		bodyFormData.append('status', status);
		bodyFormData.append('created', created);
		bodyFormData.append('availability', availability);
		bodyFormData.append('assgnmnt_area', assgnmnt_area);
		bodyFormData.append('job_category', job_category);
		bodyFormData.append('incharge_email', incharge_email);

		return axios({
			method: "post",
			url: JOBS_API_URL + "/index_post",
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
		.then(response => {
			console.log(response);
			return response.data;
		});
	}

	addJob(jobid, position, vacancy, street, city, details, qualifications, employment_type, company, availability, assgnmnt_area) {
		var bodyFormData = new FormData();
		bodyFormData.append('job_id', jobid);
		bodyFormData.append('job_position', position);
		bodyFormData.append('vacancy', vacancy);
		bodyFormData.append('street', street);
		bodyFormData.append('city', city);
		bodyFormData.append('job_required',qualifications);
		bodyFormData.append('job_details', details);
		bodyFormData.append('employment_type', employment_type);
		bodyFormData.append('company', company);
		bodyFormData.append('availability', availability);
		bodyFormData.append('assgnmnt_area',assgnmnt_area);

		return axios({
			method: "post",
			url: JOBS_API_URL + "/index_post",
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
		.then(response => {
			console.log(response);
			return response.data;
		});
	}

	getjobByID(id, userid, ident) {
		return axios.get(JOBS_API_URL,{
			params: {
				id: id,
				userid:userid,
				ident: ident
			}
		})
		.then(res => {
			return res.data;
		});
	}

	getJobCategory() {
		return axios.get(JOB_CATEGORY)
		.then(res => {
			return res.data;
		});
	}

	// get job by id for viewing only
	getjobByIDForApplicant(id) {
		return axios.get(JBAPLICIDVIEW,{
			params: {
				id: id
			}
		})
		.then(res => {
			return res.data;
		});
	}

	// get applied Job
	getAppliedJob(id) {
		return axios.get(APPLICANT_JOB_APPLIED_LIST,{
			params: {
				id: id
			}
		})
		.then(res => {
			return res.data;
		});
	}

	//get all jobs list
	getallJobs() {
		return axios.get(JOBS_API_URL)
		.then(res => {
			return res.data;
		})
	}

	//get all jobs for applicant
	getApplicantJobs(id) {
		return axios.get(APPLICANTALLJOB, {
			params: {
				jobCatId: id
			}
		})
		.then(res => {
			return res.data;
		})
	}

	//get all jobs list
	getallWalkinApplicants() {
		return axios.get(WALK_IN)
		.then(res => {
			// console.log(res.data);
			return res.data;
		})
	}

	//get list of applied jobs
	getJobsApplied(id,ident) {
		return axios.get(JOBS_APLCT,{
			params: {
				id: id,
				ident: ident
			}
		})
		.then(res => {
			return res.data;
		});
	}

	// apply job function
	applyJob(jobid, userID) {
		var bodyFormData = new FormData();
		bodyFormData.append('job_id', jobid);
		bodyFormData.append('user_id', userID);

		return axios({
			method: "post",
			url: JOBS_APLCT,
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
		.then(response => {
			return response.data;
		})
	}

}

export default new JobsService();
