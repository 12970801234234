import React, { Component } from "react";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
// import { Redirect } from "react-router-dom";
import Button from '@material-ui/core/Button';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
// import Bg from "../image/whats-new.jpg";
// import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
// import { Editor } from "react-draft-wysiwyg";
// , convertToRaw 
import { EditorState} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// import * as moment from 'moment';

// import AuthService from "../services/auth.service";

import JobsService from "../../services/job.service";

export default class AddAdmin extends Component {
  constructor(props) {
    super(props);
	this.state = {editorState: EditorState.createEmpty()};
    this.state = {
        formData: {
            username: '',
            firstname: '',
            lastname: '',
            middlename: '',
            birthday:'',
            email: '',
            password: '',
            repeatPassword: ''
        },
        submitted: false,
        loading: false,
        message: "",
		checked: true,
		disableDetails: false
    }
  }
  
  componentDidMount() {
	// custom rule will have name 'isPasswordMatch'
	ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
		if (value !== this.state.formData.password) {
			return false;
		}
		return true;
	});

	// custom rule will have name 'validate Age'
	ValidatorForm.addValidationRule('isAge', (value) => {
		if (this.getAge(value) < 18) {
			return false;
		}
		return true;
	});
  }

	handleChange = (event) => {
		const { formData } = this.state;
		formData[event.target.name] = event.target.value;
		console.log(formData);
		this.setState({ formData });
	}

	// handleChangeChecked = (event) => {
	// 	const { formData } = this.state;
	// 	formData[event.target.name] = event.target.checked;
	// 	console.log(formData);
	// 	if(formData.High || formData.More || formData.No || formData.Year) {
	// 		this.setState({checkedData: true});
	// 	} else {
	// 		this.setState({checkedData: false});
	// 	}
	// }

	// onChange = (editorState) => {
	// 	const contentState = editorState.getCurrentContent();
	// 	console.log('content state', convertToRaw(contentState));
	// 	if(contentState.hasText()) {
	// 		this.setState({disableDetails: true});
	// 	} else {
	// 		this.setState({disableDetails: false});
	// 	}
	// 	this.saveContent(contentState);
	// 	this.setState({
	// 		editorState,
	// 	});
	// }

	// saveContent = (content) => {
	// 	// window.localStorage.setItem('content', JSON.stringify(convertToRaw(content)));
	// 	this.setState({job_details: JSON.stringify(convertToRaw(content))})
	// }

	handleSubmit = () => {
		this.setState({
			resMessage: "",
			loading: true
		});
		this.setState({ submitted: true }, () => {
			JobsService.addNewJob(
				this.state.formData.job_position,
				this.state.formData.vacancy,
				this.state.formData.street,
				this.state.formData.city,
				this.state.job_details,
				this.state.formData.High,
				this.state.formData.More,
				this.state.formData.No,
				this.state.formData.Year,
				this.state.formData.employment_type,
				this.state.formData.company
			).then(
			response => {
					console.log(response);
					if (response.status) {
						setTimeout(() => 
							this.setState({ 
								message: response.message,
								successful: true,
								loading: false
							}), 3000);
							setTimeout(() => 
							this.setState({ 
								successful: false
							}), 4000);
							setTimeout(() => 
							this.props.history.push("/jobs"), 4500);
					}
				}, error => {
					const resMessage = error.response.data;
					console.log(resMessage);
					// this.setState({
					// 	successful: false,
					// 	message: resMessage,
					// 	loading: false
					// });
				}
			);
			setTimeout(() => 
			this.setState({ submitted: false }), 5000);
		});
		}

	closeDiv = () => {
		this.props.history.goBack();
	}

  render() {
    const { formData, submitted } = this.state; //, checkedData, disableDetails
    return (
		<div className="container" id="createjobContainer" style={{paddingTop:'45px',paddingBottom: '10px'}}>
			<Paper style={{maxWidth: '500px !important' }}>
				<span onClick={this.closeDiv } style={{color: '#0a0d0a',float: 'right',paddingRight: '10px',fontSize: '21px', cursor: 'pointer'}}><i className="fas fa-times"></i></span>
				<ValidatorForm
					ref="form"
					onSubmit={this.handleSubmit}
					style={{padding: '10px'}}
				>
					<Typography component="h1" variant="h5" style={{textAlign:'center'}}>
						New Admin
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<TextValidator
								label="Email"
								onChange={this.handleChange}
								name="email"
								value={formData.email}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
								validators={['required', 'isEmail']}
								errorMessages={['Email is required', 'Email is not valid']}
								fullWidth
							/>
						</Grid>
						<Grid item xs={6}> 
							<label style={{fontSize: '10px',opacity: '.5'}}> Birthday </label>
							<TextValidator
								// label="Birthday"
								onChange={this.handleChange}
								name="birthday"
								type="date"
								value={formData.birthday}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['isAge','required']}
								placeholder="none"
								errorMessages={['Required age must be 18 years old.','Birthday is required']}
								fullWidth
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={4}> 
							<TextValidator
								label="Firstname"
								onChange={this.handleChange}
								name="firstname"
								value={formData.firstname}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['required']}
								errorMessages={['Firstname is required']}
								fullWidth
							/>
						</Grid>
						<Grid item xs={4}> 
							<TextValidator
								label="Middlename"
								onChange={this.handleChange}
								name="middlename"
								value={formData.middlename}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['required']}
								errorMessages={['Middlename is required']}
								fullWidth
							/>
						</Grid>
						<Grid item xs={4}> 
							<TextValidator
								label="Last Name"
								onChange={this.handleChange}
								name="lastname"
								value={formData.lastname}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['required']}
								errorMessages={['Lastname is required']}
								fullWidth
							/>
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={6}> 
							<TextValidator
								label="Password"
								onChange={this.handleChange}
								name="password"
								type="password"
								value={formData.password}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['required']}
								errorMessages={['Password is required']}
								fullWidth
							/>
						</Grid>
						<Grid item xs={6}>
							<TextValidator
								label="Repeat Password"
								onChange={this.handleChange}
								name="repeatPassword"
								type="password"
								value={formData.repeatPassword}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
								validators={['required','isPasswordMatch']}
								errorMessages={['Password is required', 'Password not matched.']}
								fullWidth
							/>
						</Grid>
					</Grid>
					{this.state.successful && (
						<div>
							<br/>
							<div className="form-group">
								<div
									className={
										this.state.successful
										? "alert alert-success"
										: "alert alert-danger"
									}
									role="alert"
								>
								{this.state.message}
								</div>
							</div>
						</div>
					)}

					<div className="form-group" style={{marginTop: '-10px', marginBottom: '10px'}}>
						<Button
							color="primary"
							variant="contained"
							type="submit"
							disabled={submitted}
							style={{ 'width': '100%', marginTop: '30px'}}>

							{this.state.loading && (
								<span className="spinner-border spinner-border-sm"></span>
							)}

							{
								(submitted && ' Creating Account..')
								|| (!submitted && 'SAVE')
							}
						</Button>
						<br />
						{this.state.message && (
							<div className="form-group" style={{marginTop: '10px'}}>
							<div className="alert alert-danger" role="alert">
								{this.state.message}
							</div>
							</div>
						)}
					</div>

					{/* <div className="form-group" style={{marginTop: '-10px'}}>
					{  disableDetails && (
						<div>
						{ checkedData && (
						<Button
							color="primary"	
							variant="contained"
							type="submit"
							disabled={submitted}
							style={{ 'width': '100%', marginTop: '30px'}}
						>
							{this.state.loading && (
								<div><span className="spinner-border spinner-border-sm"></span>&nbsp;</div>
							)}

							{
								(submitted && ' Saving new job..')
								|| (!submitted && 'Save New Job ')
							}
						</Button>
						)}
						</div>
					)}
					</div> */}
					</ValidatorForm>
			</Paper>
		</div>
		);
  }
}
