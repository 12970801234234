import React,{ useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useHistory } from "react-router-dom"
// import Layout from "../components/Layout"
  
function Login2() {
    const navigate = useHistory();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [validationErrors, setValidationErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
 
    useEffect(()=>{
        if(localStorage.getItem('token') != "" && localStorage.getItem('token') != null){
            navigate("/dashboard");
        }
        console.log(localStorage.getItem('token'))
    },[])
 
    const loginAction = (e) => {
        setValidationErrors({})
        e.preventDefault();
        setIsSubmitting(true)
        let payload = {
            email:email,
            password:password,
        }
        const baseUrl = "https://cgsijobs.ngrok.io";
        axios.post(baseUrl+'/index_post', payload)
        .then((r) => {
            setIsSubmitting(false)
            localStorage.setItem('token', r.data.token)
            navigate("/dashboard");
        })
        .catch((e) => {
            setIsSubmitting(false)
            if (e.response.data.errors != undefined) {
                setValidationErrors(e.response.data.errors);
            }
            if (e.response.data.error != undefined) {
                setValidationErrors(e.response.data.error);
            }
        });
    }
 
     
    return (
		<div className="row justify-content-md-center mt-5">
			<div className="col-4">
				<div className="card">
					<div className="card-body">
						<h5 className="card-title mb-4">Sign In</h5>
						<form onSubmit={(e)=>{loginAction(e)}}>
							{Object.keys(validationErrors).length != 0 &&
									<p className='text-center '><small className='text-danger'>Incorrect Email or Password</small></p>
							}
							
							<div className="mb-3">
								<label 
									htmlFor="email"
									className="form-label">
										Email address
								</label>
								<input 
									type="email"
									className="form-control"
									id="email"
									name="email"
									value={email}
									onChange={(e)=>{setEmail(e.target.value)}}
								/>
							</div>
							<div className="mb-3">
								<label 
									htmlFor="password"
									className="form-label">Password
								</label>
								<input 
									type="password"
									className="form-control"
									id="password"
									name="password"
									value={password}
									onChange={(e)=>{setPassword(e.target.value)}}
								/>
							</div>
							<div className="d-grid gap-2">
								<button 
									disabled={isSubmitting}
									type="submit"
									className="btn btn-primary btn-block">Login</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
    );
}
   
export default Login2;



// import React, { Component } from "react";
// import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import Link from '@material-ui/core/Link';
// import Grid from '@material-ui/core/Grid';
// import Logo from "../image/cgsilogo.png";
// import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
// import Bg from "../image/about-us.jpg";

// import './Component.css';

// import AuthService from "../services/auth.service";

// export default class Login extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//         formData: {
//             email: '',
//             password: '',
//         },
//         container: {
//             backgroundImage: `url(${Bg})`,
//             backgroundPosition: 'center',
//             backgroundSize: 'cover',
//             backgroundRepeat: 'no-repeat',
//             position: 'absolute',
//             width: '100vw',
//             height: '100%',
//             left: '0px',
//             top: '0px'
//         },
//         submitted: false,
//         loading: false,
//         message: ""
//     }

//   }

//   componentDidMount() {
//     localStorage.clear();
//   }

//   handleChange = (event) => {
//     const { formData } = this.state;
//     formData[event.target.name] = event.target.value;
//     this.setState({ formData });
//   }

//   handleSubmit = () => {
//       this.setState({ submitted: true }, () => {
//         this.setState({
//           resMessage: "",
//           loading: true
//         });

//         AuthService.login(this.state.formData.email, this.state.formData.password).then(
//           response => {
//             if (response.data.role === '1') {
//               this.props.history.push("/applicantjobs");
//             } else {
//               this.props.history.push("/dashboard");
//             }
//             window.location.reload();
//           },
//           error => {
//             console.log(error);
//             const resMessage = error.response.data;
//             this.setState({
//               loading: false,
//               message: resMessage
//             });
//           }
//         );
//           setTimeout(() => 
//           this.setState({ submitted: false, message:'' }), 5000);
//       });
//   }

//   render() {
//     const { formData, submitted } = this.state;
//     return (
//       <div className="col-md-12" style={this.state.container} >
//         <div className="card card-container" style={{borderRadius:'0px'}}>
//           <img src={Logo} alt="website logo" style={{"width": "80px",marginLeft: '40%'}}/>
//             <ValidatorForm
//                   ref="form"
//                   onSubmit={this.handleSubmit}
//             >
//                 <Typography component="h1" variant="h5" style={{textAlign:'center'}}>
//                   Login
//                 </Typography>

//                 <div className="form-group">
//                   <TextValidator
//                       label="Email"
//                       onChange={this.handleChange}
//                       name="email"
//                       value={formData.email}
//                       inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
//                       validators={['required', 'isEmail']}
//                       errorMessages={['Email is required', 'Email is not valid']}
//                       autoFocus
//                       fullWidth
//                   />
//                 </div><br />
//                 <div className="form-group" style={{marginTop: '-10px'}}>
//                   <TextValidator
//                       label="Password"
//                       onChange={this.handleChange}
//                       name="password"
//                       type="password"
//                       value={formData.password}
//                       inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
//                       validators={['required']}
//                       errorMessages={['Password is required']}
//                       fullWidth
//                   />
//                 </div>
//                 <div className="form-group" style={{marginTop: '-10px'}}>
//                   <Button
//                       color="primary"
//                       variant="contained"
//                       type="submit"
//                       disabled={submitted}
//                       className="registerAccountBtn"
//                       style={{ 'width': '100%', marginTop: '25px'}}>

//                       {this.state.loading && (
//                         <div><span className="spinner-border spinner-border-sm"></span>&nbsp;</div>
//                       )}

//                       {
//                           (submitted && 'Logging In..')
//                           || (!submitted && 'Login')
//                       }
//                   </Button>
//                   <br />
//                   {this.state.message && (
//                     <div className="form-group" style={{marginTop: '10px'}}>
//                       <div className="alert alert-danger" role="alert">
//                         {this.state.message}
//                       </div>
//                     </div>
//                   )}
//                   <Grid container>
//                     <Grid item xs>
//                       <Link href="#" variant="body2">
//                         Forgot password?
//                       </Link>
//                     </Grid>
//                     <Grid item>
//                       {"Don't have an account? "}
//                       <Link href="/register" variant="body2">
//                         {" Sign Up"}
//                       </Link>
//                     </Grid>
//                   </Grid>
//                 </div>
//             </ValidatorForm>
//         </div>
//       </div>
//     );
//   }
// }
