import React, { Component } from "react";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
// import { Redirect } from "react-router-dom";
import Button from '@material-ui/core/Button';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
// import Bg from "../image/whats-new.jpg";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// import AuthService from "../services/auth.service";

import JobsService from "../../services/job.service";

export default class AddApplicant extends Component {
  constructor(props) {
    super(props);
	this.state = {editorState: EditorState.createEmpty()};
    this.state = {
        formData: {
            job_position: '',
			company: '',
            vacancy: '',
            job_details: '',
			employment_type: '',
            street: '',
            city: '',
			No: false,
			Year: false,
			High: false,
			More: false
        },
        submitted: false,
        loading: false,
        message: "",
		checked: true,
		disableDetails: false
    }
  }
  
  componentDidMount() {}

	handleChange = (event) => {
		const { formData } = this.state;
		formData[event.target.name] = event.target.value;
		console.log(formData);
		this.setState({ formData });
	}

	handleChangeChecked = (event) => {
		const { formData } = this.state;
		formData[event.target.name] = event.target.checked;
		console.log(formData);
		if(formData.High || formData.More || formData.No || formData.Year) {
			this.setState({checkedData: true});
		} else {
			this.setState({checkedData: false});
		}
	}

	onChange = (editorState) => {
		const contentState = editorState.getCurrentContent();
		console.log('content state', convertToRaw(contentState));
		if(contentState.hasText()) {
			this.setState({disableDetails: true});
		} else {
			this.setState({disableDetails: false});
		}
		this.saveContent(contentState);
		this.setState({
			editorState,
		});
	}

	saveContent = (content) => {
		// window.localStorage.setItem('content', JSON.stringify(convertToRaw(content)));
		this.setState({job_details: JSON.stringify(convertToRaw(content))})
	}

	handleSubmit = () => {
		this.setState({
			resMessage: "",
			loading: true
		});
		this.setState({ submitted: true }, () => {
			JobsService.addNewJob(
				this.state.formData.job_position,
				this.state.formData.vacancy,
				this.state.formData.street,
				this.state.formData.city,
				this.state.job_details,
				this.state.formData.High,
				this.state.formData.More,
				this.state.formData.No,
				this.state.formData.Year,
				this.state.formData.employment_type,
				this.state.formData.company
			).then(
			response => {
					console.log(response);
					if (response.status) {
						setTimeout(() => 
							this.setState({ 
								message: response.message,
								successful: true,
								loading: false
							}), 3000);
							setTimeout(() => 
							this.setState({ 
								successful: false
							}), 4000);
							setTimeout(() => 
							this.props.history.push("/jobs"), 4500);
					}
				}, error => {
					const resMessage = error.response.data;
					console.log(resMessage);
					// this.setState({
					// 	successful: false,
					// 	message: resMessage,
					// 	loading: false
					// });
				}
			);
			setTimeout(() => 
			this.setState({ submitted: false }), 5000);
		});
		}

  render() {
    const { formData, submitted, checkedData, disableDetails } = this.state;
    return (
		<div className="container" id="createjobContainer" style={{paddingTop:'45px',paddingBottom: '10px'}}>
			<Paper style={{maxWidth: '500px !important' }}>
				<ValidatorForm
					ref="form"
					onSubmit={this.handleSubmit}
					style={{padding: '10px'}}
				>
					<Typography component="h1" variant="h5" style={{textAlign:'center'}}>
						Add New Applicant
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12}> 
							<TextValidator
								label="Company"
								onChange={this.handleChange}
								name="company"
								value={formData.company}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['required']}
								errorMessages={['Company is required']}
								autoFocus
								fullWidth
								style={{marginTop:'-20px'}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={6}> 
							<TextValidator
								label="Position"
								onChange={this.handleChange}
								name="job_position"
								value={formData.job_position}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['required']}
								errorMessages={['Position is required']}
								fullWidth
								style={{marginTop:'-15px'}}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextValidator
								label="Vacancy"
								onChange={this.handleChange}
								name="vacancy"
								value={formData.vacancy}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
								validators={['required']}
								errorMessages={['Vacancy is required']}
								fullWidth
								type="number"
								style={{marginTop:'-15px'}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={6}> 
							<label>Qualifications</label>
							<input 
								onChange={this.handleChangeChecked}
								name="No"
								// value={formData.req1}
								type="checkbox" 
							/> <i className="fas fa-circle" style={{
								fontSize: '11px',
								color: 'rgb(242, 113, 28)'
								}}></i> No Experience &nbsp;
							<input 
								onChange={this.handleChangeChecked}
								name="Year"
								// value={formData.req2}
								type="checkbox" 
							/> <i className="fas fa-circle" style={{
								fontSize: '11px',
								color: 'rgb(224, 57, 151)'
								}}></i> 1 Year or less &nbsp;
							<input 
								onChange={this.handleChangeChecked}
								name="High"
								// value={formData.req3}
								type="checkbox" 
							/> <i className="fas fa-circle" style={{
								fontSize: '11px',
								color: 'rgb(251, 189, 8)'
								}}></i> High School Graduate are welcome &nbsp;	
							<input 
								onChange={this.handleChangeChecked}
								name="More"
								// value={formData.req4}
								type="checkbox" 
							/> <i className="fas fa-circle" style={{
								fontSize: '11px',
								color: 'rgb(33, 186, 69)'
								}}></i> 2 to 4 years	
						</Grid>
						<Grid item xs={6}>
							<TextValidator
									name="employment_type"
									label="Employment Type"
									select
									value={formData.employment_type}
									InputLabelProps={{ shrink: true }}
									validators={['required']}
									errorMessages={['Employment Type is required.']}
									onChange={this.handleChange}
									validatorListener={this.validatorListener}
									fullWidth
									style={{marginTop: '7px'}}
							>
								<MenuItem value={'Fulltime'} selected>Full Time</MenuItem>
								<MenuItem value={'ProjectBased'}>Project Base</MenuItem>
							</TextValidator>
						</Grid>	
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={6}> 
							<TextValidator
								label="Street, Building Address"
								onChange={this.handleChange}
								name="street"
								value={formData.street}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
								validators={['required']}
								errorMessages={['Street or Building is required']}
								fullWidth
								style={{marginTop: '-15px'}}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextValidator
								label="City, Province Address"
								onChange={this.handleChange}
								name="city"
								value={formData.city}
								inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
								validators={['required']}
								errorMessages={['City Address is required']}
								fullWidth
								style={{marginTop: '-15px'}}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={12}> 
							<h6>Job Description</h6>
							<div style={{ border: "1px solid #bfbdbd", padding: '10px', minHeight: '400px' }}>
								<Editor
									name="employment_type"
									editorState={this.state.editorState}
									// editorState={editorState}
									value={formData.job_details}
									onEditorStateChange={this.onChange}
									// onChange={this.handleChange}
									placeholder="Enter Job Details"
									fullWidth
									style={{padding:'20px'}}
								/>
							</div>
						</Grid>
					</Grid>
					{this.state.successful && (
						<div>
							<br/>
							<div className="form-group">
								<div
									className={
										this.state.successful
										? "alert alert-success"
										: "alert alert-danger"
									}
									role="alert"
								>
								{this.state.message}
								</div>
							</div>
						</div>
					)}
					<div className="form-group" style={{marginTop: '-10px'}}>
					{  disableDetails && (
						<div>
						{ checkedData && (
						<Button
							color="primary"
							variant="contained"
							type="submit"
							disabled={submitted}
							style={{ 'width': '100%', marginTop: '30px'}}
						>
							{this.state.loading && (
								<div><span className="spinner-border spinner-border-sm"></span>&nbsp;</div>
							)}

							{
								(submitted && ' Saving new job..')
								|| (!submitted && 'Save New Job ')
							}
						</Button>
						)}
						</div>
					)}
					</div>
					</ValidatorForm>
			</Paper>
		</div>
		);
  }
}
