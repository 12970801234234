import React, { Component } from "react";
// import PropTypes from 'prop-types';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
// import Select from '@material-ui/core/Select';
// import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
// import Input from '@material-ui/core/Input';
// import ListItemText from '@material-ui/core/ListItemText';

// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';

// import Accordion from '@material-ui/core/Accordion';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { send } from 'emailjs-com';

import "../Component.css";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from 'draft-convert';
import { EditorState, convertFromRaw } from "draft-js";
import DOMPurify from 'dompurify';

import * as moment from 'moment';

// Services
import JobsService from "../../services/job.service";
import AuthService from "../../services/auth.service";

import { Link } from "react-router-dom";
// import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";

// function TabPanel(props) {
// 	const { children, value, index, ...other } = props;
  
// 	return (
// 	  <div
// 		role="tabpanel"
// 		hidden={value !== index}
// 		id={`simple-tabpanel-${index}`}
// 		aria-labelledby={`simple-tab-${index}`}
// 		{...other}
// 	  >
// 		{value === index && (
// 		  <Box sx={{ p: 3 }}>
// 			<Typography>{children}</Typography>
// 		  </Box>
// 		)}
// 	  </div>
// 	);
//   }
  
//   TabPanel.propTypes = {
// 	children: PropTypes.node,
// 	index: PropTypes.number.isRequired,
// 	value: PropTypes.number.isRequired,
//   };

export default class jobdetails extends Component {
  constructor(props) {
    super(props);
	this.state = {editorState: EditorState.createEmpty()};
    this.state = {
		user: '',
		fullname: '',
		gender:'',
        formData: {
			fullname: '',
			job_required: "",
			qualifications: [],
			jobRequired:[],
			gender: "Male",
			educ_attainment:'',
			email: '',
			confirm_email: ''
        },
		quickApplyForm: {
			fullname: '',
			gender: '',
			email: '',
			confirm_email: '',
			presentaddress: '',
			birthday: '',
			mobile_number: '',
			educ_attainment:''
		},
		qualifications:[],
        submitted: false,
        loading: false,
        message: "",
		checked: true,
		disableDetails: false,
		disabled: true,
		alert: '',
		alertIcon: '',
		jobCategory: [
			"Accounting / Finance",
			"Admin / Office / Clerical",
			"Arts / Media / Design",
			"Engineering / Architecture",
			"Food / Restaurant",
			"Foreign Language",
			"HR / Recruitment / Training",
			"Health / Medical / Science",
			"Hotel / Spa / Salon",
			"IT / Computers",
			"Logistics / Warehousing",
			"Production / Manufacturing",
			"Purchasing / Buyer",
			"Sales / Marketing / Retail",
			"Skilled Work / Technical"
		],
		successful: false,
		job_position: '',
		value: 0,
		tab1: true,
		activeMenu:'quick'
    }
  }
  
	componentDidMount() {
			const { id }  = this.props.match.params;
			this.setState({ loading: false, jobID: id, disabled: false });
			// Get Job by ID
			JobsService.getjobByIDForApplicant(id).then(
				response => {
					if (response.status) {
						const jobDetails = response.data;
						this.setState({job_position:jobDetails[0].job_position});
						if (jobDetails[0].job_details) {
							this.setState({ editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(jobDetails[0].job_details))) });
							this.saveContent(this.state.editorState.getCurrentContent());
						} else {
							this.setState({ editorState: EditorState.createEmpty() });
						}
						this.setState({ jobRequired: JSON.parse(response.data[0].job_required) });
						this.setState({ formData: { ...response.data[0], qualifications: this.state.qualifications }});
						// this.setState({ formData: { ...response.data[0], qualifications: this.state.qualifications }, disabled: false });
					}
				},
				error => {
					console.log(error);
				}
			);

			// custom rule will have name 'isEmailMatch'
			ValidatorForm.addValidationRule('isEmailMatch', (value) => {
				if (value !== this.state.quickApplyForm.email) {
					return false;
				}
				return true;
			});
	

			// custom rule will have name 'validate Age'
			ValidatorForm.addValidationRule('isAge', (value) => {
				if (this.getAge(value) < 18) {
					return false;
				}
				return true;
			});
	}

	componentWillUnmount() {
		// remove rule when it is not needed
		ValidatorForm.removeValidationRule('isPasswordMatch');
		ValidatorForm.removeValidationRule('isAge');
	}

	handleChange = (event) => {
		const { quickApplyForm } = this.state;
		quickApplyForm[event.target.name] = event.target.value;
		this.setState({ quickApplyForm });
	}

	handleChangeChecked = (event) => {
		const { formData } = this.state;
		formData[event.target.name] = event.target.checked;
		if(formData.High || formData.More || formData.No || formData.Year) {
			this.setState({checkedData: true});
		} else {
			this.setState({checkedData: false});
		}
	}

	onChange = (editorState) => {
		const contentState = editorState.getCurrentContent();
		if(contentState.hasText()) {
			this.setState({disableDetails: true, disabled: false});
		} else {
			this.setState({disableDetails: false, disabled: true});
		}
		this.saveContent(contentState);
		this.setState({
			editorState,
		});
	}

	saveContent = (content) => {
		this.setState({job_details: convertToHTML(content)})
	}

	closeDiv = () => {
		this.props.history.goBack();
	}

	getAge = (dateString) => {
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}

	// filter the required options
	required = (req) => {
		if(req !== "") {
			const qualif = JSON.parse(req);
			var reqdesc = [];
			qualif.forEach(element => {
				var withEx = 'Year';
				if(element.match(new RegExp(`${withEx}`, 'gi'))){
					var sprite = <i className="fas fa-circle" style={{
						fontSize: '15px',
						color: 'rgb(224, 57, 151)'
						}}></i>
				}

				if(element.match(new RegExp(`No`, 'gi'))){
					sprite = <i className="fas fa-circle" style={{
						fontSize: '15px',
						color: 'rgb(242, 113, 28)'
						}}></i>
				}

				if(element.match(new RegExp(`High`, 'gi'))){
					sprite = <i className="fas fa-circle" style={{
						fontSize: '15px',
						color: 'rgb(251, 189, 8)'
						}}></i>
				}

				if(element.match(new RegExp(`years`, 'gi'))){
					sprite = <i className="fas fa-circle" style={{
						fontSize: '15px',
						color: 'rgb(33, 186, 69)'
						}}></i>
				}

				reqdesc.push(<div key={element}><span style={{fontSize: '13px'}}>
								{sprite} &nbsp;
								{element}
							</span><br /></div>)
			});
			return reqdesc;
		}
	}

	handleChangeTab = (event, newValue) => {
		this.setState({value: newValue});
	};

	handleSubmit = () => {
		this.setState({
		  resMessage: "",
		  loading: true,
		  successful: false,
		  disabled: true
		});
		this.setState({ submitted: true }, () => {
			  const { id }  = this.props.match.params;
			  AuthService.quickApply(
				  id,
				  this.state.quickApplyForm.fullname,
				  this.state.quickApplyForm.gender,
				  this.state.quickApplyForm.email,
				  this.state.quickApplyForm.presentaddress,
				  this.state.quickApplyForm.birthday,
				  this.state.quickApplyForm.mobile_number,
				  this.state.quickApplyForm.educ_attainment
			  ).then(response => {
						const toSend =  {
							to_name: this.state.quickApplyForm.fullname,
							to_email: this.state.quickApplyForm.email,
							job_applied: this.state.job_position
						};
						send(
							'service_37v4ai9',
							'template_c3x3b38',
							toSend,
							'user_jXEHEjDvf6F3v7yYF4fMj',
						)
						.then((response) => {
							console.log(response);
						})
						.catch((err) => {
							console.log('FAILED...', err);
						});

						// const toSendHR =  {
				  		// 	gender: this.state.quickApplyForm.gender,
				  		// 	address: this.state.quickApplyForm.presentaddress,
				  		// 	birthday: this.state.quickApplyForm.birthday,
						// 	age: this.getAge(this.state.quickApplyForm.birthday),
				  		// 	mobile_number: this.state.quickApplyForm.mobile_number,
						// 	to_name: this.state.quickApplyForm.fullname,
						// 	to_email: this.state.quickApplyForm.email,
						// 	job_applied: this.state.job_position,
						// 	educ_attain: this.state.quickApplyForm.educ_attainment
						// };
						// send(
						// 	'service_do9nsb2',
						// 	'template_svhme9a',
						// 	toSendHR,
						// 	'user_DxcqlOSGkrCElgPuVUYLd',
						// )
						// .then((response) => {
						// 	console.log(response);
						// })
						// .catch((err) => {
						// 	console.log('FAILED...', err);
						// });
						
						setTimeout(() => 
							this.setState({
								message: "Thank your for applying CGSI. You have successfully applied job. We will contact you as soon as posible. Thank you.",
								successful: true,
								loading: false
							}), 
						5000); 
				  },
				  error => {
					const resMessage = error.response.data;
					this.setState({
						successful: false,
						message: resMessage,
						loading: false
					});
				  }
			  );
			  setTimeout(() => 
			  this.setState({ submitted: false, disabled: false }), 5000);
		});
	}

	showContent = (id) => {
		this.setState({tab1: id===2?false: true});
		if(id === 1) {
			this.setState({activeMenu:'quick'});
		} else {
			this.setState({activeMenu:'regis'});
		}
	}

  render() {
    const { formData, submitted, quickApplyForm, disabled } = this.state; 
	const createMarkup = (html) => {
		return  {
		  __html: DOMPurify.sanitize(html)
		}
	  }
    return (
		<div className="container" id="createjobContainer" style={{paddingTop:'0px',paddingBottom: '10px'}}>
			<span onClick={this.closeDiv } style={{color: '#0a0d0a',float: 'left',paddingLeft: '10px',fontSize: '21px', cursor: 'pointer'}} title="Back to job list"><i className="fas fa-arrow-left"></i></span>
					<Typography component="h1" variant="h5" style={{textAlign:'center'}}>
						Job Details
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12}> 
							<span style={{fontSize: '30px',fontWeight: 'bold'}}>{formData.job_position}</span>
							<span style={{display:'block',fontWeight:'bold',color: '#4982e7',fontSize: '18px'}}> {formData.company}</span>
							{this.required(formData.job_required)}
							<span><span style={{fontSize:'12px'}}>Total vacancies for this job title:</span> 
							&nbsp;{formData.vacancy}</span>
							<span style={{display:'block'}}><span style={{fontSize:'12px'}}>Employment type:</span> 
							<span style={{fontSize:'12px'}}>&nbsp;{formData.employment_type}</span></span>
							<span><i className="fas fa-map-marker-alt" style={{
								fontSize: '20px',
								color: 'rgb(33, 186, 69)'
							}}></i> {formData.street}, {formData.city}</span>
							<span style={{display:'block'}}>
								<i className="fas fa-clock" style={{color:'royalblue'}}></i> 
								&nbsp;{moment(formData.created).format('MMM DD YYYY h:mm A')}
								<span style={{ display: 'block',fontSize: '0.66em'}}>{moment(formData.created).startOf('hour').fromNow()}</span>
							</span>
						</Grid>
					</Grid>
					<hr />
					<Grid container spacing={2}>
						<Grid item xs={12}> 
							<h3>Job Description</h3>
								<div className="preview" dangerouslySetInnerHTML={createMarkup(this.state.job_details)}></div>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}> 
							<Button className={`${this.state.activeMenu==='quick'?'quickBtn_active':'quickBtn'}`} onClick={() => this.showContent(1)} style={{fontSize: '17px',fontWeight: 'bold',color:' #4949c5'}}>Quick Apply</Button>
							<Button className={`${this.state.activeMenu==='regis'?'quickBtn_active':'quickBtn'}`} onClick={() => this.showContent(2)} style={{fontSize: '17px',fontWeight: 'bold',color:' #4949c5'}}>Apply as CGSI Applicant</Button>
						</Grid>
					</Grid>
					{
						this.state.tab1 && (
						<Grid container spacing={2} id="quickForm">
							<Grid item xs={12}> 
								<div style={{marginLeft:'10px', paddingTop: '25px'}}>
									<span style={{display: 'block',fontSize: '20px',fontWeight: 'bold',color: '#5959f5'}}>Quick Apply</span>
									<span>Input information details. For Services position (Driver, Housekeeping, Maintenance) there will be no exam.</span>
								</div>
								<ValidatorForm
									ref="form"
									onSubmit={this.handleSubmit}
									style={{padding: '10px'}}
								>
									<Grid container spacing={2}>
										<Grid item xs={12}> 
											<TextValidator
												label="Full Name*"
												onChange={this.handleChange}
												name="fullname"
												value={quickApplyForm.fullname}
												variant="outlined"
												inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
												validators={['required']}
												errorMessages={['Full Name is required']}
												fullWidth
												autoFocus
											/>
										</Grid>
										<Grid item xs={12}> 
											<TextValidator
													name="gender"
													label="Gender*"
													select
													variant="outlined"
													value={quickApplyForm.gender}
													InputLabelProps={{ shrink: true }}
													validators={['required']}
													errorMessages={['Gender is required.']}
													onChange={this.handleChange}
													validatorListener={this.validatorListener}
													fullWidth
													style={{marginTop: '7px'}}
											>
												<MenuItem value={'Male'} selected>Male</MenuItem>
												<MenuItem value={'Female'} >Female</MenuItem>
											</TextValidator>
										</Grid>
										<Grid item xs={12}> 
											<label style={{fontSize: '10px',opacity: '.5', marginBottom: '10px'}}> Birthday* </label>
											<TextValidator
												onChange={this.handleChange}
												name="birthday"
												type="date"
												value={quickApplyForm.birthday}
												variant="outlined"
												inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
												validators={['isAge','required']}
												errorMessages={['Required age must be 18 years old.','Birthday is required']}
												fullWidth
											/>
										</Grid>
									</Grid>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<TextValidator
												label="Email*"
												onChange={this.handleChange}
												name="email"
												variant="outlined"
												InputLabelProps={{ shrink: true }}
												value={quickApplyForm.email}
												inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
												validators={['required', 'isEmail']}
												errorMessages={['Email is required', 'Email is not valid']}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12}>
											<TextValidator
												label="Confirm Email*"
												onChange={this.handleChange}
												name="confirm_email"
												InputLabelProps={{ shrink: true }}
												value={quickApplyForm.confirm_email}
												variant="outlined"
												inputProps={{style: { marginBottom: '5px', marginTop:'5px'}}}
												validators={['required', 'isEmail','isEmailMatch']}
												errorMessages={['Confirm is required', 'Email is not valid','Email not match']}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12}> 
											<TextValidator
												name="educ_attainment"
												label="Education Attainment*"
												select
												value={quickApplyForm.educ_attainment}
												InputLabelProps={{ shrink: true }}
												validators={['required']}
												variant="outlined"
												errorMessages={['Education Attain is required.']}
												onChange={this.handleChange}
												validatorListener={this.validatorListener}
												fullWidth
												style={{marginTop: '7px'}}
												>
												<MenuItem value={'Highschool_Level'} selected>High School Level</MenuItem>
												<MenuItem value={'Highschool_Grad'}>High School Graduate</MenuItem>
												<MenuItem value={'College_Level'} >College Level</MenuItem>
												<MenuItem value={'College_Grad'}>College Graduate</MenuItem>
											</TextValidator>
										</Grid>
									</Grid>
									<Grid container spacing={2}>
										<Grid item xs={12}> 
											<TextValidator
												label="Present Address*"
												onChange={this.handleChange}
												name="presentaddress"
												variant="outlined"
												InputLabelProps={{ shrink: true }}
												value={quickApplyForm.presentaddress}
												inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
												validators={['required']}
												// placeholder="none"
												errorMessages={['Present Address is required.']}
												fullWidth
											/>
										</Grid>
									</Grid>
									<Grid container spacing={2}>
										<Grid item xs={12}> 
											<TextValidator
												label="Contact / Mobile number*"
												onChange={this.handleChange}
												name="mobile_number"
												variant="outlined"
												InputLabelProps={{ shrink: true }}
												value={quickApplyForm.mobile_number}
												inputProps={{style: { marginBottom: '5px', marginTop:'5px' }}}
												validators={['required']}
												errorMessages={['Mobile Number is required.']}
												fullWidth
												type="number"
											/>
										</Grid>
									</Grid>
									{this.state.successful && (
										<div>
											<br/>
											<div className="form-group">
												<div
													className={
														this.state.successful
														? "alert alert-success"
														: "alert alert-danger"
													}
													role="alert"
												>
												{this.state.message}
												</div>
											</div>
										</div>
									)}

									<div className="form-group" style={{marginTop: '-10px', marginBottom: '10px'}}>
										<Button
											color="primary"
											variant="contained"
											type="submit"
											// className="registerAccountBtn"
											disabled={disabled || submitted}
											style={{ 'width': '100%', marginTop: '30px'}}>

											{this.state.loading && (
												<div><span className="spinner-border spinner-border-sm"></span> &nbsp;</div>
											)}

											{
												(submitted && ' Applying job...')
												|| (!submitted && 'SUBMIT')
											}
										</Button>
									</div>
									</ValidatorForm>
							</Grid>
						</Grid>) 
					}
					{
						!this.state.tab1 && (
							<Grid container spacing={2} id="quickForm">
								<Grid item xs={12}> 
								<span style={{display: 'block',fontSize: '20px',fontWeight: 'bold',color: '#5959f5', paddingTop: '25px'}}>LogIn As CGSI Applicant</span>
								<span>Take Online exam without going to office, Job Dashboard to have job history list</span>
								<Grid container spacing={2}>
									<Grid item xs={6}> 
										<div>
											<div className="form-group" style={{marginTop: '-30px'}}>
												<Link
													to={"/register"}
													variant="contained"
													type="submit"
													className="createAccountBtn"
													style={{ 'width': '100%', marginTop: '40px'}}
												>
													{this.state.loading && (
														<div><span className="spinner-border spinner-border-sm"></span>&nbsp;</div>
													)}

													{
														(submitted && ' Register..')
														|| (!submitted && 'No Account yet? Create Account. ')
													}
												</Link>
											</div>
										</div>
									</Grid>
										<Grid item xs={6}> 
											<div>
												<div className="form-group" style={{marginTop: '-30px'}}>
													<Link
														to={"/login"}
														variant="contained"
														type="submit"
														className="loginAccountBtn"
														style={{ 'width': '100%', marginTop: '40px'}}
													>
														{this.state.loading && (
															<div><span className="spinner-border spinner-border-sm"></span>&nbsp;</div>
														)}

														{
															(submitted && ' Login..')
															|| (!submitted && 'Already registered? Login. ')
														}
													</Link>
												</div>
											</div>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						)
					}
					
		</div>
		);
  }
}

