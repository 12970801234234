import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'http://180.232.152.179:70/api/test/';

class UserService {
	getPublicContent() {
		return axios.get(API_URL + 'all');
	}

	getAllExamVerbal() {
		return axios.get(API_URL + 'all');
	}

	getUserBoard() {
		return axios.get(API_URL + 'user', { headers: authHeader() });
	}

	getModeratorBoard() {
		return axios.get(API_URL + 'mod', { headers: authHeader() });
	}

	getAdminBoard() {
		return axios.get(API_URL + 'admin', { headers: authHeader() });
	}
}

export default new UserService();
